import React from "react";

function Contact() {
  
  const enviarCorreo = (e) => {
    e.preventDefault();
    let nombre = document.getElementById("nombre").value;
    let email = document.getElementById("email").value;
    let manzana = document.getElementById("manzana").value;
    let lote = document.getElementById("lote").value;
    let loteo = document.getElementById("loteo").value;
    let telefono = document.getElementById("telefono").value;

    let url =
      "https://terraloteos.com/terra-mail/ContactCliente.php?nombre=" +
      nombre +
      "&email=" +
      email +
      "&manzana=" +
      manzana +
      "&lote=" +
      lote +
      "&loteo=" +
      loteo +
      "&telefono=" +
      telefono;

    window.location.href = url;
  };

  return (
    <div className="contact-from-section pt-150 pb-150 fondo-textura2">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 mb-5 mb-lg-0 caja-contacto">
            <div className="form-title">
              <h2>Pongase en contacto con nosotros</h2>
              <p>
                Si usted es titular de un lote escribanos su consulta y nuestros
                asesores se pondrán en contacto con usted para resolver todas
                sus dudas e inquietudes.
              </p>
            </div>
            <div id="form_status" />
            <div className="contact-form">
              <form onSubmit={enviarCorreo} id="contact">
                <p>
                  <input
                    type="text"
                    placeholder="Nombre y apellido del titular"
                    name="nombre"
                    id="nombre"
                    required
                  />
                  <input
                    type="email"
                    placeholder="Email"
                    name="email"
                    id="email"
                    required
                  />
                </p>
                <p>
                  <input
                    type="text"
                    placeholder="Número de manzana"
                    name="manzana"
                    id="manzana"
                    required
                  />
                  <input
                    type="text"
                    placeholder="Número de lote"
                    name="lote"
                    id="lote"
                    required
                  />
                </p>
                <p>
                  <input
                    type="text"
                    placeholder="Nombre del loteo"
                    name="loteo"
                    id="loteo"
                    required
                  />
                  <input
                    type="tel"
                    placeholder="Teléfono (opcional)"
                    name="telefono"
                    id="telefono"
                  />
                </p>
                <input type="hidden" name="token" defaultValue="FsWga4&@f6aw" />
                <p className="text-center">
                  <input type="submit" defaultValue="Enviar" />
                </p>
              </form>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="contact-form-wrap">
              <div className="contact-form-box">
                <h4>
                  <i className="fas fa-map" />
                  Oficinas
                </h4>
                <p>
                  <span>V. Mercedes:</span>
                  <br />
                  Av. Mitre N° 1260
                  <br />
                  <span>Bs. As.:</span>
                  <br />
                  Av. Córdoba N° 679, Piso:2 Oficina:B (CABA)
                  <br />
                  <span>San Luis:</span>
                  <br />
                  Gral. Paz 956
                  <br />
                  <span>Merlo:</span>
                  <br />
                  Av. del Sol N° 821 y Av. del Sol N° 351
                  <br />
                  <span>Rio Cuarto:</span>
                  <br />
                  Alberdi 717
                  <br />
                </p>
              </div>
              <div className="contact-form-box">
                <h4>
                  <i className="fas fa-address-book" /> Contacto
                </h4>
                <p>
                  Email: <a href> info@terraloteos.com</a>
                </p>
                <a
                  href="https://walink.co/f5d32f"
                  className="wp-btn"
                  target={"_blank"}
                  rel="noopener noreferrer"
                >
                  <i className="fab fa-whatsapp" /> Escribinos!
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
