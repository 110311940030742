import React from "react";
import Proyecto from "../../layouts/Proyecto";
import Navbar from "../../components/Navbar";
import NavbarResponsive from "../../components/NavbarResponsive";

function ValleDeLosComechingones() {
  var data = {
    image: "valle-de-los-comechingones-hero",
    nombre: "Valle de los Comechingones",
    descripcion:
      "Emprendimiento ubicado en las cercanías de Merlo, con una ubicación ideal que permite disfrutar del paisaje de las sierrras y el valle de los Comechingones con la ventaja de la cercanía al centro de la ciudad.",
    items: {
      item1: [
        "fas fa-map-pin",
        "A 5 minutos de Merlo",
        "Ruta N°5 A 5km del centro",
      ],
      item2: [
        "fas fa-expand-arrows-alt",
        "15 Hectareas",
        "Divididas en 24 lotes",
      ],
      item3: [
        "fas fa-water",
        "Parque Acuático",
        "Parque Palo Alto ubicado justo al lado",
      ],
      item4: ["fas fa-tint", "Servicios", "Luz y Agua Potable"],
    },
    servicios: [
      "Agua Potable",
      "Luz",
      "Alumbrado de Calles",
      "Calles terminadas",
    ],
    galeria: [
      "https://terraloteos.com/img/proyectos/Valle%20de%20los%20Comechingones/valle-de-los-comechingones-g1.jpg",
      "https://terraloteos.com/img/proyectos/Valle%20de%20los%20Comechingones/valle-de-los-comechingones-g2.jpg",
      "https://terraloteos.com/img/proyectos/Valle%20de%20los%20Comechingones/valle-de-los-comechingones-g3.jpg",
      "https://terraloteos.com/img/proyectos/Valle%20de%20los%20Comechingones/valle-de-los-comechingones-g4.jpg",
      "https://terraloteos.com/img/proyectos/Valle%20de%20los%20Comechingones/valle-de-los-comechingones-g5.jpg",
    ],
    plano:
      "https://terraloteos.com/img/proyectos/Valle%20de%20los%20Comechingones/valle-de-los-comechingones-plano.jpg",
    mapa: "!1m18!1m12!1m3!1d53921.913495471104!2d-65.06668123691486!3d-32.362337179409785!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95d31e20ae9e9ca3%3A0xa2cf22704512fb26!2sValle%20de%20los%20Comechingones!5e0!3m2!1ses!2sar!4v1646407509104!5m2!1ses!2sar",
    puntos: {
      punto1: [
        "fas fa-pin",
        "Ubicación Privilegiada",
        "Ubicado a solo 5 minutos de la Villa de Merlo, cuenta con una ubicación unica que le permite estar rodeado de naturaleza y al mismo tiempo estar cerca del centro de la ciudad.",
        "../img/proyectos/Valle%20de%20los%20Comechingones/valle-de-los-comechingones-item1.jpg",
      ],
      punto2: [
        "fas fa-arrows-expand",
        "Parque Acuático",
        "Ubicado justo al lado del loteo, se encuentra el único parque acuático de la zona, ideal para visitar en familia.",
        "https://terraloteos.com/img/proyectos/Valle%20de%20los%20Comechingones/valle-de-los-comechingones-item2.jpg",
      ],
      punto3: [
        "fas fa-arrow-left",
        "Todos los servicios",
        "El loteo cuenta con todos los servicios necesarios para llevar una vida tranquila y con todas las comodidades rodeado de la naturaleza. Cuenta con agua potable, luz, alumbrado de calles y calles terminadas.",
        "https://terraloteos.com/img/proyectos/Valle%20de%20los%20Comechingones/valle-de-los-comechingones-item3.jpg",
      ],
    },
  };

  const setNavbar = () => {
    if (window.screen.width < 800) {
      return <NavbarResponsive />;
    } else {
      return <Navbar active={3} />;
    }
  };

  return (
    <>
      {setNavbar()}
      <Proyecto data={data}></Proyecto>
    </>
  );
}

export default ValleDeLosComechingones;
