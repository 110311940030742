import React from "react";
import Proyecto from "../../layouts/Proyecto";
import Navbar from "../../components/Navbar";
import NavbarResponsive from "../../components/NavbarResponsive";

function LosAlamos() {
  var data = {
    image: "los-alamos-hero",
    nombre: "Los Alamos",
    descripcion:
      "Desarrollo urbanístico ubicado en el extremo noreste de la ciudad de Villa Mercedes, con ingreso por vía de acceso Intendente Almada. A solo 10” del corazón de Villa Mercedes.",
    items: {
      item1: [
        "fas fa-map-pin",
        "Villa Mercedes, San Luis",
        "Intendente Almada Extremo Oeste",
      ],
      item2: [
        "fas fa-expand-arrows-alt",
        "16 Hectareas",
        "Divididas en 302 lotes",
      ],
      item3: [
        "fas fa-lightbulb",
        "Infraestructura",
        "Alumbrado de Calles, Calles terminadas",
      ],
      item4: ["fas fa-tint", "Servicios", "Luz, Agua Potable, Cloacas"],
    },
    servicios: [
      "Agua Potable",
      "Luz",
      "Alumbrado de Calles",
      "Calles terminadas",
    ],
    galeria: [
      "https://terraloteos.com/img/proyectos/Los%20Alamos/los-alamos-1.jpeg",
      "https://terraloteos.com/img/proyectos/Los%20Alamos/los-alamos-2.jpeg",
      "https://terraloteos.com/img/proyectos/Los%20Alamos/los-alamos-hero.jpeg",
    ],
    plano: "",
    mapa: "!1m18!1m12!1m3!1d5387.604340200034!2d-65.47696314674599!3d-33.65063191936453!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95d16b8d330a4579%3A0x1978a3b05c0d96ac!2sLoteo%20Los%20Alamos!5e0!3m2!1ses!2sar!4v1681220394030!5m2!1ses!2sar",
    puntos: {
      punto1: [
        "fas fa-pin",
        "Ubicación Privilegiada",
        "Ubicado en la Av. Circunvalación de la Ciudad de Villa Mercedes, a pocos minutos del corazón de la ciudad.",
        "../img/proyectos/Los%20Alamos/los-alamos-1.jpeg",
      ],
      punto2: [
        "fas fa-arrows-expand",
        "Infraestructura",
        "Este desarrollo urbanístico cuenta con servicios esenciales como agua potable, luz y cloacas, así como alumbrado en sus calles y calles terminadas para una circulación cómoda y segura.",
        "../img/proyectos/Los%20Alamos/los-alamos-2.jpeg",
      ],
    },
  };

  const setNavbar = () => {
    if (window.screen.width < 800) {
      return <NavbarResponsive />;
    } else {
      return <Navbar active={3} />;
    }
  };

  return (
    <>
      {setNavbar()}
      <Proyecto data={data}></Proyecto>
    </>
  );
}

export default LosAlamos;
