import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import NavbarResponsive from "../components/NavbarResponsive";
import Contact from "../components/Contact";
import BlogItemSingle from "../components/BlogItemSingle";
import fire from "../config/Fire";
import { collection, getDocs, getFirestore } from "firebase/firestore";

function Blog() {
  const db = getFirestore(fire);
  const [blogs, setBlogs] = useState([]);

  const getBlogs = async () => {
    let arrayResultados = [];
    const querySnapshot = await getDocs(collection(db, "blogs"));
    querySnapshot.forEach((doc) => {
      arrayResultados.push(doc.data());
    });
    setBlogs(arrayResultados);
  };

  const setNavbar = () => {
    getBlogs();
    if (window.screen.width < 800) {
      return <NavbarResponsive />;
    } else {
      return <Navbar active={6} />;
    }
  };

  const renderBlogs = () => {
    var items = [];
    for (let i = 0; i < blogs.length; i++) {
      items.push(<BlogItemSingle blog={blogs[i]} />);
    }
    return items;
  };

  return (
    <div>
      {setNavbar()}
      <body>
        <div className="search-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <span className="close-btn">
                  <i className="fas fa-times" />
                </span>
                <div className="search-bar">
                  <div className="search-bar-tablecell">
                    <a href="/">
                      <h3>Home</h3>
                    </a>
                    <a href="/#nosotros" className="close-btn">
                      <h3>Nosotros</h3>
                    </a>
                    <a href="/fundacion">
                      <h3>Fundación</h3>
                    </a>
                    <a href="/oficinas">
                      <h3>Oficinas</h3>
                    </a>
                    <a href="/proyectos">
                      <h3>Proyectos</h3>
                    </a>
                    <a href="/casas">
                      <h3>Casas</h3>
                    </a>
                    <a href="/blog">
                      <h3>Blog</h3>
                    </a>
                    <a href="/clientes">
                      <h3>Clientes</h3>
                    </a>
                    <button type="submit" className="close-btn" id="close-btn">
                      Volver <i className="fas fa-arrow-up" />
                    </button>
                    <a href="/login">
                      <h5>Ingresar</h5>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="blog-hero">
          <div className="container text-center">
            <h3>
              Conocé nuestro
              <br />
              <span className="orange-text">Blog</span>
            </h3>
            <a href="#blog" className="btn-bajar btn-lg">
              <i className="fas fa-arrow-down mr-0" />
            </a>
          </div>
        </section>

        <div className="latest-news pt-150 pb-150 fondo-textura2" id="blog">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2 text-center">
                <div className="section-title">
                  <h3>
                    Conocé las<span className="orange-text"> Novedades</span>
                  </h3>
                  <p>
                    Enterate de todas las noticias sobre nuestros loteos,
                    mantenete informado sobre nuestras actividades y conocé más
                    sobre nosotros!
                  </p>
                </div>
              </div>
            </div>
            <div className="row" id="fila1">
              {renderBlogs()}
              {/* {<div className="col-lg-4 col-md-6">
                <div className="single-latest-news">
                  <a href="/#">
                    <div className="latest-news-bg blog-2" />
                  </a>
                  <div className="news-text-box">
                    <h3><a href="/#">Titulo blog 2</a></h3>
                    <p className="blog-meta">
                      <span className="author"><i className="fas fa-map-marker-alt" />25/03/2023</span>
                      <span className="date"><i className="fas fa-tag" />Casas</span>
                    </p>
                    <p className="excerpt">Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint debitis quis aliquam voluptate! Totam, maxime neque, commodi, excepturi voluptate magnam hic similique inventore repellendus tempora modi doloremque nobis id. Eos?</p>
                    <a href="/#" className="read-more-btn">Seguir leyendo <i className="fas fa-angle-right" /></a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div className="single-latest-news">
                  <a href="/#">
                    <div className="latest-news-bg blog-3" />
                  </a>
                  <div className="news-text-box">
                    <h3><a href="/#">Titulo blog 3</a></h3>
                    <p className="blog-meta">
                      <span className="author"><i className="fas fa-map-marker-alt" />25/03/2023</span>
                      <span className="date"><i className="fas fa-tag" />Proyectos</span>
                    </p>
                    <p className="excerpt">Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint debitis quis aliquam voluptate! Totam, maxime neque, commodi, excepturi voluptate magnam hic similique inventore repellendus tempora modi doloremque nobis id. Eos?</p>
                    <a href="/#" className="read-more-btn">Seguir leyendo <i className="fas fa-angle-right" /></a>
                  </div>
                </div>
              </div>} */}
            </div>
            {/* <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="single-latest-news">
                  <a href="/#">
                    <div className="latest-news-bg blog-4" />
                  </a>
                  <div className="news-text-box">
                    <h3><a href="/#">Titulo blog 1</a></h3>
                    <p className="blog-meta">
                      <span className="author"><i className="fas fa-map-marker-alt" />25/03/2023</span>
                      <span className="date"><i className="fas fa-tag" />Fundacion</span>
                    </p>
                    <p className="excerpt">Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint debitis quis aliquam voluptate! Totam, maxime neque, commodi, excepturi voluptate magnam hic similique inventore repellendus tempora modi doloremque nobis id. Eos?</p>
                    <a href="/#" className="read-more-btn">Seguir leyendo <i className="fas fa-angle-right" /></a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="single-latest-news">
                  <a href="/#">
                    <div className="latest-news-bg blog-5" />
                  </a>
                  <div className="news-text-box">
                    <h3><a href="/#">Titulo blog 2</a></h3>
                    <p className="blog-meta">
                      <span className="author"><i className="fas fa-map-marker-alt" />25/03/2023</span>
                      <span className="date"><i className="fas fa-tag" />Casas</span>
                    </p>
                    <p className="excerpt">Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint debitis quis aliquam voluptate! Totam, maxime neque, commodi, excepturi voluptate magnam hic similique inventore repellendus tempora modi doloremque nobis id. Eos?</p>
                    <a href="/#" className="read-more-btn">Seguir leyendo <i className="fas fa-angle-right" /></a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div className="single-latest-news">
                  <a href="/#">
                    <div className="latest-news-bg blog-6" />
                  </a>
                  <div className="news-text-box">
                    <h3><a href="/#">Titulo blog 3</a></h3>
                    <p className="blog-meta">
                      <span className="author"><i className="fas fa-map-marker-alt" />25/03/2023</span>
                      <span className="date"><i className="fas fa-tag" />Proyectos</span>
                    </p>
                    <p className="excerpt">Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint debitis quis aliquam voluptate! Totam, maxime neque, commodi, excepturi voluptate magnam hic similique inventore repellendus tempora modi doloremque nobis id. Eos?</p>
                    <a href="/#" className="read-more-btn">Seguir leyendo <i className="fas fa-angle-right" /></a>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>

        <div className="list-section pt-80 pb-80" id="contacto">
          <div className="container">
            <div className="row text-center">
              <div className="col-lg-12">
                <div className="section-title mb-0">
                  <h3 className="text-white">
                    Comunicate con <span className="orange-text">Nosotros</span>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Contact></Contact>
      </body>
    </div>
  );
}

export default Blog;
