import React from "react";
// import { useParams } from "react-router-dom";
import Contact from "../components/Contact";
import Navbar from "../components/Navbar";
import NavbarResponsive from "../components/NavbarResponsive";

function PoliticaCookies() {

    // const {blogUrl} = useParams();

    const setNavbar = () => {
      if (window.screen.width < 800) {
        return(<NavbarResponsive/>)
      }else{
        return(<Navbar active={6}/>)
      }
    }

    return(
        <body>
          {setNavbar()}
        <div className="search-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <span className="close-btn"><i className="fas fa-times" /></span>
                <div className="search-bar">
                  <div className="search-bar-tablecell">
                    <a href="/"><h3>Home</h3></a>
                    <a href="/asesores" className="close-btn"><h3>Asesores</h3></a>
                    <a href="/fundacion"><h3>Fundación</h3></a>
                    <a href="/oficinas"><h3>Oficinas</h3></a>
                    <a href="/proyectos"><h3>Proyectos</h3></a>
                    <a href="/casas"><h3>Casas</h3></a>
                    <a href="/blog"><h3>Blog</h3></a>
                    <a href="/clientes">
                      <h3>Clientes</h3>
                    </a>
                    <button type="submit" className="close-btn" id="close-btn">Volver <i className="fas fa-arrow-up" /></button>
                    <a href="/login"><h5>Ingresar</h5></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="proyecto-hero" style={{backgroundImage: "linear-gradient(rgba(19, 50, 34, 0.7),rgba(19, 50, 34, 0.7)) , url(../img/otros/cookies.jpg)" }}>
          <div className="container text-center">
            <h3>Conocé nuestra<br /><span className="orange-text">Política de Cookies</span></h3>
            <a href="#cookies" className="btn-bajar btn-lg"><i className="fas fa-arrow-down mr-0" /></a>
          </div>
        </section>

        <div className="latest-news pt-150 pb-150 fondo-textura4" id="cookies">
            <div className="container">
              <div className="col-lg-8 offset-lg-2 text-center">
                <div className="section-title">
                  <h3>Política de<span className="orange-text"> Cookies</span></h3>
                </div>
              </div>
              <div className="col-lg-10 blog-body">
                  <p>
                    El acceso a este Sitio Web puede implicar la utilización de cookies. Las cookies son pequeñas cantidades de información que se almacenan en el navegador utilizado por cada Usuario —en los distintos dispositivos que pueda utilizar para navegar— para que el servidor recuerde cierta información que posteriormente y únicamente el servidor que la implementó leerá. Las cookies facilitan la navegación, la hacen más amigable, y no dañan el dispositivo de navegación.
                    <br />
                    Las cookies son procedimientos automáticos de recogida de información relativa a las preferencias determinadas por el Usuario durante su visita al Sitio Web con el fin de reconocerlo como Usuario, y personalizar su experiencia y el uso del Sitio Web, y pueden también, por ejemplo, ayudar a identificar y resolver errores.
                    <br />
                    La información recabada a través de las cookies puede incluir la fecha y hora de visitas al Sitio Web, las páginas visionadas, el tiempo que ha estado en el Sitio Web y los sitios visitados justo antes y después del mismo. Sin embargo, ninguna cookie permite que esta misma pueda contactarse con el número de teléfono del Usuario o con cualquier otro medio de contacto personal. Ninguna cookie puede extraer información del disco duro del Usuario o robar información personal. La única manera de que la información privada del Usuario forme parte del archivo Cookie es que el usuario dé personalmente esa información al servidor.
                    <br />
                    Las cookies que permiten identificar a una persona se consideran datos personales. Por tanto, a las mismas les será de aplicación la Política de Privacidad anteriormente descrita. En este sentido, para la utilización de las mismas será necesario el consentimiento del Usuario. Este consentimiento será comunicado, en base a una elección auténtica, ofrecido mediante una decisión afirmativa y positiva, antes del tratamiento inicial, removible y documentado.
                    <br />
                    <br />
                    <h4>Cookies Propias</h4>
                    Son aquellas cookies que son enviadas al ordenador o dispositivo del Usuario y gestionadas exclusivamente por Terraloteos para el mejor funcionamiento del Sitio Web. La información que se recaba se emplea para mejorar la calidad del Sitio Web y su Contenido y su experiencia como Usuario. Estas cookies permiten reconocer al Usuario como visitante recurrente del Sitio Web y adaptar el contenido para ofrecerle contenidos que se ajusten a sus preferencias.
                    <br />
                    <br />
                    <h4>Cookies de Terceros</h4>
                    Son cookies utilizadas y gestionadas por entidades externas que proporcionan a Terraloteos servicios solicitados por este mismo para mejorar el Sitio Web y la experiencia del usuario al navegar en el Sitio Web. Los principales objetivos para los que se utilizan cookies de terceros son la obtención de estadísticas de accesos y analizar la información de la navegación, es decir, cómo interactúa el Usuario con el Sitio Web.
                    <br />
                    La información que se obtiene se refiere, por ejemplo, al número de páginas visitadas, el idioma, el lugar a la que la dirección IP desde el que accede el Usuario, el número de Usuarios que acceden, la frecuencia yreincidencia de las visitas, el tiempo de visita, el navegador que usan, el operador o tipo de dipositivo desde el que se realiza la visita. Esta información se utiliza para mejorar el Sitio Web, y detectar nuevas necesidades para ofrecer a los Usuarios un Contenido y/o servicio de óptima calidad. En todo caso, la información se recopila de forma anónima y se elaboran informes de tendencias del Sitio Web sin identificar a usuarios individuales. Puede obtener más información sobre las cookies, la información sobre la privacidad, o consultar la descripción del tipo de cookies que se utiliza, sus principales características, periodo de expiración, etc. en el siguiente(s) enlace(s):
                    <br />
                    <br />
                    Chat-Robot: https://www.chat-robot.com/
                    <br />
                    <br />
                    La(s) entidad(es) encargada(s) del suministro de cookies podrá(n) ceder esta información a terceros, siempre y cuando lo exija la ley o sea un tercero el que procese esta información para dichas entidades. 
                    <br />
                    <br />
                    <h4>Cookies de Redes Sociales</h4>
                    Terraloteos incorpora plugins de redes sociales, que permiten acceder a las mismas a partir del Sitio Web.
                    <br />
                    Por esta razón, las cookies de redes sociales pueden almacenarse en el navegador del Usuario. Los
                    titulares de dichas redes sociales disponen de sus propias políticas de protección de datos y de cookies,
                    siendo ellos mismos, en cada caso, responsables de sus propios ficheros y de sus propias prácticas de
                    privacidad. El Usuario debe referirse a las mismas para informarse acerca de dichas cookies y, en su caso,
                    del tratamiento de sus datos personales. Unicamente a título informativo se indican a continuación los
                    enlaces en los que se pueden consultar dichas políticas de privacidad y/o de cookies:
                    Facebook: https://www.facebook.com/policies/cookies/
                    Twitter: https://twitter.com/es/privacy
                    Instagram: https://help.instagram.com/1896641480634370?ref=ig
                    YouTube: https://policies.google.com/privacy?hl=es-419&gl=mx
                    Pinterest: https://policy.pinterest.com/es/privacy-policy
                    LinkedIn: https://www.linkedin.com/legal/cookie-policy?trk=hp-cookies
                    </p>
              </div>
          </div>
        </div>

        <div className="list-section pt-80 pb-80" id="galeria">
          <div className="container">
            <div className="row text-center">
              <div className="col-lg-12">
                <div className="section-title mb-0">
                  <h3 className="text-white">Galería de <span className="orange-text">Fotos</span></h3>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="gallery-slider">

          <div className="proyecto-slider" style={{backgroundImage: "url(../img/blog/ejemplo/galeria-1.jpg)"}}>
            <div className="container">
            </div>
          </div>

          <div className="proyecto-slider" style={{backgroundImage: "url(../img/blog/ejemplo/galeria-2.jpg)"}}>
            <div className="container">
            </div>
          </div>

          <div className="proyecto-slider" style={{backgroundImage: "url(../img/blog/ejemplo/galeria-3.jpg)"}}>
            <div className="container">
            </div>
          </div>

          <div className="proyecto-slider" style={{backgroundImage: "url(../img/blog/ejemplo/galeria-4.jpg)"}}>
            <div className="container">
            </div>
          </div>

          <div className="proyecto-slider" style={{backgroundImage: "url(../img/blog/ejemplo/galeria-5.jpg)"}}>
            <div className="container">
            </div>
          </div>
        </div>

        <div className="latest-news pt-150 pb-150 fondo-textura4" id="casas">
            <div className="container">
            <div className="row mt-80">
                  <div className="col-lg-8 offset-lg-2 text-center">
                    <div className="section-title">
                      <h3>Entradas <span className="orange-text">relacionadas</span></h3>
                    </div>
                  </div>
                </div>
                <div className="row mb-80">
                  <div className="col-lg-4 col-md-6">
                    <div className="single-latest-news">
                      <a href="/#">
                        <div className="latest-news-bg blog-4" />
                      </a>
                      <div className="news-text-box">
                        <h3><a href="/#">Titulo blog 1</a></h3>
                        <p className="blog-meta">
                          <span className="author"><i className="fas fa-map-marker-alt" />25/03/2023</span>
                          <span className="date"><i className="fas fa-tag" />Fundacion</span>
                        </p>
                        <p className="excerpt">Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint debitis quis aliquam voluptate! Totam, maxime neque, commodi, excepturi voluptate magnam hic similique inventore repellendus tempora modi doloremque nobis id. Eos?</p>
                        <a href="/#" className="read-more-btn">Seguir leyendo <i className="fas fa-angle-right" /></a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="single-latest-news">
                      <a href="/#">
                        <div className="latest-news-bg blog-5" />
                      </a>
                      <div className="news-text-box">
                        <h3><a href="/#">Titulo blog 2</a></h3>
                        <p className="blog-meta">
                          <span className="author"><i className="fas fa-map-marker-alt" />25/03/2023</span>
                          <span className="date"><i className="fas fa-tag" />Casas</span>
                        </p>
                        <p className="excerpt">Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint debitis quis aliquam voluptate! Totam, maxime neque, commodi, excepturi voluptate magnam hic similique inventore repellendus tempora modi doloremque nobis id. Eos?</p>
                        <a href="/#" className="read-more-btn">Seguir leyendo <i className="fas fa-angle-right" /></a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                    <div className="single-latest-news">
                      <a href="/#">
                        <div className="latest-news-bg blog-6" />
                      </a>
                      <div className="news-text-box">
                        <h3><a href="/#">Titulo blog 3</a></h3>
                        <p className="blog-meta">
                          <span className="author"><i className="fas fa-map-marker-alt" />25/03/2023</span>
                          <span className="date"><i className="fas fa-tag" />Proyectos</span>
                        </p>
                        <p className="excerpt">Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint debitis quis aliquam voluptate! Totam, maxime neque, commodi, excepturi voluptate magnam hic similique inventore repellendus tempora modi doloremque nobis id. Eos?</p>
                        <a href="/#" className="read-more-btn">Seguir leyendo <i className="fas fa-angle-right" /></a>
                      </div>
                    </div>
                  </div>
                </div>
          </div>
        </div>

        <div className="list-section pt-80 pb-80" id="contacto">
          <div className="container">
            <div className="row text-center">
              <div className="col-lg-12">
                <div className="section-title mb-0">
                  <h3 className="text-white">Comunicate con <span className="orange-text">Nosotros</span></h3>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Contact></Contact>

      </body>
    )

}

export default PoliticaCookies;