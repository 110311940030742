import React from "react";
import Proyecto from "../../layouts/Proyecto";
import Navbar from "../../components/Navbar";
import NavbarResponsive from "../../components/NavbarResponsive";

function BalconesDeSanMiguelII() {
  var data = {
    image: "balcones-de-san-miguel-ii-hero",
    nombre: "Balcones de San Miguel II",
    descripcion:
      "Loteo ideal para aquellos que aman estar rodeados de la fauna autóctona serrana, situado sobre el costado norte del arroyo san miguel, brinda una vista y conexión natural única.",
    items: {
      item1: ["fas fa-map-pin", "San Miguel, San Luis", "Ruta N°1 Km 23"],
      item2: [
        "fas fa-expand-arrows-alt",
        "4 Hectareas",
        "Divididas en 27 lotes",
      ],
      item3: ["fas fa-water", "Arroyo San Miguel", "Ubicado al lado del loteo"],
      item4: ["fas fa-tint", "Servicios", "Luz y Agua Potable"],
    },
    servicios: [
      "Agua Potable",
      "Luz",
      "Alumbrado de Calles",
      "Calles terminadas",
    ],
    galeria: [
      "https://terraloteos.com/img/proyectos/Balcones%20de%20San%20Miguel%20II/balcones-de-san-miguel-ii-g1.jpg",
      "https://terraloteos.com/img/proyectos/Balcones%20de%20San%20Miguel%20II/balcones-de-san-miguel-ii-g2.jpg",
      "https://terraloteos.com/img/proyectos/Balcones%20de%20San%20Miguel%20II/balcones-de-san-miguel-ii-g3.jpg",
      "https://terraloteos.com/img/proyectos/Balcones%20de%20San%20Miguel%20II/balcones-de-san-miguel-ii-g4.jpg",
      "https://terraloteos.com/img/proyectos/Balcones%20de%20San%20Miguel%20II/balcones-de-san-miguel-ii-g5.jpg",
    ],
    plano:
      "https://terraloteos.com/img/proyectos/Balcones%20de%20San%20Miguel%20II/balcones-de-san-miguel-ii-plano.jpg",
    mapa: "!1m14!1m8!1m3!1d13454.141421799746!2d-64.9802417!3d-32.5385546!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x7b56a529b90a798b!2sBALCONES%20DE%20SAN%20MIGUEL%20II!5e0!3m2!1ses!2sar!4v1646680141437!5m2!1ses!2sar",
    puntos: {
      punto1: [
        "fas fa-pin",
        "Ubicación Privilegiada",
        "Situado sobre el costado norte del arroyo San Miguel, brinda una vista increíble y rodeada de naturaleza.",
        "../img/proyectos/Balcones%20de%20San%20Miguel%20II/balcones-de-san-miguel-ii-item1.jpg",
      ],
      punto2: [
        "fas fa-arrows-expand",
        "Conexión natural",
        "Un ambiente rodeado de naturaleza, al costado del arroyo San Miguel y ubicado entre grandes terrenos de fauna natural.",
        "https://terraloteos.com/img/proyectos/Balcones%20de%20San%20Miguel%20II/balcones-de-san-miguel-ii-item2.jpg",
      ],
      punto3: [
        "fas fa-arrow-left",
        "Todos los servicios y comodidades",
        "El loteo cuenta con todos los servicios necesarios para llevar una vida tranquila y con todas las comodidades rodeado de la naturaleza. Cuenta con agua potable, luz, alumbrado de calles y calles terminadas.",
        "https://terraloteos.com/img/proyectos/Balcones%20de%20San%20Miguel%20II/balcones-de-san-miguel-ii-item3.jpg",
      ],
    },
  };

  const setNavbar = () => {
    if (window.screen.width < 800) {
      return <NavbarResponsive />;
    } else {
      return <Navbar active={3} />;
    }
  };

  return (
    <>
      {setNavbar()}
      <Proyecto data={data}></Proyecto>
    </>
  );
}

export default BalconesDeSanMiguelII;
