import React from "react";

import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from "reactstrap";

function ModalCookies( {modal, toggle, cookiesFunction} ) {

    return (
        <div>
            <Modal isOpen={modal} centered contentClassName="card"> 
                <ModalHeader className="modal-header" close>Aviso de Cookies</ModalHeader>
                <ModalBody>
                    <div className="pt-4 pb-4">
                        <p className="mb-0">Utilizamos cookies propias y de terceros con el fin de mejorar nuestros servicios y ofrecer una mejor experiencia de usuario. Si continúa con la navegación, consideramos que acepta este uso.</p>
                        <a href="#" className="leer-mas">Leer más</a>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <a className="primary-btn" onClick={cookiesFunction}>Aceptar</a>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default ModalCookies;
