import React from "react";
import Banner from "../components/Banner";
import BannerResponsive from "../components/BannerResponsive";
import Contact from "../components/Contact";

function Proyecto(props) {
  const setBanner = () => {
    if (window.screen.width < 800) {
      return <BannerResponsive items={props.data.items} />;
    } else {
      return <Banner items={props.data.items} />;
    }
  };

  const mostrarPlano = () => {
    if (props.data.plano !== "") {
      return (
        <>
          <div className="list-section pt-80 pb-80" id="plano">
            <div className="container">
              <div className="row text-center">
                <div className="col-lg-12">
                  <div className="section-title mb-0">
                    <h3 className="text-white">
                      Disposición del{" "}
                      <span className="orange-text">Barrio</span>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <section
            className="proyecto-plano"
            style={{ backgroundImage: `url(${props.data.plano})` }}
          >
            <div className="container text-center"></div>
          </section>
        </>
      );
    }
  };

  const setTitle = (position) => {
    var name = props.data.nombre;
    var nameCut = name.split(" ");
    switch (nameCut.length) {
      case 1:
        if (position === 1) {
          return "Proyecto";
        } else {
          return nameCut[0];
        }
      case 2:
        if (position === 1) {
          return nameCut[0];
        } else {
          return nameCut[1];
        }
      case 3:
        if (position === 1) {
          return nameCut[0] + " " + nameCut[1];
        } else {
          return nameCut[2];
        }
      case 4:
        if (position === 1) {
          return nameCut[0] + " " + nameCut[1];
        } else {
          return nameCut[2] + " " + nameCut[3];
        }
      case 5:
        if (position === 1) {
          return nameCut[0] + " " + nameCut[1];
        } else {
          return nameCut[2] + " " + nameCut[3] + " " + nameCut[4];
        }
      default:
        return name;
    }
  };

  return (
    <body>
      <div className="search-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <span className="close-btn">
                <i className="fas fa-times" />
              </span>
              <div className="search-bar">
                <div className="search-bar-tablecell">
                  <a href="/">
                    <h3>Home</h3>
                  </a>
                  <a href="/asesores" className="close-btn">
                    <h3>Asesores</h3>
                  </a>
                  <a href="/fundacion">
                    <h3>Fundación</h3>
                  </a>
                  <a href="/oficinas">
                    <h3>Oficinas</h3>
                  </a>
                  <a href="/proyectos">
                    <h3>Proyectos</h3>
                  </a>
                  <a href="/casas">
                    <h3>Casas</h3>
                  </a>
                  <a href="/blog">
                    <h3>Blog</h3>
                  </a>
                  <a href="/clientes">
                    <h3>Clientes</h3>
                  </a>
                  <button type="submit" className="close-btn" id="close-btn">
                    Volver <i className="fas fa-arrow-up" />
                  </button>
                  <a href="/login">
                    <h5>Ingresar</h5>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className={"proyecto-hero " + props.data.image}>
        <div className="container text-center">
          <h3>
            Proyecto
            <br />
            <span className="orange-text">{props.data.nombre}</span>
          </h3>
          <a href="#oficinas" className="btn-bajar btn-lg">
            <i className="fas fa-arrow-down mr-0" />
          </a>
        </div>
      </section>

      {setBanner()}

      <div className="latest-news pt-150 pb-150 fondo-textura3" id="casas">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2 text-center">
              <div className="section-title">
                <h3>
                  {setTitle(1)}
                  <span className="orange-text"> {setTitle(2)}</span>
                </h3>
                <p>{props.data.descripcion}</p>
              </div>
            </div>
          </div>
          <div className="row single-descripcion">
            <div className="col-lg-4 col-md-6 pr-0 pl-0">
              <a href="/#">
                <img
                  className="imagen-descripcion ajustar-imagen"
                  src={props.data.puntos.punto1[3]}
                  alt=""
                />
              </a>
            </div>
            <div className="col-lg-8 col-md-6 pr-0 pl-0">
              <div className="caja-descripcion centrado-vertical">
                <div>
                  <h3>{props.data.puntos.punto1[1]}</h3>
                  <p className="product-price">
                    <span>{props.data.puntos.punto1[2]}</span>
                  </p>
                  <a href="#mapa" className="cart-btn">
                    <i className="fas fa-map-marker-alt" /> Ver mapa
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="row single-descripcion">
            <div className="col-lg-4 col-md-6 pr-0 pl-0">
              <a href="/#">
                <img
                  className="imagen-descripcion ajustar-imagen"
                  src={props.data.puntos.punto2[3]}
                  alt=""
                />
              </a>
            </div>
            <div className="col-lg-8 col-md-6 pr-0 pl-0">
              <div className="caja-descripcion centrado-vertical">
                <div>
                  <h3>{props.data.puntos.punto2[1]}</h3>
                  <p className="product-price">
                    <span>{props.data.puntos.punto2[2]}</span>
                  </p>
                  <a href="#galeria" className="cart-btn">
                    <i className="fas fa-camera" /> Ver fotos
                  </a>
                </div>
              </div>
            </div>
          </div>
          {props.data.puntos.punto3 && (
            <div className="row single-descripcion">
              <div className="col-lg-4 col-md-6 pr-0 pl-0">
                <a href="/#">
                  <img
                    className="imagen-descripcion ajustar-imagen"
                    src={props.data.puntos.punto3[3]}
                    alt=""
                  />
                </a>
              </div>
              <div className="col-lg-8 col-md-6 pr-0 pl-0">
                <div className="caja-descripcion centrado-vertical">
                  <div>
                    <h3>{props.data.puntos.punto3[1]}</h3>
                    <p className="product-price">
                      <span>{props.data.puntos.punto3[2]}</span>
                    </p>
                    <a href="#plano" className="cart-btn">
                      <i className="fas fa-map" /> Ver plano
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-lg-12 text-center">
              <a href="#contacto" className="boxed-btn">
                Contactanos
              </a>
            </div>
          </div>
        </div>
      </div>

      {props.data.galeria.length > 0 && (
        <>
          <div className="list-section pt-80 pb-80" id="galeria">
            <div className="container">
              <div className="row text-center">
                <div className="col-lg-12">
                  <div className="section-title mb-0">
                    <h3 className="text-white">
                      Galería de <span className="orange-text">Fotos</span>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="gallery-slider">
            <div
              className="proyecto-slider"
              style={{ backgroundImage: `url(${props.data.galeria[0]})` }}
            >
              <div className="container"></div>
            </div>

            <div
              className="proyecto-slider"
              style={{ backgroundImage: `url(${props.data.galeria[1]})` }}
            >
              <div className="container"></div>
            </div>

            {props.data.galeria[2] && (
              <div
                className="proyecto-slider"
                style={{ backgroundImage: `url(${props.data.galeria[2]})` }}
              >
                <div className="container"></div>
              </div>
            )}

            {props.data.galeria[3] && (
              <div
                className="proyecto-slider"
                style={{ backgroundImage: `url(${props.data.galeria[3]})` }}
              >
                <div className="container"></div>
              </div>
            )}

            {props.data.galeria[4] && (
              <div
                className="proyecto-slider"
                style={{ backgroundImage: `url(${props.data.galeria[4]})` }}
              >
                <div className="container"></div>
              </div>
            )}
          </div>
        </>
      )}

      {mostrarPlano()}

      <div className="list-section pt-80 pb-80" id="mapa">
        <div className="container">
          <div className="row text-center">
            <div className="col-lg-12">
              <div className="section-title mb-0">
                <h3 className="text-white">
                  Como <span className="orange-text">Llegar</span>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="embed-responsive embed-responsive-21by9">
        <iframe
          title="map"
          src={"https://www.google.com/maps/embed?pb=" + props.data.mapa}
          style={{ border: 0 }}
          allowFullScreen
          className="embed-responsive-item"
          width={600}
          height={450}
          frameBorder={0}
        />
      </div>

      <div className="list-section pt-80 pb-80" id="contacto">
        <div className="container">
          <div className="row text-center">
            <div className="col-lg-12">
              <div className="section-title mb-0">
                <h3 className="text-white">
                  Comunicate con <span className="orange-text">Nosotros</span>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Contact></Contact>
    </body>
  );
}

export default Proyecto;
