import React from "react";
import Proyecto from "../../layouts/Proyecto";
import Navbar from "../../components/Navbar";
import NavbarResponsive from "../../components/NavbarResponsive";

function PalmaresDeBalcarce() {
  var data = {
    image: "palmares-de-balcarce-hero",
    nombre: "Palmares de Balcarce",
    descripcion:
      "Emprendimiento situado a 3000 metros de la ruta Nº1. Su principal característica se encuentra dada por su flora autóctona, la palmera caranday, que ornamenta y configura un espacio único y especial.",
    items: {
      item1: ["fas fa-map-pin", "Cortaderas, San Luis", "Ruta N°1 Km 24"],
      item2: [
        "fas fa-expand-arrows-alt",
        "1 Hectarea",
        "Dividida en 150 lotes",
      ],
      item3: [
        "fas fa-lightbulb",
        "Infraestructura",
        "Alumbrado de Calles, Calles terminadas",
      ],
      item4: ["fas fa-tint", "Servicios", "Luz, Agua Potable, Cloacas"],
    },
    servicios: [
      "Agua Potable",
      "Luz",
      "Alumbrado de Calles",
      "Calles terminadas",
    ],
    galeria: [
      "https://terraloteos.com/img/proyectos/Palmares%20de%20Balcarce/palmares-de-balcarce-1.jpg",
      "https://terraloteos.com/img/proyectos/Palmares%20de%20Balcarce/palmares-de-balcarce-hero.jpg",
    ],
    plano:
      "",
    mapa: "!1m18!1m12!1m3!1d11899.083860037914!2d-65.02528836128332!3d-32.545800600129695!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95d2fec76bc728d1%3A0x2f99944a1f34ffb1!2sPalmares%20de%20Balcarce!5e0!3m2!1ses!2sar!4v1681310784319!5m2!1ses!2sar",
    puntos: {
      punto1: [
        "fas fa-pin",
        "Ubicación Privilegiada",
        "Su ubicación estratégica lo convierte en una excelente opción para aquellos que buscan un lugar cercano a la naturaleza pero con fácil acceso a las comodidades de la ciudad. El emprendimiento se encuentra a pocos metros del ingreso al Dique Piscu Yaco, lo que facilita el acceso y ofrece múltiples opciones de recreación al aire libre.",
        "https://terraloteos.com/img/proyectos/Palmares%20de%20Balcarce/palmares-de-balcarce-1.jpg",
      ],
      punto2: [
        "fas fa-arrows-expand",
        "Conexión natural",
        "El Loteo Palmares de Balcarce se caracteriza por la presencia de la palmera caranday, una especie autóctona de la región que configura un espacio natural único y especial.",
        "https://terraloteos.com/img/proyectos/Palmares%20de%20Balcarce/palmares-de-balcarce-hero.jpg",
      ],
    },
  };

  const setNavbar = () => {
    if (window.screen.width < 800) {
      return <NavbarResponsive />;
    } else {
      return <Navbar active={3} />;
    }
  };

  return (
    <>
      {setNavbar()}
      <Proyecto data={data}></Proyecto>
    </>
  );
}

export default PalmaresDeBalcarce;
