import React from "react";
import Banner from '../components/Banner';
import Navbar from "../components/Navbar";
import NavbarResponsive from "../components/NavbarResponsive";
import Contact from "../components/Contact";
import CountUp from 'react-countup';

function Fundacion() {

    const setNavbar = () => {
      if (window.screen.width < 800) {
        return(<NavbarResponsive/>)
      }else{    
        return(<Navbar active={7}/>)
      }
    }

    return(
      <div>
        {setNavbar()}
        <body>
        <div className="search-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <span className="close-btn"><i className="fas fa-times" /></span>
                <div className="search-bar">
                  <div className="search-bar-tablecell">
                    <a href="/"><h3>Home</h3></a>
                    <a href="/asesores" className="close-btn"><h3>Asesores</h3></a>
                    <a href="/fundacion"><h3>Fundación</h3></a>
                    <a href="/oficinas"><h3>Oficinas</h3></a>
                    <a href="/proyectos"><h3>Proyectos</h3></a>
                    <a href="/casas"><h3>Casas</h3></a>
                    <a href="/blog"><h3>Blog</h3></a>
                    <a href="/clientes">
                      <h3>Clientes</h3>
                    </a>
                    <button type="submit" className="close-btn" id="close-btn">Volver <i className="fas fa-arrow-up" /></button>
                    <a href="/login"><h5>Ingresar</h5></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="fundacion-hero">
          <div className="container text-center">
            <img src="/img/fundacion/Fundacion Grupo Terra Logo.png" alt="Logo Fundacion" />
            <br />
            {/* <i className="fas fa-tree arbol" />
            <br />
            <span className="contador"><CountUp end={3412} /></span>
            <br />
            <p>Árboles Plantados</p> */}
            <a href="#detalles" className="btn-bajar btn-lg"><i className="fas fa-arrow-down mr-0" /></a>
          </div>
        </section>

        <div className="latest-news pt-150 pb-150 fondo-textura4" id="detalles">
            <div className="container">
              <div className="col-lg-8 offset-lg-2 text-center">
                <div className="section-title">
                  <h3>¿Quienes<span className="orange-text"> somos?</span></h3>
                </div>
              </div>
              <div className="col-lg-10 blog-body">
                  <p>
                    <strong>Fundación Grupo Terra</strong> nace como parte de nuestro compromiso con el cuidado y concientización del medio ambiente, la flora y la fauna autóctona de la Región , y ante  la necesidad de poner en acción la ayuda concreta para educar y hacer.
                    <br />
                    Desde la semilla que da vida y hace un llamado ante las actuales circunstancias del planeta y de la humanidad, la producción de árboles, la reforestación y la necesidad de fortalecer los recursos ecológicos nos unimos para generar un equilibrio social para mejorar la calidad de vida de nuestras comunidades  logrando implementar estrategias de sostenibilidad.
                    <br />
                    <br />
                    <strong>Nuestra Misión:</strong>
                    <br />
                    Ser guardianes de nuestro planeta, realizando acciones para concientizar y generar un mundo verde con proyectos diversificados de manera innovadora responsable y sostenible.
                    <br />
                    <br />
                    <strong>Nuestra Visión:</strong>
                    <br />
                    Enfocados en contribuir al mejoramiento de la calidad de vida de las comunidades y el ecosistema para una calidad de vida en equilibrio con la naturaleza.
                    <br />
                    <br />
                    <strong>Nuestros Valores:</strong>
                    <br />
                    Respeto, Pasión, Compromiso y Responsabilidad Social Empresarial. 
                    <br />
                  </p>
              </div>
              <div className="row">
                <div className="col-lg-12 text-center">
                  <a href="https://www.instagram.com/fundacion_grupo_terra/" target="_blank" rel="noopener noreferrer" className="social-btn-instagram ml-2 mr-2"><i className="fab fa-instagram mr-2"/> Seguinos</a>
                </div>
              </div>
          </div>
        </div>

        <div className="list-section pt-80 pb-80" id="contacto">
          <div className="container">
            <div className="row text-center">
              <div className="col-lg-12">
                <div className="section-title mb-0">
                  <h3 className="text-white">Comunicate con <span className="orange-text">Nosotros</span></h3>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Contact></Contact>

      </body>
      </div>
    )

}

export default Fundacion;