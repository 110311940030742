import React from "react";
import Banner from "../components/Banner";
import Navbar from "../components/Navbar";
import NavbarResponsive from "../components/NavbarResponsive";
import Contact from "../components/Contact";

function Proyectos() {
  const setNavbar = () => {
    if (window.screen.width < 800) {
      return <NavbarResponsive />;
    } else {
      return <Navbar active={3} />;
    }
  };

  const proyectos = [
    {
      titulo: "Los Alamos",
      descripcion:
        "Desarrollo urbanístico ubicado en el Extremo noreste de la ciudad de Villa Mercedes, con ingreso por vía de acceso Intendente Almada. A solo 10” del corazón de Villa Mercedes.",
      direccion: "Intendente Almada Extremo Oeste",
      tamano_hectareas: 16,
      tamano_loteos: 302,
      servicios:
        "Agua Potable, Luz, Cloacas, Alumbrado de Calles, Calles terminadas",
      puntos_interes: "A solo 10' del centro de la ciudad de Villa Mercedes.",
    },
    {
      titulo: "Las Chacras",
      descripcion:
        "Buscamos crear un espacio para que puedas construir un lugar único especial para vos, tu casa de fin de semana, un lugar para encontrarte con vos mismo, para realizar tus proyectos personales, tu finca o chacra.",
      direccion: "Av. Circunvalación Extremo Norte - Ruta 148",
      tamano_hectareas: 135,
      tamano_loteos: 27,
      servicios: "Agua Potable, Luz, Alumbrado de Calles, Calles terminadas",
      puntos_interes:
        "Ubicado en la Av. Circunvalación de la Ciudad de Villa Mercedes, a pocos minutos del corazón de la ciudad.",
    },
    {
      titulo: "Palmares de Balcarce",
      descripcion:
        "Emprendimiento urbanístico situado a 3000 metros de la ruta Nº1. Su principal característica se encuentra dada por su flora autóctona, la palmera caranday, que ornamenta y configura un espacio único y especial.",
      direccion: "Ruta Nº1 Km 24, Cortaderas, San Luis.",
      tamano_hectareas: 1,
      tamano_loteos: 150,
      servicios: "Agua Potable, Luz, Alumbrado de Calles, Calles terminadas",
      puntos_interes:
        "Extensión del Loteo Palmares de Balcarce. A pocos metros del ingreso al Dique Piscu Yaco.",
    },
  ];

  return (
    <div>
      {setNavbar()}
      <body>
        <div className="search-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <span className="close-btn">
                  <i className="fas fa-times" />
                </span>
                <div className="search-bar">
                  <div className="search-bar-tablecell">
                    <a href="/">
                      <h3>Home</h3>
                    </a>
                    <a href="/#nosotros" className="close-btn">
                      <h3>Nosotros</h3>
                    </a>
                    <a href="/fundacion">
                      <h3>Fundación</h3>
                    </a>
                    <a href="/oficinas">
                      <h3>Oficinas</h3>
                    </a>
                    <a href="/proyectos">
                      <h3>Proyectos</h3>
                    </a>
                    <a href="/casas">
                      <h3>Casas</h3>
                    </a>
                    <a href="/blog">
                      <h3>Blog</h3>
                    </a>
                    <a href="/clientes">
                      <h3>Clientes</h3>
                    </a>
                    <button type="submit" className="close-btn" id="close-btn">
                      Volver <i className="fas fa-arrow-up" />
                    </button>
                    <a href="/login">
                      <h5>Ingresar</h5>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="proyecto-hero">
          <div className="container text-center">
            <h3>
              Conocé nuestros
              <br />
              <span className="orange-text">Proyectos</span>
            </h3>
            <a href="#proyectos" className="btn-bajar btn-lg">
              <i className="fas fa-arrow-down mr-0" />
            </a>
          </div>
        </section>

        <div className="latest-news pt-150 pb-150 fondo-textura" id="proyectos">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2 text-center">
                <div className="section-title">
                  <h3>
                    Nuestros<span className="orange-text"> Proyectos</span>
                  </h3>
                  <p>
                    En todos nuestros desarrollos nos enfocamos en crear el
                    ambiente ideal para que puedas comenzar una nueva etapa de
                    tu vida, brindandote la mejor comodidad, todos los
                    servicios, seguridad y todo lo que necesita tu nuevo hogar
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="single-latest-news">
                  <a href="/proyectos/los-alamos">
                    <div className="latest-news-bg los-alamos-item" />
                  </a>
                  <div className="news-text-box">
                    <h3>
                      <a href="/proyectos/los-alamos">Los Alamos</a>
                    </h3>
                    <p className="blog-meta">
                      <span className="author">
                        <i className="fas fa-map-marker-alt" /> Villa Mercedes,
                        San Luis
                      </span>
                      <span className="date">
                        <i className="fas fa-sign" /> 302 Lotes
                      </span>
                    </p>
                    <p className="excerpt">
                      Desarrollo urbanístico ubicado en el extremo noreste de la
                      ciudad de Villa Mercedes, con ingreso por vía de acceso
                      Intendente Almada. A solo 10” del corazón de Villa
                      Mercedes.
                    </p>
                    <a href="/proyectos/los-alamos" className="read-more-btn">
                      Ver más <i className="fas fa-angle-right" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="single-latest-news">
                  <a href="/proyectos/las-chacras">
                    <div className="latest-news-bg las-chacras-item" />
                  </a>
                  <div className="news-text-box">
                    <h3>
                      <a href="/proyectos/las-chacras">Las Chacras</a>
                    </h3>
                    <p className="blog-meta">
                      <span className="author">
                        <i className="fas fa-map-marker-alt" /> Villa Mercedes,
                        San Luis
                      </span>
                      <span className="date">
                        <i className="fas fa-sign" /> 27 Lotes
                      </span>
                    </p>
                    <p className="excerpt">
                      Buscamos crear un espacio para que puedas construir un
                      lugar único especial para vos, tu casa de fin de semana,
                      un lugar para encontrarte con vos mismo, para realizar tus
                      proyectos personales, tu finca o chacra.
                    </p>
                    <a href="/proyectos/las-chacras" className="read-more-btn">
                      Ver más <i className="fas fa-angle-right" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div className="single-latest-news">
                  <a href="/proyectos/palmares-de-balcarce">
                    <div className="latest-news-bg palmares-de-balcarce-item" />
                  </a>
                  <div className="news-text-box">
                    <h3>
                      <a href="/proyectos/palmares-de-balcarce">
                        Palmares de Balcarce
                      </a>
                    </h3>
                    <p className="blog-meta">
                      <span className="author">
                        <i className="fas fa-map-marker-alt" /> Cortaderas, San
                        Luis
                      </span>
                      <span className="date">
                        <i className="fas fa-sign" /> 150 Lotes
                      </span>
                    </p>
                    <p className="excerpt">
                      Emprendimiento situado a 3000 metros de la ruta Nº1. Su
                      principal característica se encuentra dada por su flora
                      autóctona, la palmera caranday, que ornamenta y configura
                      un espacio único y especial.
                    </p>
                    <a
                      href="/proyectos/palmares-de-balcarce"
                      className="read-more-btn"
                    >
                      Ver más <i className="fas fa-angle-right" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="single-latest-news">
                  <a href="/proyectos/estacion-conlara">
                    <div className="latest-news-bg estacion-conlara-item" />
                  </a>
                  <div className="news-text-box">
                    <h3>
                      <a href="/proyectos/estacion-conlara">Estación Conlara</a>
                    </h3>
                    <p className="blog-meta">
                      <span className="author">
                        <i className="fas fa-map-marker-alt" /> Santa Rosa del
                        Conlara, S.L.
                      </span>
                      <span className="date">
                        <i className="fas fa-sign" /> 992 Lotes
                      </span>
                    </p>
                    <p className="excerpt">
                      Mega emprendimiento ideal para que comiences a vivir tu
                      nueva vida cerca de Merlo, con amplios espacios naturales,
                      una laguna propia y vistas privilegiadas de las sierras.
                    </p>
                    <a
                      href="/proyectos/estacion-conlara"
                      className="read-more-btn"
                    >
                      Ver más <i className="fas fa-angle-right" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="single-latest-news">
                  <a href="/proyectos/ayres-de-larca">
                    <div className="latest-news-bg ayres-de-larca-item" />
                  </a>
                  <div className="news-text-box">
                    <h3>
                      <a href="/proyectos/ayres-de-larca">Ayres de Larca</a>
                    </h3>
                    <p className="blog-meta">
                      <span className="author">
                        <i className="fas fa-map-marker-alt" /> Cortaderas, San
                        Luis
                      </span>
                      <span className="date">
                        <i className="fas fa-sign" /> 515 Lotes
                      </span>
                    </p>
                    <p className="excerpt">
                      Emprendimiento que mezcla el concepto de sierra y valle,
                      caracterizado por tener una vista única y privilegiada a
                      las Sierras de los Comechingones acompañado del verde
                      característico del valle.
                    </p>
                    <a
                      href="/proyectos/ayres-de-larca"
                      className="read-more-btn"
                    >
                      Ver más <i className="fas fa-angle-right" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div className="single-latest-news">
                  <a href="/proyectos/valle-de-los-comechingones">
                    <div className="latest-news-bg valle-de-los-comechingones-item" />
                  </a>
                  <div className="news-text-box">
                    <h3>
                      <a href="/proyectos/valle-de-los-comechingones">
                        Valle de los Comechingones
                      </a>
                    </h3>
                    <p className="blog-meta">
                      <span className="author">
                        <i className="fas fa-map-marker-alt" /> Villa de Merlo,
                        San Luis
                      </span>
                      <span className="date">
                        <i className="fas fa-sign" /> 24 Lotes
                      </span>
                    </p>
                    <p className="excerpt">
                      Emprendimiento ubicado en las cercanías de Merlo, con una
                      ubicación ideal que permite disfrutar del paisaje de las
                      sierrras y el valle de los Comechingones con la ventaja de
                      la cercanía al centro de la ciudad.
                    </p>
                    <a
                      href="/proyectos/valle-de-los-comechingones"
                      className="read-more-btn"
                    >
                      Ver más <i className="fas fa-angle-right" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="single-latest-news">
                  <a href="/proyectos/terrazas-del-talar">
                    <div className="latest-news-bg terrazas-del-talar-item" />
                  </a>
                  <div className="news-text-box">
                    <h3>
                      <a href="/proyectos/terrazas-del-talar">
                        Terrazas del Talar
                      </a>
                    </h3>
                    <p className="blog-meta">
                      <span className="author">
                        <i className="fas fa-map-marker-alt" /> Los Molles, San
                        Luis
                      </span>
                      <span className="date">
                        <i className="fas fa-sign" /> 86 Lotes
                      </span>
                    </p>
                    <p className="excerpt">
                      Terrazas del Talar es un loteo exclusivo que nos propone
                      una experiencia única en conjunto con la naturaleza,
                      garantizando comodidad, bienestar y calidad de vida para
                      quienes deciden invertir.
                    </p>
                    <a
                      href="/proyectos/terrazas-del-talar"
                      className="read-more-btn"
                    >
                      Ver más <i className="fas fa-angle-right" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="single-latest-news">
                  <a href="/proyectos/balcones-de-san-miguel">
                    <div className="latest-news-bg balcones-de-san-miguel-ii-item" />
                  </a>
                  <div className="news-text-box">
                    <h3>
                      <a href="/proyectos/balcones-de-san-miguel">
                        Balcones de San Miguel II
                      </a>
                    </h3>
                    <p className="blog-meta">
                      <span className="author">
                        <i className="fas fa-map-marker-alt" /> San Miguel, San
                        Luis
                      </span>
                      <span className="date">
                        <i className="fas fa-sign" /> 27 Lotes
                      </span>
                    </p>
                    <p className="excerpt">
                      Loteo ideal para aquellos que aman estar rodeados de la
                      fauna autóctona serrana, situado sobre el costado norte
                      del arroyo san miguel, brinda una vista y conexión natural
                      única.
                    </p>
                    <a
                      href="/proyectos/balcones-de-san-miguel"
                      className="read-more-btn"
                    >
                      Ver más <i className="fas fa-angle-right" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div className="single-latest-news">
                  <a href="/proyectos/pueblo-mio">
                    <div className="latest-news-bg pueblo-mio-item" />
                  </a>
                  <div className="news-text-box">
                    <h3>
                      <a href="/proyectos/pueblo-mio">Pueblo Mío</a>
                    </h3>
                    <p className="blog-meta">
                      <span className="author">
                        <i className="fas fa-map-marker-alt" /> Villa Larca, San
                        Luis
                      </span>
                      <span className="date">
                        <i className="fas fa-sign" /> 250 Lotes
                      </span>
                    </p>
                    <p className="excerpt">
                      Situado frente al "Chorro de San Ignacio", este loteo está
                      pensado para aquellos que buscan espacios amplios con
                      naturaleza autoctona, con vista privilegiada de las
                      Sierras de los Comechingones.
                    </p>
                    <a href="/proyectos/pueblo-mio" className="read-more-btn">
                      Ver más <i className="fas fa-angle-right" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="single-latest-news">
                  <a href="/proyectos/merlo-village">
                    <div className="latest-news-bg merlo-village-item" />
                  </a>
                  <div className="news-text-box">
                    <h3>
                      <a href="/proyectos/merlo-village">Merlo Village</a>
                    </h3>
                    <p className="blog-meta">
                      <span className="author">
                        <i className="fas fa-map-marker-alt" /> Villa de Merlo,
                        San Luis
                      </span>
                      <span className="date">
                        <i className="fas fa-sign" /> 744 Lotes
                      </span>
                    </p>
                    <p className="excerpt">
                      Con una ubicación privilegiada, a 5 min del ingreso a
                      Villa de Merlo, este emprendimiento pensado en la vida
                      natural y urbana cuenta con amplios espacios verdes y un
                      extenso centro comercial.
                    </p>
                    <a
                      href="/proyectos/merlo-village"
                      className="read-more-btn"
                    >
                      Ver más <i className="fas fa-angle-right" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="single-latest-news">
                  <a href="/proyectos/estacion-del-valle">
                    <div className="latest-news-bg estacion-del-valle-item" />
                  </a>
                  <div className="news-text-box">
                    <h3>
                      <a href="/proyectos/estacion-del-valle">
                        Estación del Valle
                      </a>
                    </h3>
                    <p className="blog-meta">
                      <span className="author">
                        <i className="fas fa-map-marker-alt" /> Santa Rosa del
                        Conlara, S.L.
                      </span>
                      <span className="date">
                        <i className="fas fa-sign" /> 2443 Lotes
                      </span>
                    </p>
                    <p className="excerpt">
                      Megaemprendimiento dividido en 5 etapas, ubicación
                      privilegiada a solo 8 minutos de Villa de Merlo, todos los
                      servicios, grandes espacios verdes y excelente vista a las
                      sierras.
                    </p>
                    <a
                      href="/proyectos/estacion-del-valle"
                      className="read-more-btn"
                    >
                      Ver más <i className="fas fa-angle-right" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div className="single-latest-news">
                  <a href="/proyectos/miralba">
                    <div className="latest-news-bg miralba-item" />
                  </a>
                  <div className="news-text-box">
                    <h3>
                      <a href="/proyectos/miralba">Miralba</a>
                    </h3>
                    <p className="blog-meta">
                      <span className="author">
                        <i className="fas fa-map-marker-alt" /> La Cañada, La
                        Punta, San Luis
                      </span>
                      <span className="date">
                        <i className="fas fa-sign" /> 572 Lotes
                      </span>
                    </p>
                    <p className="excerpt">
                      Nueva urbanización destinada a mejorar la calidad de vida
                      de sus habitantes. La eficiencia a la hora de prestar
                      servicios, la sostenibilidad económica, social y ambiental
                      la caracterizan.
                    </p>
                    <a href="/proyectos/miralba" className="read-more-btn">
                      Ver más <i className="fas fa-angle-right" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="list-section pt-80 pb-80" id="contacto">
          <div className="container">
            <div className="row text-center">
              <div className="col-lg-12">
                <div className="section-title mb-0">
                  <h3 className="text-white">
                    Comunicate con <span className="orange-text">Nosotros</span>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Contact></Contact>
      </body>
    </div>
  );
}

export default Proyectos;
