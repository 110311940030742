import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Contact from "../components/Contact";
import Navbar from "../components/Navbar";
import NavbarResponsive from "../components/NavbarResponsive";
import fire from "../config/Fire";
import { collection, getDocs, getFirestore } from "firebase/firestore";
import parse from "html-react-parser";

function BlogSingle() {
  const { blogUrl } = useParams();

  const db = getFirestore(fire);
  const [blogs, setBlogs] = useState([]);

  const getBlogs = async () => {
    let arrayResultados = [];
    const querySnapshot = await getDocs(collection(db, "blogs"));
    querySnapshot.forEach((doc) => {
      arrayResultados.push(doc.data());
    });
    setBlogs(arrayResultados);
  };

  const setNavbar = () => {
    if (window.screen.width < 800) {
      return <NavbarResponsive />;
    } else {
      return <Navbar active={6} />;
    }
  };

  const showData = () => {
    getBlogs();
    const blog = blogs.filter((blog) => blog.url === blogUrl);
    if (blog.length > 0) {
      //cut the string in the half of words
      const blogTitle = blog[0].title.split(" ");
      const blogTitleCut1 = [];
      const blogTitleCut2 = [];
      for (let i = 0; i < blogTitle.length; i++) {
        if (i < blogTitle.length / 2) {
          blogTitleCut1.push(blogTitle[i]);
        } else {
          blogTitleCut2.push(blogTitle[i]);
        }
      }
      const blogTitleCutString1 = blogTitleCut1.join(" ");
      const blogTitleCutString2 = blogTitleCut2.join(" ");

      const blogContent = blog[0].contenido;
      return (
        <>
          <section
            className="proyecto-hero"
            style={{
              backgroundImage:
                "linear-gradient(rgba(19, 50, 34, 0.7),rgba(19, 50, 34, 0.7)) , url(../img/blog/avances-de-obra-abril/hero.jpg)",
            }}
          >
            <div className="container text-center">
              <h3>
                {blogTitleCutString1}
                <br />
                <span className="orange-text"> {blogTitleCutString2}</span>
              </h3>
              <a href="#blog" className="btn-bajar btn-lg">
                <i className="fas fa-arrow-down mr-0" />
              </a>
            </div>
          </section>

          <div className="latest-news pt-150 pb-150 fondo-textura4" id="blog">
            <div className="container">
              <div className="col-lg-8 offset-lg-2 text-center">
                <div className="section-title">
                  <h3>
                    {blogTitleCutString1}
                    <span className="orange-text"> {blogTitleCutString2}</span>
                  </h3>
                </div>
              </div>
              <div className="col-lg-10 blog-body">{parse(blogContent)}</div>
              <div className="row">
                <div className="col-lg-12 text-center">
                  <a
                    href={
                      "https://twitter.com/intent/tweet?text=" +
                      blogTitleCutString1 +
                      " " +
                      blogTitleCutString2 +
                      "&url=https://www.terraloteos.com/blog/" +
                      blogUrl
                    }
                    className="social-btn-twitter ml-2 mr-2"
                  >
                    <i className="fab fa-twitter mr-2" /> Compartir
                  </a>
                  <a
                    href={
                      "https://www.facebook.com/sharer/sharer.php?u=https://www.terraloteos.com/blog/" +
                      blogUrl
                    }
                    className="social-btn-facebook ml-2 mr-2"
                  >
                    <i className="fab fa-facebook mr-2" /> Compartir
                  </a>
                  <a
                    href="https://www.instagram.com/terraloteos/"
                    className="social-btn-instagram ml-2 mr-2"
                  >
                    <i className="fab fa-instagram mr-2" /> Seguinos
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="list-section pt-80 pb-80" id="galeria">
          <div className="container">
            <div className="row text-center">
              <div className="col-lg-12">
                <div className="section-title mb-0">
                  <h3 className="text-white">Galería de <span className="orange-text">Fotos</span></h3>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="gallery-slider">

          <div className="proyecto-slider" style={{backgroundImage: "url(../img/blog/avances-de-obra-abril/galeria-1.jpg)"}}>
            <div className="container">
            </div>
          </div>

          <div className="proyecto-slider" style={{backgroundImage: "url(../img/blog/avances-de-obra-abril/galeria-1.jpg)"}}>
            <div className="container">
            </div>
          </div>
          
        </div> */}

          {/* <div className="latest-news pt-150 pb-150 fondo-textura4" id="casas">
            <div className="container">
            <div className="row mt-80">
                  <div className="col-lg-8 offset-lg-2 text-center">
                    <div className="section-title">
                      <h3>Entradas <span className="orange-text">relacionadas</span></h3>
                    </div>
                  </div>
                </div>
                <div className="row mb-80">
                  <div className="col-lg-4 col-md-6">
                    <div className="single-latest-news">
                      <a href="/#">
                        <div className="latest-news-bg blog-4" />
                      </a>
                      <div className="news-text-box">
                        <h3><a href="/#">Titulo blog 1</a></h3>
                        <p className="blog-meta">
                          <span className="author"><i className="fas fa-map-marker-alt" />25/03/2023</span>
                          <span className="date"><i className="fas fa-tag" />Fundacion</span>
                        </p>
                        <p className="excerpt">Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint debitis quis aliquam voluptate! Totam, maxime neque, commodi, excepturi voluptate magnam hic similique inventore repellendus tempora modi doloremque nobis id. Eos?</p>
                        <a href="/#" className="read-more-btn">Seguir leyendo <i className="fas fa-angle-right" /></a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="single-latest-news">
                      <a href="/#">
                        <div className="latest-news-bg blog-5" />
                      </a>
                      <div className="news-text-box">
                        <h3><a href="/#">Titulo blog 2</a></h3>
                        <p className="blog-meta">
                          <span className="author"><i className="fas fa-map-marker-alt" />25/03/2023</span>
                          <span className="date"><i className="fas fa-tag" />Casas</span>
                        </p>
                        <p className="excerpt">Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint debitis quis aliquam voluptate! Totam, maxime neque, commodi, excepturi voluptate magnam hic similique inventore repellendus tempora modi doloremque nobis id. Eos?</p>
                        <a href="/#" className="read-more-btn">Seguir leyendo <i className="fas fa-angle-right" /></a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                    <div className="single-latest-news">
                      <a href="/#">
                        <div className="latest-news-bg blog-6" />
                      </a>
                      <div className="news-text-box">
                        <h3><a href="/#">Titulo blog 3</a></h3>
                        <p className="blog-meta">
                          <span className="author"><i className="fas fa-map-marker-alt" />25/03/2023</span>
                          <span className="date"><i className="fas fa-tag" />Proyectos</span>
                        </p>
                        <p className="excerpt">Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint debitis quis aliquam voluptate! Totam, maxime neque, commodi, excepturi voluptate magnam hic similique inventore repellendus tempora modi doloremque nobis id. Eos?</p>
                        <a href="/#" className="read-more-btn">Seguir leyendo <i className="fas fa-angle-right" /></a>
                      </div>
                    </div>
                  </div>
                </div>
          </div>
        </div> */}

          <div className="list-section pt-80 pb-80" id="contacto">
            <div className="container">
              <div className="row text-center">
                <div className="col-lg-12">
                  <div className="section-title mb-0">
                    <h3 className="text-white">
                      Comunicate con{" "}
                      <span className="orange-text">Nosotros</span>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Contact></Contact>
        </>
      );
    }
    // else {
    //   return (
    //     <>
    //       <section
    //         className="casas-hero"
    //         style={{
    //           backgroundImage: `linear-gradient(rgba(19, 50, 34, 0.7),rgba(19, 50, 34, 0.7)) , url(https://terraloteos.com/img/Fondo%20Textura3.jpg)`,
    //         }}
    //       >
    //         <div className="container text-center">
    //           <i
    //             className="fas fa-exclamation-triangle pb-3"
    //             style={{ color: `#ef8937`, fontSize: `100px` }}
    //           ></i>
    //           <h3>
    //             La página que ingresaste
    //             <br />
    //             <span className="orange-text">no existe</span>
    //           </h3>
    //           <a href="/" className="boxed-btn">
    //             Ir a inicio
    //           </a>
    //         </div>
    //       </section>
    //     </>
    //   );
    // }
  };

  return (
    <body>
      {setNavbar()}
      <div className="search-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <span className="close-btn">
                <i className="fas fa-times" />
              </span>
              <div className="search-bar">
                <div className="search-bar-tablecell">
                  <a href="/">
                    <h3>Home</h3>
                  </a>
                  <a href="/#nosotros" className="close-btn">
                    <h3>Nosotros</h3>
                  </a>
                  <a href="/fundacion">
                    <h3>Fundación</h3>
                  </a>
                  <a href="/oficinas">
                    <h3>Oficinas</h3>
                  </a>
                  <a href="/proyectos">
                    <h3>Proyectos</h3>
                  </a>
                  <a href="/casas">
                    <h3>Casas</h3>
                  </a>
                  <a href="/blog">
                    <h3>Blog</h3>
                  </a>
                  <a href="/clientes">
                      <h3>Clientes</h3>
                    </a>
                  <button type="submit" className="close-btn" id="close-btn">
                    Volver <i className="fas fa-arrow-up" />
                  </button>
                  <a href="/login">
                    <h5>Ingresar</h5>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showData()}
    </body>
  );
}

export default BlogSingle;
