import React from "react";
import Proyecto from "../../layouts/Proyecto";
import Navbar from "../../components/Navbar";
import NavbarResponsive from "../../components/NavbarResponsive";

function Miralba() {
  var data = {
    image: "miralba-hero",
    nombre: "Miralba",
    descripcion:
      "Nueva urbanización destinada a mejorar la calidad de vida de sus habitantes. La eficiencia a la hora de prestar servicios, la sostenibilidad económica, social y ambiental la caracterizan.",
    items: {
      item1: ["fas fa-map-pin", "La Punta, San Luis", "Avda. La Cañada"],
      item2: [
        "fas fa-expand-arrows-alt",
        "102 Hectareas",
        "Divididas en 572 lotes",
      ],
      item3: ["fas fa-tree", "Potrero de Los Funes", "A solo 10 minutos"],
      item4: ["fas fa-wifi", "Servicios", "Todos los servicios y comodidades"],
    },
    servicios: [
      "Agua Potable",
      "Luz",
      "Alumbrado de Calles",
      "Calles terminadas",
    ],
    galeria: [
      "https://terraloteos.com/img/proyectos/Miralba/miralba-g1.jpg",
      "https://terraloteos.com/img/proyectos/Miralba/miralba-g2.jpg",
      "https://terraloteos.com/img/proyectos/Miralba/miralba-g3.jpg",
      "https://terraloteos.com/img/proyectos/Miralba/miralba-g4.jpg",
      "https://terraloteos.com/img/proyectos/Miralba/miralba-g5.jpg",
    ],
    plano: "https://terraloteos.com/img/proyectos/Miralba/miralba-plano.jpg",
    mapa: "!1m14!1m8!1m3!1d13355.366699734926!2d-66.3163640302246!3d-33.19202409999999!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x17c6506d806ef37!2sMIRALBA%2C!5e0!3m2!1ses-419!2sar!4v1646691384812!5m2!1ses-419!2sar",
    puntos: {
      punto1: [
        "fas fa-pin",
        "Ubicación Privilegiada",
        "Ubicado a solo 10 minutos de Potrero de Los Funes y en frente del estadio Juan Gilberto Funes, el loteo Miralba se encuentra en la rotonda de acceso a la ciudad de La Punta.",
        "../img/proyectos/Miralba/miralba-item1.jpg",
      ],
      punto2: [
        "fas fa-arrows-expand",
        "Mejora de la calidad de vida",
        "Miralba es una nueva urbanización cuyo objetivo es el de mejorar la calidad de vida de sus habitantes, esto a través de una interacción social amigable con el objetivo de brindar una sustentabilidad económica, social y ambiental.",
        "https://terraloteos.com/img/proyectos/Miralba/miralba-item2.jpg",
      ],
      punto3: [
        "fas fa-arrow-left",
        "Todos los servicios y comodidades",
        "Esta urbanización cuenta con todos los servicios y comodidades, desde los servicios básicos como agua, luz y gas, hasta servicios como Fibra Óptica, seguridad y recolección de residuos.",
        "https://terraloteos.com/img/proyectos/Miralba/miralba-item3.jpg",
      ],
    },
  };

  const setNavbar = () => {
    if (window.screen.width < 800) {
      return <NavbarResponsive />;
    } else {
      return <Navbar active={3} />;
    }
  };

  return (
    <>
      {setNavbar()}
      <Proyecto data={data}></Proyecto>
    </>
  );
}

export default Miralba;
