import React from "react";
import Proyecto from "../../layouts/Proyecto";
import Navbar from "../../components/Navbar";
import NavbarResponsive from "../../components/NavbarResponsive";

function EstacionDelValle() {
  var data = {
    image: "estacion-del-valle-hero",
    nombre: "Estación del Valle",
    descripcion:
      "Megaemprendimiento dividido en 5 etapas, ubicación privilegiada a solo 8 minutos de Villa de Merlo, todos los servicios, grandes espacios verdes y excelente vista a las sierras.",
    items: {
      item1: ["fas fa-map-pin", "Santa Rosa, San Luis", "Ruta N°5 Km 8"],
      item2: [
        "fas fa-expand-arrows-alt",
        "164 Hectareas",
        "Divididas en 2443 lotes",
      ],
      item3: ["fas fa-city", "A 5 min del centro", "Villa de Merlo"],
      item4: ["fas fa-tint", "Servicios", "Luz y Agua Potable"],
    },
    servicios: [
      "Agua Potable",
      "Luz",
      "Alumbrado de Calles",
      "Calles terminadas",
    ],
    galeria: [
      "https://terraloteos.com/img/proyectos/Estacion%20del%20Valle/estacion-del-valle-g1.jpg",
      "https://terraloteos.com/img/proyectos/Estacion%20del%20Valle/estacion-del-valle-g2.jpg",
      "https://terraloteos.com/img/proyectos/Estacion%20del%20Valle/estacion-del-valle-g3.jpg",
      "https://terraloteos.com/img/proyectos/Estacion%20del%20Valle/estacion-del-valle-g4.jpg",
      "https://terraloteos.com/img/proyectos/Estacion%20del%20Valle/estacion-del-valle-g5.jpg",
    ],
    plano:
      "https://terraloteos.com/img/proyectos/Estacion%20del%20Valle/estacion-del-valle-plano.jpg",
    mapa: "!1m18!1m12!1m3!1d3370.871273475761!2d-65.13689748447135!3d-32.342165181105386!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95d31fa35e99f40d%3A0xa9e398fae5791332!2sEstaci%C3%B3n%20del%20Valle!5e0!3m2!1ses!2sar!4v1646686163748!5m2!1ses!2sar",
    puntos: {
      punto1: [
        "fas fa-pin",
        "Ubicación Privilegiada",
        "A solo 8 minutos del ingreso a la Villa de Merlo, Estación del Valle cuenta con un paisaje increíble rodeado de elementos naturales pero con la comodidad de la vida urbana.",
        "../img/proyectos/Estacion%20del%20Valle/estacion-del-valle-item1.jpg",
      ],
      punto2: [
        "fas fa-arrows-expand",
        "Conexión natural",
        "Cuenta con amplios espacios verdes de vegetación autóctona y vistas privilegiadas a las Sierras de los Comechingones.",
        "https://terraloteos.com/img/proyectos/Estacion%20del%20Valle/estacion-del-valle-item2.jpg",
      ],
      punto3: [
        "fas fa-arrow-left",
        "Todos los servicios y comodidades",
        "El loteo cuenta con todos los servicios necesarios para llevar una vida tranquila y con todas las comodidades rodeado de la naturaleza. Cuenta con agua potable, luz, alumbrado de calles y calles terminadas.",
        "https://terraloteos.com/img/proyectos/Estacion%20del%20Valle/estacion-del-valle-item3.jpg",
      ],
    },
  };

  const setNavbar = () => {
    if (window.screen.width < 800) {
      return <NavbarResponsive />;
    } else {
      return <Navbar active={3} />;
    }
  };

  return (
    <>
      {setNavbar()}
      <Proyecto data={data}></Proyecto>
    </>
  );
}

export default EstacionDelValle;
