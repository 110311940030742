import React, { useEffect, useState } from "react";
import "./App.css";
import Footer from "./components/Footer";
import ModalCookies from "./components/Modals/ModalCookies";
import Asesores from "./pages/Asesores";
import Blog from "./pages/Blog";
import Casas from "./pages/Casas";
import Error404 from "./pages/Error404";
import Home from "./pages/Home";
import Login from "./pages/Login";
import MensajeError from "./pages/MensajeError";
import MensajeExito from "./pages/MensajeExito";
import NewBlog from "./pages/NewBlog";
import Oficinas from "./pages/Oficinas";
import Proyectos from "./pages/Proyectos";
import TutorialSignatura from "./pages/TutorialSignatura";
// import ModalPopUpAd from "./components/Modals/PopUpAd";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Clientes from "./pages/Clientes";
import Fundacion from "./pages/Fundacion";
import PoliticaCookies from "./pages/PoliticaCookies";
import User from "./pages/User";
import Chat from "./components/Chat";

//Nuevas Casas
import Loft from "./pages/casas/Loft";
import LoftA1 from "./pages/casas/LoftA1";
import LoftA2 from "./pages/casas/LoftA2";
import TipologiaA from "./pages/casas/TipologiaA";
import TipologiaB from "./pages/casas/TipologiaB";
import TipologiaB3 from "./pages/casas/TipologiaB3";
import TipologiaC from "./pages/casas/TipologiaC";
import TipologiaD from "./pages/casas/TipologiaD";
import TipologiaD1 from "./pages/casas/TipologiaD1";
import TipologiaE from "./pages/casas/TipologiaE";
import TipologiaF from "./pages/casas/TipologiaF";
import TipologiaH from "./pages/casas/TipologiaH";

//Proyectos
import AyresDeLarca from "./pages/proyectos/AyresDeLarca";
import BalconesDeSanMiguelll from "./pages/proyectos/BalconesDeSanMiguelII";
import EstacionConlara from "./pages/proyectos/EstacionConlara";
import EstacionDelValle from "./pages/proyectos/EstacionDelValle";
import LasChacras from "./pages/proyectos/LasChacras";
import LosAlamos from "./pages/proyectos/LosAlamos";
import MerloVillage from "./pages/proyectos/MerloVillage";
import Miralba from "./pages/proyectos/Miralba";
import PalmaresDeBalcarce from "./pages/proyectos/PalmaresDeBalcarce";
import PuebloMio from "./pages/proyectos/PuebloMio";
import TerrazasDelTalar from "./pages/proyectos/TerrazasDelTalar";
import ValleDeLosComechingones from "./pages/proyectos/ValleDeLosComechingones";

import { ApolloProvider } from '@apollo/client';
import client from "./config/config";

import BlogSingle from "./layouts/BlogSingle";

function App() {
  const [modalCookies, setModalCookies] = useState(false);

  const toggleModalCookies = () => {
    setModalCookies(!modalCookies);
  };

  // const togglepopUpAd = () => {
  //   setpopUpAd(!popUpAd);
  // };

  const compruebaModales = () => {
    if (localStorage.aceptaCookies !== "true") {
      toggleModalCookies();
    }
    // if (sessionStorage.vioPopUp !== "true") {
    //   togglepopUpAd();
    // }
  };

  const aceptarCookies = () => {
    localStorage.aceptaCookies = "true";
    toggleModalCookies();
  };

  // const vioModal = () => {
  //   sessionStorage.vioPopUp = "true";
  //   togglepopUpAd();
  // };

  const redirectProyectos = () => {
    sessionStorage.vioPopUp = "true";
    window.location.replace("/proyectos");
  };

  useEffect(() => {
    compruebaModales();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ApolloProvider client={client}>
    <div className="app">
      <div className="loader">
        <div className="loader-inner">
          <div className="circle"></div>
        </div>
      </div>
      {/* {popUpAd && (
        <ModalPopUpAd
          modal={popUpAd}
          toggle={togglepopUpAd}
          vioModalFunc={vioModal}
          redirectPopUp={redirectProyectos}
        />
      )} */}
      {modalCookies && (
        <ModalCookies
          modal={modalCookies}
          toggle={toggleModalCookies}
          cookiesFunction={aceptarCookies}
        />
      )}
      <Chat />
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/asesores" element={<Asesores />} />
          <Route path="/oficinas" element={<Oficinas />} />
          <Route path="/clientes" element={<Clientes />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:blogUrl" element={<BlogSingle />} />
          <Route path="/newBlog" element={<NewBlog />} />
          <Route path="/login" element={<Login />} />
          <Route path="/tutorialsignatura" element={<TutorialSignatura />} />
          <Route
            path="/tutorialsignatura.html#video"
            element={<TutorialSignatura />}
          />
          <Route path="/cookies" element={<PoliticaCookies />} />
          <Route path="/fundacion" element={<Fundacion />} />

          <Route path="/proyectos" element={<Proyectos />} />
          <Route path="/proyectos/ayres-de-larca" element={<AyresDeLarca />} />
          <Route
            path="/proyectos/balcones-de-san-miguel"
            element={<BalconesDeSanMiguelll />}
          />
          <Route
            path="/proyectos/estacion-conlara"
            element={<EstacionConlara />}
          />
          <Route
            path="/proyectos/estacion-del-valle"
            element={<EstacionDelValle />}
          />
          <Route path="/proyectos/merlo-village" element={<MerloVillage />} />
          <Route path="/proyectos/miralba" element={<Miralba />} />
          <Route path="/proyectos/pueblo-mio" element={<PuebloMio />} />
          <Route
            path="/proyectos/terrazas-del-talar"
            element={<TerrazasDelTalar />}
          />
          <Route
            path="/proyectos/valle-de-los-comechingones"
            element={<ValleDeLosComechingones />}
          />
          <Route path="/proyectos/los-alamos" element={<LosAlamos />} />
          <Route path="/proyectos/las-chacras" element={<LasChacras />} />
          <Route
            path="/proyectos/palmares-de-balcarce"
            element={<PalmaresDeBalcarce />}
          />

          <Route path="/casas" element={<Casas />} />
          <Route path="/casas/loft" element={<Loft />} />
          <Route path="/casas/loft-A1" element={<LoftA1 />} />
          <Route path="/casas/loft-A2" element={<LoftA2 />} />
          <Route path="/casas/tipologia-A" element={<TipologiaA />} />
          <Route path="/casas/tipologia-B" element={<TipologiaB />} />
          <Route path="/casas/tipologia-B3" element={<TipologiaB3 />} />
          <Route path="/casas/tipologia-C" element={<TipologiaC />} />
          <Route path="/casas/tipologia-D" element={<TipologiaD />} />
          <Route path="/casas/tipologia-D1" element={<TipologiaD1 />} />
          <Route path="/casas/tipologia-E" element={<TipologiaE />} />
          <Route path="/casas/tipologia-F" element={<TipologiaF />} />
          <Route path="/casas/tipologia-H" element={<TipologiaH />} />

          <Route path="/mensaje-exito" element={<MensajeExito />} />
          <Route path="/mensaje-error" element={<MensajeError />} />
          <Route path="/user" element={<User />} />

          <Route path="*" element={<Error404 />} />
        </Routes>
        <Footer></Footer>
      </BrowserRouter>
    </div>
    </ApolloProvider>
  );
}

export default App;
