import React from "react";

import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";

function ModalNavegacion(modal, toggle) {

    return (
        <div>
            <Modal isOpen={modal} toggle={toggle} contentClassName="card" size="xl" centered> 
                <ModalHeader toggle={toggle}>Text</ModalHeader>
                <ModalBody>
                    <div className="row justify-content-center">
                    </div>
                    <div className="text-center">
                    </div>
                </ModalBody>
                <ModalFooter className="justify-content-center">
                    <Button className="btn-round" color="primary">Confirmar</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default ModalNavegacion;
