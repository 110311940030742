import React, { useState } from "react";
import Navbar from "../components/Navbar";
import NavbarResponsive from "../components/NavbarResponsive";
import {
  getAuth,
  signInWithEmailAndPassword,
  onAuthStateChanged,
} from "firebase/auth";

import { Form, Input } from "reactstrap";

function Error404() {
  const [userData, setUserData] = useState({
    userName: "",
    userPass: "",
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    login(userData.userName, userData.userPass);
  };

  const auth = getAuth();

  onAuthStateChanged(auth, (user) => {
    if (user) {
      window.location.href = "/user";
    }
  });

  const login = (email, password) => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // const user = userCredential.user;
        window.location.href = "/newBlog";
      })
      .catch((error) => {
        // const errorCode = error.code;
        const errorMessage = error.message;
        alert(errorMessage);
      });
  };

  const handleChange = (event) => {
    setUserData({
      ...userData,
      [event.target.name]: event.target.value,
    });
  };

  const setNavbar = () => {
    if (window.screen.width < 800) {
      return <NavbarResponsive />;
    } else {
      return <Navbar active={2} />;
    }
  };

  return (
    <div>
      {setNavbar()}
      <body>
        <div className="search-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <span className="close-btn">
                  <i className="fas fa-times" />
                </span>
                <div className="search-bar">
                  <div className="search-bar-tablecell">
                    <a href="/">
                      <h3>Home</h3>
                    </a>
                    <a href="/#nosotros" className="close-btn">
                      <h3>Nosotros</h3>
                    </a>
                    <a href="/fundacion">
                      <h3>Fundación</h3>
                    </a>
                    <a href="/oficinas">
                      <h3>Oficinas</h3>
                    </a>
                    <a href="/proyectos">
                      <h3>Proyectos</h3>
                    </a>
                    <a href="/casas">
                      <h3>Casas</h3>
                    </a>
                    <a href="/blog">
                      <h3>Blog</h3>
                    </a>
                    <a href="/clientes">
                      <h3>Clientes</h3>
                    </a>
                    <button type="submit" className="close-btn" id="close-btn">
                      Volver <i className="fas fa-arrow-up" />
                    </button>
                    <a href="/login">
                      <h5>Ingresar</h5>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="list-section pt-100"></div>

        <section className="casas-hero fondo-textura3">
          <div className="container login-container">
            <div className="row justify-content-center">
              <div className="col-lg-6 mb-5 mb-lg-0 caja-contacto">
                <div className="form-title text-center">
                  <h2>Iniciar Sesión</h2>
                </div>
                <div id="form_status" />
                <div className="login-form">
                  <Form onSubmit={handleSubmit} id="login-form">
                    <p>
                      <Input
                        defaultValue=""
                        placeholder="Usuario"
                        type="text"
                        name="userName"
                        className="login-form-input"
                        onChange={handleChange}
                      />
                    </p>
                    <p>
                      <Input
                        defaultValue=""
                        placeholder="Contraseña"
                        type="password"
                        name="userPass"
                        className="login-form-input"
                        onChange={handleChange}
                      />
                    </p>
                    <p className="text-center">
                      <input
                        type="submit"
                        defaultValue="Entrar"
                        value="Entrar"
                      />
                    </p>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </body>
    </div>
  );
}

export default Error404;
