import React from "react";
import Proyecto from "../../layouts/Proyecto";
import Navbar from "../../components/Navbar";
import NavbarResponsive from "../../components/NavbarResponsive";

function EstacionConlara() {
  var data = {
    image: "estacion-conlara-hero",
    nombre: "Estación Conlara",
    descripcion:
      "Mega emprendimiento ideal para que comiences a vivir tu nueva vida cerca de Merlo, con amplios espacios naturales, una laguna propia y vistas privilegiadas de las sierras.",
    items: {
      item1: ["fas fa-map-pin", "Santa Rosa, San Luis", "Ruta N°5 Km 25"],
      item2: [
        "fas fa-expand-arrows-alt",
        "93 Hectareas",
        "Divididas en 992 lotes",
      ],
      item3: ["fas fa-water", "Laguna Propia", "Laguna dentro del loteo"],
      item4: ["fas fa-tint", "Servicios", "Luz y Agua Potable"],
    },
    servicios: [
      "Agua Potable",
      "Luz",
      "Alumbrado de Calles",
      "Calles terminadas",
    ],
    galeria: [
      "https://terraloteos.com/img/proyectos/Estacion%20Conlara/estacion-conlara-g1.jpg",
      "https://terraloteos.com/img/proyectos/Estacion%20Conlara/estacion-conlara-g2.jpg",
      "https://terraloteos.com/img/proyectos/Estacion%20Conlara/estacion-conlara-g3.jpg",
      "https://terraloteos.com/img/proyectos/Estacion%20Conlara/estacion-conlara-g4.jpg",
      "https://terraloteos.com/img/proyectos/Estacion%20Conlara/estacion-conlara-g5.jpg",
    ],
    plano:
      "https://terraloteos.com/img/proyectos/Estacion%20Conlara/estacion-conlara-plano.jpg",
    mapa: "!1m14!1m8!1m3!1d13483.887380675014!2d-65.1618738!3d-32.3394654!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe7a504506d3ffdc6!2sEstacion%20Conlara!5e0!3m2!1ses!2sar!4v1646408899172!5m2!1ses!2sar",
    puntos: {
      punto1: [
        "fas fa-pin",
        "Ubicación Privilegiada",
        "Este megaemprendimiento está ubicado a solo 1000 metros de la intersección entre la Ruta N°5 y la autopista N°148, a solo 5 minutos de la Villa de Merlo.",
        "../img/proyectos/Estacion%20Conlara/estacion-conlara-item1.jpg",
      ],
      punto2: [
        "fas fa-arrows-expand",
        "Conexión natural",
        "El barrio cuenta con una laguna natural propia con muelle, que se complementa con el paisaje natural de la zona con amplias vistas a la Sierra de Los Comechingones.",
        "https://terraloteos.com/img/proyectos/Estacion%20Conlara/estacion-conlara-item2.jpg",
      ],
      punto3: [
        "fas fa-arrow-left",
        "Todos los servicios y comodidades",
        "El proyecto se divide en tres sectores: residencial, comercial y gastronómico‑turístico; con las obras culminadas de apertura de calles, red de alumbrado público, red de agua corriente y red eléctrica subterránea, portal de ingreso, cordón serrano.",
        "https://terraloteos.com/img/proyectos/Estacion%20Conlara/estacion-conlara-item3.jpg",
      ],
    },
  };

  const setNavbar = () => {
    if (window.screen.width < 800) {
      return <NavbarResponsive />;
    } else {
      return <Navbar active={3} />;
    }
  };

  return (
    <>
      {setNavbar()}
      <Proyecto data={data}></Proyecto>
    </>
  );
}

export default EstacionConlara;
