export const asesores = [
    {
      nombre: "Pablo Zielke",
      sucursal: "Villa Mercedes, San Luis",
      whatsapp: "5492657334395",
      puesto: "Asesor Comercial",
      imagen: "https://terraloteos.com/img/asesores/pablozielke.png",
      correo: "pablozielke@terraloteos.com",
    },
    {
      nombre: "Roque Lucero",
      sucursal: "Villa Mercedes, San Luis",
      whatsapp: "5492664328116",
      puesto: "Asesor Comercial",
      imagen: "https://terraloteos.com/img/asesores/roquelucero.png",
      correo: "roquelucero@terraloteos.com",
    },
    {
      nombre: "Eugenia Sosa",
      sucursal: "San Luis Capital, San Luis",
      whatsapp: "5492664771323",
      puesto: "Asesor Comercial",
      imagen: "https://terraloteos.com/img/asesores/eugeniasosa.png",
      correo: "eugeniasosa@terraloteos.com",
    },
    {
      nombre: "Oscar Miranda",
      sucursal: "San Luis Capital, San Luis",
      whatsapp: "5492664576206",
      puesto: "Asesor Comercial",
      imagen: "https://terraloteos.com/img/asesores/oscarmiranda.png",
      correo: "oscarmiranda@terraloteos.com",
    },
    {
      nombre: "Sergio Martinez",
      sucursal: "San Luis Capital, San Luis",
      whatsapp: "5492657507099",
      puesto: "Jefe de Oficina",
      imagen: "https://terraloteos.com/img/asesores/sergiomartinez.png",
      correo: "sergiomartinez@terraloteos.com",
    },
    {
      nombre: "Gerardo Nahas",
      sucursal: "Río Cuarto, Córdoba",
      whatsapp: "5493584379720",
      puesto: "Jefe de Oficina",
      imagen: "https://terraloteos.com/img/asesores/gerardonahas.png",
      correo: "gerardonahas@terraloteos.com",
    },
    {
      nombre: "Gonzalo Alba",
      sucursal: "Río cuarto, Córdoba",
      whatsapp: "5493585147095",
      puesto: "Asesor Comercial",
      imagen: "https://terraloteos.com/img/asesores/gonzaloalba.png",
      correo: "gonzaloalba@terraloteos.com",
    },
    {
      nombre: "Matias Zarate",
      sucursal: "Río Cuarto, Córdoba.",
      whatsapp: "5493584902452",
      puesto: "Asesor Comercial",
      imagen: "https://terraloteos.com/img/asesores/matiaszarate.png",
      correo: "matiaszarate@terraloteos.com",
    },
    {
      nombre: "Laura Alaialdin",
      sucursal: "Río Cuarto, Córdoba.",
      whatsapp: "5493585602150",
      puesto: "Asesor Comercial",
      imagen: "https://terraloteos.com/img/asesores/lauraalaialdin.png",
      correo: "lauraalaialdin@terraloteos.com",
    },
    {
      nombre: "Aldo Zavala",
      sucursal: "Villa de Merlo",
      whatsapp: "5492664013254",
      puesto: "Asesor Comercial",
      imagen: "https://terraloteos.com/img/asesores/aldozavala.png",
      correo: "aldozavala@terraloteos.com",
    },
  ];