import React from "react";
import Banner from "../components/Banner";
import BannerResponsive from "../components/BannerResponsive";
import Contact from "../components/Contact";

function Casa(props) {
  const setBanner = () => {
    if (window.screen.width < 800) {
      return <BannerResponsive items={props.data.items} />;
    } else {
      return <Banner items={props.data.items} />;
    }
  };

  return (
    <body>
      <div className="search-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <span className="close-btn">
                <i className="fas fa-times" />
              </span>
              <div className="search-bar">
                <div className="search-bar-tablecell">
                  <a href="/">
                    <h3>Home</h3>
                  </a>
                  <a href="/asesores" className="close-btn">
                    <h3>Asesores</h3>
                  </a>
                  <a href="/fundacion">
                    <h3>Fundación</h3>
                  </a>
                  <a href="/oficinas">
                    <h3>Oficinas</h3>
                  </a>
                  <a href="/proyectos">
                    <h3>Proyectos</h3>
                  </a>
                  <a href="/casas">
                    <h3>Casas</h3>
                  </a>
                  <a href="/blog">
                    <h3>Blog</h3>
                  </a>
                  <a href="/clientes">
                    <h3>Clientes</h3>
                  </a>
                  <button type="submit" className="close-btn" id="close-btn">
                    Volver <i className="fas fa-arrow-up" />
                  </button>
                  <a href="/login">
                    <h5>Ingresar</h5>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section
        className="casa-item-hero"
        style={{
          backgroundImage: `linear-gradient(rgba(19, 50, 34, 0.7),rgba(19, 50, 34, 0.7)), url('/img/${props.data.galeria[0]}')`,
        }}
      >
        <div className="container text-center">
          <h3>
            Casa
            <br />
            <span className="orange-text">{props.data.titulo}</span>
          </h3>
          <a href="#oficinas" className="btn-bajar btn-lg">
            <i className="fas fa-arrow-down mr-0" />
          </a>
        </div>
      </section>

      {setBanner()}

      <div className="latest-news pt-150 pb-150 fondo-textura4" id="casas">
        <div className="container">
          <div className="col-lg-8 offset-lg-2 text-center">
            <div className="section-title">
              <h3>
                Tu casa<span className="orange-text"> ideal</span>
              </h3>
              <p>{props.data.descripcion}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8">
              <div className="single-article-section">
                <div className="single-article-text">
                  <div
                    className="single-artcile-bg"
                    style={{
                      backgroundImage: `url('/img/${props.data.galeria[1]}')`,
                    }}
                  ></div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sidebar-section">
                <div className="recent-posts">
                  <h4>Detalles</h4>
                  <p>{props.data.detalles}</p>
                </div>
                <div className="archive-posts">
                  <h4>Características</h4>
                  <ul>
                    {props.data.caracteristicas.map((caracteristica, index) => {
                      return (
                        <li>
                          <a style={{ cursor: "default" }}>{caracteristica}</a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-80">
            <div className="col-lg-8 offset-lg-2 text-center">
              <div className="section-title">
                <h3>
                  Conocé <span className="orange-text">más</span>
                </h3>
                <p>
                  Echale un vistazo a los ambientes que componen esta casa,
                  diseñados para satisfacer todas tus necesidades.
                </p>
              </div>
            </div>
          </div>
          <div className="row mb-80">
            <div className="col-lg-4 col-md-6">
              <div className="single-team-item">
                <div
                  className="team-bg"
                  style={{
                    backgroundImage: `url('/img/${
                      props.data.galeria.length > 2
                        ? props.data.galeria[2]
                        : props.data.galeria[1]
                    }')`,
                  }}
                ></div>
                <h4>
                  {props.data.puntos.punto1[0]}
                  <span>{props.data.puntos.punto1[1]}</span>
                </h4>
                <ul className="social-link-team">
                  <li>
                    <a
                      href={`/img/${
                        props.data.galeria.length > 2
                          ? props.data.galeria[2]
                          : props.data.galeria[1]
                      }`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fas fa-expand-arrows-alt"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-team-item">
                <div
                  className="team-bg"
                  style={{
                    backgroundImage: `url('/img/${
                      props.data.galeria.length > 3
                        ? props.data.galeria[3]
                        : props.data.galeria[2]
                    }')`,
                  }}
                ></div>
                <h4>
                  {props.data.puntos.punto2[0]}
                  <span>{props.data.puntos.punto2[1]}</span>
                </h4>
                <ul className="social-link-team">
                  <li>
                    <a
                     href={`/img/${
                      props.data.galeria.length > 3
                        ? props.data.galeria[3]
                        : props.data.galeria[2]
                    }`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fas fa-expand-arrows-alt"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
              <div className="single-team-item">
                {console.log(props.data.galeria)}
                <div
                  className="team-bg"
                  style={{
                    backgroundImage: `url('/img/${
                      props.data.galeria.length > 4
                        ? props.data.galeria[4]
                        : props.data.galeria[0]
                    }')`,
                  }}
                ></div>
                <h4>
                  {props.data.puntos.punto3[0]}
                  <span>{props.data.puntos.punto3[1]}</span>
                </h4>
                <ul className="social-link-team">
                  <li>
                    <a
                     href={`/img/${
                      props.data.galeria.length > 4
                        ? props.data.galeria[4]
                        : props.data.galeria[0]
                    }`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fas fa-expand-arrows-alt"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="list-section pt-80 pb-80" id="galeria">
        <div className="container">
          <div className="row text-center">
            <div className="col-lg-12">
              <div className="section-title mb-0">
                <h3 className="text-white">
                  Galería de <span className="orange-text">Fotos</span>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="gallery-slider">
        {props.data.galeria.map((img, index) => {
          return (
            <div
              className="proyecto-slider"
              style={{
                backgroundImage: `url('/img/${img}')`,
              }}
            >
              <div className="container"></div>
            </div>
          );
        })}
      </div>

      <div className="list-section pt-80 pb-80" id="contacto">
        <div className="container">
          <div className="row text-center">
            <div className="col-lg-12">
              <div className="section-title mb-0">
                <h3 className="text-white">
                  Comunicate con <span className="orange-text">Nosotros</span>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Contact></Contact>
    </body>
  );
}

export default Casa;
