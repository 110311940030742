import { ApolloClient, InMemoryCache, createHttpLink, split } from '@apollo/client';
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';

// export const url = "https://terraloteos-app.com";
export const url = "http://localhost:5000";

const htprotocol = "http";
const wsprotocol = "ws";
const domain = "localhost:5000";

export const API_URL = `${htprotocol}://${domain}`;

const GRAPHQL_URL = `${API_URL}/graphql`;
const WS_URL = `${wsprotocol}://${domain}/subscriptions`;

// const wsLink = new WebSocketLink({
//   uri: WS_URL,
//   options: {
//     reconnect: true, // Automatically reconnect if the connection is lost
//   },
// });

const httpLink = createHttpLink({
  uri: GRAPHQL_URL,
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: WS_URL,
    on: {
      connected: () => console.log("Connected to GraphQL WS"),
      connecting: () => console.log("Connecting to GraphQL WS"),
      closed: () => console.log("Closed connection to GraphQL WS"),
      error: (error) => console.log("Error on GraphQL WS", error),
    },
  })
);

const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === "OperationDefinition" && definition.operation === "subscription";
  },
  wsLink,
  httpLink
);


const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache(),
});

export default client;

