import React from "react";
import SliderHome from "../components/SliderHome";
import Banner from "../components/Banner";
import BannerResponsive from "../components/BannerResponsive";
import Navbar from "../components/Navbar";
import NavbarResponsive from "../components/NavbarResponsive";
import { casas } from "../data/casas.js";
import { asesores } from "../data/asesores.js";

function Home() {
  let items = {
    item1: [
      "fas fa-building",
      "V. Mercedes",
      "Av. Mitre N° 1260 y Av. Mitre N° 734",
    ],
    item2: [
      "fas fa-city",
      "Bs. As.",
      "Av. Córdoba N° 679, Piso:2 Oficina:B (CABA)",
    ],
    item3: ["fas fa-city", "San Luis", "Gral. Paz 956"],
    item4: [
      "fas fa-mountain",
      "Merlo",
      "Av. del Sol N° 821 y Av. del Sol N° 351",
    ],
    item5: ["fas fa-building", "Rio Cuarto", "Alberdi 717"],
  };

  const shuffle = (array) => {
    let currentIndex = array.length,
      temporaryValue,
      randomIndex;

    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    array = array.slice(0, 3);
    return array;
  };

  const setBanner = () => {
    if (window.screen.width < 800) {
      return <BannerResponsive items={items} />;
    } else {
      return <Banner items={items} />;
    }
  };

  const setNavbar = () => {
    if (window.screen.width < 800) {
      return <NavbarResponsive />;
    } else {
      return <Navbar active={0} />;
    }
  };

  return (
    <div>
      {setNavbar()}
      <body>
        <div className="search-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <span className="close-btn">
                  <i className="fas fa-times" />
                </span>
                <div className="search-bar">
                  <div className="search-bar-tablecell">
                    <a href="/">
                      <h3>Home</h3>
                    </a>
                    <a href="/#nosotros" className="close-btn">
                      <h3>Nosotros</h3>
                    </a>
                    <a href="/fundacion">
                      <h3>Fundación</h3>
                    </a>
                    <a href="/oficinas">
                      <h3>Oficinas</h3>
                    </a>
                    <a href="/proyectos">
                      <h3>Proyectos</h3>
                    </a>
                    <a href="/casas">
                      <h3>Casas</h3>
                    </a>
                    <a href="/blog">
                      <h3>Blog</h3>
                    </a>
                    <a href="/clientes">
                      <h3>Clientes</h3>
                    </a>
                    <button type="submit" className="close-btn" id="close-btn">
                      Volver <i className="fas fa-arrow-up" />
                    </button>
                    <a href="/login">
                      <h5>Ingresar</h5>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <SliderHome></SliderHome>

        <div className="list-section pt-80 pb-80" id="nosotros">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6 mb-4 mb-lg-0">
                <div className="list-box d-flex align-items-center">
                  <div className="list-icon">
                    <i className="fas fa-home" />
                  </div>
                  <div className="content">
                    <h3>Asesoramiento</h3>
                    <p>
                      Te ayudamos a encontrar <br />
                      tu casa ideal!
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-4 mb-lg-0">
                <div className="list-box d-flex align-items-center">
                  <div className="list-icon">
                    <i className="fas fa-handshake" />
                  </div>
                  <div className="content">
                    <h3>Compromiso</h3>
                    <p>Con nuestros clientes y con el medio ambiente</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="list-box d-flex justify-content-start align-items-center">
                  <div className="list-icon">
                    <i className="fas fa-shield-alt" />
                  </div>
                  <div className="content">
                    <h3>Confianza</h3>
                    <p>
                      Respaldados por una grán cantidad de clientes satisfechos
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="latest-news pt-150 pb-150 fondo-textura"
          id="desarrollos"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2 text-center">
                <div className="section-title">
                  <h3>
                    Nuestros<span className="orange-text"> Desarrollos</span>
                  </h3>
                  <p>
                    Comenzá a soñar con tu nuevo hogar, y construí tus sueños
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="single-latest-news">
                  <a href="/proyectos/estacion-conlara">
                    <div className="latest-news-bg estacion-conlara-item" />
                  </a>
                  <div className="news-text-box">
                    <h3>
                      <a href="/proyectos/estacion-conlara">Estación Conlara</a>
                    </h3>
                    <p className="blog-meta">
                      <span className="author">
                        <i className="fas fa-map-marker-alt" /> Santa Rosa del
                        Conlara, S.L.
                      </span>
                      <span className="date">
                        <i className="fas fa-sign" /> 992 Lotes
                      </span>
                    </p>
                    <p className="excerpt">
                      Mega emprendimiento ideal para que comiences a vivir tu
                      nueva vida cerca de Merlo, con amplios espacios naturales,
                      una laguna propia y vistas privilegiadas de las sierras.
                    </p>
                    <a
                      href="/proyectos/estacion-conlara"
                      className="read-more-btn"
                    >
                      Ver más <i className="fas fa-angle-right" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="single-latest-news">
                  <a href="/proyectos/ayres-de-larca">
                    <div className="latest-news-bg ayres-de-larca-item" />
                  </a>
                  <div className="news-text-box">
                    <h3>
                      <a href="/proyectos/ayres-de-larca">Ayres de Larca</a>
                    </h3>
                    <p className="blog-meta">
                      <span className="author">
                        <i className="fas fa-map-marker-alt" /> Cortaderas, San
                        Luis
                      </span>
                      <span className="date">
                        <i className="fas fa-sign" /> 515 Lotes
                      </span>
                    </p>
                    <p className="excerpt">
                      Emprendimiento que mezcla el concepto de sierra y valle,
                      caracterizado por tener una vista única y privilegiada a
                      las Sierras de los Comechingones acompañado del verde
                      característico del valle.
                    </p>
                    <a
                      href="/proyectos/ayres-de-larca"
                      className="read-more-btn"
                    >
                      Ver más <i className="fas fa-angle-right" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div className="single-latest-news">
                  <a href="/proyectos/valle-de-los-comechingones">
                    <div className="latest-news-bg valle-de-los-comechingones-item" />
                  </a>
                  <div className="news-text-box">
                    <h3>
                      <a href="/proyectos/valle-de-los-comechingones">
                        Valle de los Comechingones
                      </a>
                    </h3>
                    <p className="blog-meta">
                      <span className="author">
                        <i className="fas fa-map-marker-alt" /> Villa de Merlo,
                        San Luis
                      </span>
                      <span className="date">
                        <i className="fas fa-sign" /> 24 Lotes
                      </span>
                    </p>
                    <p className="excerpt">
                      Emprendimiento ubicado en las cercanías de Merlo, con una
                      ubicación ideal que permite disfrutar del paisaje de las
                      sierrras y el valle de los Comechingones con la ventaja de
                      la cercanía al centro de la ciudad.
                    </p>
                    <a
                      href="/proyectos/valle-de-los-comechingones"
                      className="read-more-btn"
                    >
                      Ver más <i className="fas fa-angle-right" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 text-center">
                <a href="/proyectos" className="boxed-btn">
                  Más proyectos
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="list-section pt-80 pb-80">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6 mb-4 mb-lg-0">
                <div className="list-box d-flex align-items-center">
                  <div className="list-icon">
                    <i className="fas fa-pencil-ruler" />
                  </div>
                  <div className="content">
                    <h2>750</h2>
                    <h3>Hectareas Desarrolladas</h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-4 mb-lg-0">
                <div className="list-box d-flex align-items-center">
                  <div className="list-icon">
                    <i className="fas fa-building" />
                  </div>
                  <div className="content">
                    <h2>5779</h2>
                    <h3>Lotes Urbanizados</h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="list-box d-flex justify-content-start align-items-center">
                  <div className="list-icon">
                    <i className="fas fa-user-check" />
                  </div>
                  <div className="content">
                    <h2>+3664</h2>
                    <h3>Clientes Actuales</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div class="testimonail-section mt-150 mb-150">
          <div class="container">
            <div class="row text-center">
              <div class="col-lg-12">
                <div class="testimonial-sliders">
                  <div class="single-testimonial-slider">
                    <div class="client-avater">
                      <img src="assets/img/avaters/avatar1.png" alt="" />
                    </div>
                    <div class="client-meta">
                      <h3>
                        Saira Hakim <span>Local shop owner</span>
                      </h3>
                      <p class="testimonial-body">
                        " Sed ut perspiciatis unde omnis iste natus error
                        veritatis et quasi architecto beatae vitae dict eaque
                        ipsa quae ab illo inventore Sed ut perspiciatis unde
                        omnis iste natus error sit voluptatem accusantium "
                      </p>
                      <div class="last-icon">
                        <i class="fas fa-quote-right"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <section className="home-banner">
          <div className="container">
            <h3>
              Cumplí el sueño de tener
              <br /> tu <span className="orange-text">casa ideal</span>
            </h3>
            <a href="#casas" className="cart-btn btn-lg mt-4">
              Ver más
            </a>
          </div>
        </section>

        <div className="latest-news pt-150 pb-150 fondo-textura2" id="casas">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2 text-center">
                <div className="section-title">
                  <h3>
                    Construcción<span className="orange-text"> de Casas</span>
                  </h3>
                  <p>Tu nuevo hogar y tu nueva vida te están esperando</p>
                </div>
              </div>
            </div>
            <div className="row">
              {casas.slice(0, 3).map((casa) => {
                return (
                  <div
                    className="col-lg-4 col-md-6"
                    style={{ marginBottom: "30px" }}
                  >
                    <div
                      className="single-latest-news"
                      style={{ height: "100%" }}
                    >
                      <a href={casa.link}>
                        <div
                          className="latest-news-bg"
                          style={{
                            backgroundImage: `url('/img/${casa.galeria[0]}')`,
                          }}
                        />
                      </a>
                      <div className="news-text-box text-center">
                        <h3>{casa.titulo}</h3>
                        <p className="blog-meta">
                          <span className="author">
                            <i className="fas fa-calendar" /> Disponible en{" "}
                            {casa.duracion}
                          </span>
                          <span className="date">
                            <i className="fas fa-bed" /> {casa.habitaciones}
                          </span>
                          <span className="date">
                            <i className="fas fa-arrows-alt" />{" "}
                            {casa.tamano_terreno}m2
                          </span>
                        </p>
                        <p className="product-price">
                          <span>{casa.resumen}</span>
                        </p>
                        <a href={casa.link} className="cart-btn">
                          <i className="fas fa-home" /> Ver más
                        </a>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="row">
              <div className="col-lg-12 text-center">
                <a href="/casas" className="boxed-btn">
                  Más modelos
                </a>
              </div>
            </div>
          </div>
        </div>

        <section className="contact-banner" id="contacto">
          <div className="container text-center">
            <h3>
              Contactate con nosotros
              <br />o visitá{" "}
              <span className="orange-text">nuestras oficinas</span>
            </h3>
            <a href="/oficinas" className="cart-btn btn-lg">
              Contacto
            </a>
          </div>
        </section>

        <div>
          <div
            className="latest-news pt-150 pb-150 fondo-textura"
            id="desarrollos"
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-8 offset-lg-2 text-center">
                  <div className="section-title">
                    <h3>
                      Nuestros<span className="orange-text"> Asesores</span>
                    </h3>
                    <p>
                      Asesores oficiales de Terraloteos que te ayudarán y
                      brindarán la mejor atención en la busqueda de tu inmueble
                      ideal!
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                {shuffle(asesores).map((asesor) => {
                  return (
                    <div className="col-lg-4 col-md-6" id="merlo">
                      <div className="single-latest-news">
                        <a
                          href={
                            "https://api.whatsapp.com/send/?phone=" +
                            asesor.whatsapp +
                            "&type=phone_number&app_absent=0"
                          }
                          target={"_blank"}
                        >
                          <div className="asesor-bg">
                            <img src={asesor.imagen} />
                          </div>
                        </a>
                        <div className="news-text-box text-center">
                          <h3 className="mb-0">{asesor.nombre}</h3>
                          <h4>{asesor.puesto}</h4>
                          <hr />
                          <p className="product-price">
                            <span>Sucursales</span>
                            {asesor.sucursal}
                          </p>
                          <a
                            href={
                              "https://api.whatsapp.com/send/?phone=" +
                              asesor.whatsapp +
                              "&type=phone_number&app_absent=0"
                            }
                            className="wp-btn mr-2"
                            target={"_blank"}
                            rel="noopener noreferrer"
                          >
                            <i className="fab fa-whatsapp" /> Whatsapp
                          </a>
                          <a
                            href={"mailto:" + asesor.correo}
                            target={"_blank"}
                            rel="noopener noreferrer"
                            className="cart-btn"
                          >
                            <i className="fas fa-envelope" /> Correo
                          </a>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="row">
                <div className="col-lg-12 text-center">
                  <a href="/asesores" className="boxed-btn">
                    Ver más asesores
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        {setBanner()}
      </body>
    </div>
  );
}

export default Home;
