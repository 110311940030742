import React from "react";
import Navbar from "../components/Navbar";
import NavbarResponsive from "../components/NavbarResponsive";
import YoutubeEmbed from "../components/YoutubeEmbed";

function TutorialSignatura() {
  const setNavbar = () => {
    if (window.screen.width < 800) {
      return <NavbarResponsive />;
    } else {
      return <Navbar active={null} />;
    }
  };

  return (
    <div>
      {setNavbar()}
      <body>
        <div className="search-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <span className="close-btn"><i className="fas fa-times" /></span>
                <div className="search-bar">
                  <div className="search-bar-tablecell">
                    <a href="/"><h3>Home</h3></a>
                    <a href="/asesores" className="close-btn"><h3>Asesores</h3></a>
                    <a href="/fundacion"><h3>Fundación</h3></a>
                    <a href="/oficinas"><h3>Oficinas</h3></a>
                    <a href="/proyectos"><h3>Proyectos</h3></a>
                    <a href="/casas"><h3>Casas</h3></a>
                    <a href="/blog"><h3>Blog</h3></a>
                    <a href="/clientes">
                      <h3>Clientes</h3>
                    </a>
                    <button type="submit" className="close-btn" id="close-btn">Volver <i className="fas fa-arrow-up" /></button>
                    <a href="/login"><h5>Ingresar</h5></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="latest-news pt-150 pb-150" id="blog" style={{
            backgroundImage: `linear-gradient(rgba(19, 50, 34, 0.7),rgba(19, 50, 34, 0.7)) , url(https://terraloteos.com/img/Fondo%20Textura3.jpg)`,
          }}>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10 text-center">
                <YoutubeEmbed embedId="B4eY1ULHxp8" />
              </div>
            </div>
          </div>
        </div>

      </body>
    </div>
  );
}

export default TutorialSignatura;
