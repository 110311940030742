import React from "react";
import Proyecto from "../../layouts/Proyecto";
import Navbar from "../../components/Navbar";
import NavbarResponsive from "../../components/NavbarResponsive";

function AyresDeLarca() {
  var data = {
    image: "ayres-de-larca-hero",
    nombre: "Ayres de Larca",
    descripcion:
      "Emprendimiento que mezcla el concepto de sierra y valle, caracterizado por tener una vista única y privilegiada a las Sierras de los Comechingones acompañado del verde característico del valle.",
    items: {
      item1: ["fas fa-map-pin", "Cortaderas, San Luis", "Ruta N°1 Km 27"],
      item2: [
        "fas fa-expand-arrows-alt",
        "53 Hectareas",
        "Divididas en 515 lotes",
      ],
      item3: ["fas fa-water", "Dique Piscu Yaco", "A solo 3500 metros"],
      item4: ["fas fa-tint", "Servicios", "Luz y Agua Potable"],
    },
    servicios: [
      "Agua Potable",
      "Luz",
      "Alumbrado de Calles",
      "Calles terminadas",
    ],
    galeria: [
      "https://terraloteos.com/img/proyectos/Ayres%20de%20Larca/ayres-de-larca-g1.jpg",
      "https://terraloteos.com/img/proyectos/Ayres%20de%20Larca/ayres-de-larca-g2.jpg",
      "https://terraloteos.com/img/proyectos/Ayres%20de%20Larca/ayres-de-larca-g3.jpg",
      "https://terraloteos.com/img/proyectos/Ayres%20de%20Larca/ayres-de-larca-g4.jpg",
      "https://terraloteos.com/img/proyectos/Ayres%20de%20Larca/ayres-de-larca-g5.jpg",
    ],
    plano:
      "https://terraloteos.com/img/proyectos/Ayres%20de%20Larca/ayres-de-larca-plano.jpg",
    mapa: "!1m14!1m8!1m3!1d13448.831126009376!2d-64.9854371!3d-32.5739823!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xc4eec4094111b7f1!2sAYRES%20DE%20LARCA%20LOTEO%20DE%20MONTA%C3%91A!5e0!3m2!1ses!2sar!4v1646406378833!5m2!1ses!2sar",
    puntos: {
      punto1: [
        "fas fa-pin",
        "Ubicación Privilegiada",
        "A solo 3.500 mts del loteo se encuentra el Dique Piscu Yaco, principal atractivo turístico de la zona, ideal para pasar los días calurosos o dar un paseo.",
        "../img/proyectos/Ayres%20de%20Larca/ayres-de-larca-item1.jpg",
      ],
      punto2: [
        "fas fa-arrows-expand",
        "Conexión natural",
        "Este barrio fue concebido con el objetivo de llevar una vida rodeado de la naturaleza y la tranquilidad de las sierras, podrás disfrutar de paisajes increíbles.",
        "https://terraloteos.com/img/proyectos/Ayres%20de%20Larca/ayres-de-larca-item2.jpg",
      ],
      punto3: [
        "fas fa-arrow-left",
        "Todos los servicios y comodidades",
        "El loteo cuenta con todos los servicios necesarios para llevar una vida tranquila y con todas las comodidades rodeado de la naturaleza. Cuenta con agua potable, luz, alumbrado de calles y calles terminadas.",
        "https://terraloteos.com/img/proyectos/Ayres%20de%20Larca/ayres-de-larca-item3.jpg",
      ],
    },
  };

  const setNavbar = () => {
    if (window.screen.width < 800) {
      return <NavbarResponsive />;
    } else {
      return <Navbar active={3} />;
    }
  };

  return (
    <>
      {setNavbar()}
      <Proyecto data={data}></Proyecto>
    </>
  );
}

export default AyresDeLarca;
