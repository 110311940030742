import React from "react";
import Proyecto from "../../layouts/Proyecto";
import Navbar from "../../components/Navbar";
import NavbarResponsive from "../../components/NavbarResponsive";

function LasChacras() {
  var data = {
    image: "las-chacras-hero",
    nombre: "Las Chacras",
    descripcion:
      "Buscamos crear un espacio para que puedas construir un lugar único especial para vos, tu casa de fin de semana, un lugar para encontrarte con vos mismo, para realizar tus proyectos personales, tu finca o chacra.",
    items: {
      item1: ["fas fa-map-pin", "Villa Mercedes, San Luis", "Av. Circunvalación Extremo Norte - Ruta 148"],
      item2: [
        "fas fa-expand-arrows-alt",
        "135 Hectareas",
        "Divididas en 27 lotes",
      ],
      item3: ["fas fa-lightbulb", "Infraestructura", "Alumbrado de Calles, Calles terminadas"],
      item4: ["fas fa-tint", "Servicios", "Agua Potable, Luz"],
    },
    servicios: [
      "Agua Potable",
      "Luz",
      "Alumbrado de Calles",
      "Calles terminadas",
    ],
    galeria: [
      "https://terraloteos.com/img/proyectos/Las%20Chacras/las-chacras-1.jpg",
      "https://terraloteos.com/img/proyectos/Las%20Chacras/las-chacras-hero.jpg",
    ],
    plano:
    "https://terraloteos.com/img/proyectos/Las%20Chacras/las-chacras-plano.png",
    mapa: "!1m18!1m12!1m3!1d3374.3422681762295!2d-65.01997004859722!3d-32.24887208104217!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95d2de6cb04e4a33%3A0x702805164926ba0c!2sLoteo%20Las%20Chacras!5e0!3m2!1ses!2sar!4v1681238261882!5m2!1ses!2sar",
    puntos: {
      punto1: [
        "fas fa-pin",
        "Ubicación Privilegiada",
        "Ubicado en la Av. Circunvalación de la Ciudad de Villa Mercedes, a pocos minutos del corazón de la ciudad.",
        "https://terraloteos.com/img/proyectos/Las%20Chacras/las-chacras-1.jpg",
      ],
      punto2: [
        "fas fa-arrows-expand",
        "Infraestructura",
        "Este lote también cuenta con servicios esenciales como agua potable, luz y alumbrado de calles, así como calles terminadas, lo que lo hace aún más atractivo para aquellos que buscan un espacio funcional y bien equipado para sus proyectos personales.",
        "https://terraloteos.com/img/proyectos/Las%20Chacras/las-chacras-hero.jpg",
      ],
    },
  };

  const setNavbar = () => {
    if (window.screen.width < 800) {
      return <NavbarResponsive />;
    } else {
      return <Navbar active={3} />;
    }
  };

  return (
    <>
      {setNavbar()}
      <Proyecto data={data}></Proyecto>
    </>
  );
}

export default LasChacras;
