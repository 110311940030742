export const casas = [
    {
      titulo: "Loft",
      resumen:
        "Loft con sala de estar, comedor, cocina, cochera, galería y terraza.",
      habitaciones: "1",
      descripcion:
        "El Loft cuenta con sala de estar, comedor, cocina, cochera, galería - expansión y terraza.",
  
      detalles:
        "Los trabajos a ejecutar comprenden la construcción de un Loft con sala de estar, comedor y cocina, cochera, galería y terraza. Esto incluirá los trabajos necesarios, tales como: instalaciones sanitarias (agua y cloaca), instalaciones eléctricas (bocas y artefactos), equipamiento, revestimiento, pintura, revoques, carpintería, etc.",
      duracion: "180 días",
      financiacion:
        "La construcción de esta casa cuenta con financiación propia.",
      loteo: true,
      loteo_nombre: "Cualquiera",
      tamano_terreno: "106",
      servicios: "Agua Potable, Luz, Alumbrado de Calles, Calles terminadas",
      galeria: [
        "casas/loft/loft%20(1).jpg",
        "casas/loft/loft%20(2).jpg",
        "casas/loft/loft%20(3).jpg",
        "casas/loft/loft%20(4).jpg",
      ],
      link: "/casas/loft",
      nombre: "loft",
      items: {
        item1: ["fas fa-bed", "1 Habitación", "Amplios espacios"],
        item2: ["fas fa-expand-arrows-alt", "106m2", "Amplio terreno"],
        item3: [
          "fas fa-door-closed",
          "Calidad",
          "Calidad de construcción asegurada",
        ],
        item4: [
          "fas fa-hourglass",
          "Entrega en 180 días",
          "No esperes más y tené tu casa ya!",
        ],
      },
      caracteristicas: [
        "Sala de estar",
        "Comedor",
        "Cocina",
        "Cochera",
        "Galería",
        "Expansión",
        "Terraza",
      ],
      puntos: {
        punto1: [
          "Funcionalidad",
          "Cada espacio de esta casa ha sido diseñado para ofrecer comodidad y practicidad, con materiales y acabados adaptados a la vida moderna.",
        ],
        punto2: [
          "Durabilidad",
          "Esta casa está construida con materiales de alta calidad y atención detallada, asegurando su resistencia y excelente estado durante años.",
        ],
        punto3: [
          "Diseño moderno",
          " La combinación de estilo y funcionalidad es impresionante en esta casa, con líneas limpias y detalles minimalistas que generan un ambiente sofisticado.",
        ],
      },
    },
    {
      titulo: "Loft A1",
      resumen:
        "Loft contemporáneo con sala de estar, comedor, cocina, cochera - galería y terraza.",
      habitaciones: "1",
      descripcion:
        "El Loft A1 tiene una tipología contemporánea sin molduras y revestimiento de piedra. Cuenta con sala de estar, comedor, cocina, cochera - galería y terraza.",
      detalles:
        "Los trabajos a ejecutar comprenden la construcción de un Loft A1 con sala de estar, comedor, cocina, cochera - galería, y terraza. Esto incluirá los trabajos necesarios, tales como: instalaciones sanitarias (agua y cloaca), instalaciones eléctricas (bocas y artefactos), equipamiento, revestimiento, pintura, revoques, carpintería, etc.",
      duracion: "180 días",
      financiacion:
        "La construcción de este loft cuenta con financiación propia.",
      loteo: true,
      loteo_nombre: "Cualquiera",
      tamano_terreno: "99",
      servicios:
        "Agua Potable, Luz, Gas, Cloacas, Alumbrado de Calles, Calles terminadas",
      galeria: [
        "casas/loft-a1/loft-a1%20(1).jpg",
        "casas/loft-a1/loft-a1%20(2).jpg",
        "casas/loft-a1/loft-a1%20(3).jpg",
        "casas/loft-a1/loft-a1%20(4).jpg",
        "casas/loft-a1/loft-a1%20(5).jpg",
      ],
      link: "/casas/loft-a1",
      nombre: "loft-a1",
      items: {
        item1: ["fas fa-bed", "1 Habitación", "Amplios espacios"],
        item2: ["fas fa-expand-arrows-alt", "99m2", "Amplio terreno"],
        item3: [
          "fas fa-door-closed",
          "Calidad",
          "Calidad de construcción asegurada",
        ],
        item4: [
          "fas fa-hourglass",
          "Entrega en 180 días",
          "No esperes más y tené tu casa ya!",
        ],
      },
      caracteristicas: [
        "Sala de estar",
        "Comedor",
        "Cocina",
        "Cochera - Galería",
        "Terraza",
        ],
      puntos: {
        punto1: [
          "Funcionalidad",
          "Cada espacio de esta casa ha sido diseñado para ofrecer comodidad y practicidad, con materiales y acabados adaptados a la vida moderna.",
        ],
        punto2: [
          "Durabilidad",
          "Esta casa está construida con materiales de alta calidad y atención detallada, asegurando su resistencia y excelente estado durante años.",
        ],
        punto3: [
          "Diseño moderno",
          " La combinación de estilo y funcionalidad es impresionante en esta casa, con líneas limpias y detalles minimalistas que generan un ambiente sofisticado.",
        ],
      },
    },
    {
      titulo: "Loft A2",
      resumen:
        "Loft con una tipología contenporanea, sin molduras y con revestimiento en piedra.",
      habitaciones: "1",
      descripcion:
        "La vivienda contará con los siguientes locales y espacios:\n• Estar\n• comedor\n• Cocina\n• Entrepiso\n• 1 Dormitorio\n• 1 Baño\n• Galería – Expansión con asador\n• Terraza\nContemporánea sin molduras y con revestimiento en piedra.",
      detalles:
        "Los trabajos a ejecutar comprenden la construcción de un Loft A2 con terraza accesible. Esto incluirá los trabajos necesarios, tales como: instalaciones sanitarias (agua y cloaca), instalaciones eléctricas (bocas y artefactos), equipamiento, revestimiento, pintura, revoques, carpintería, etc.",
      duracion: "180 días",
      financiacion: "Cuenta con financiación propia.",
      loteo: true,
      loteo_nombre: "En cualquiera de nuestros emprendimientos.",
      tamano_terreno: "-",
      servicios:
        "Agua Potable, Luz, Gas, Cloacas, Alumbrado de Calles, Calles terminadas",
      galeria: [
        "casas/loft-a2/loft-a2%20(1).jpg",
        "casas/loft-a2/loft-a2%20(2).jpg",
        "casas/loft-a2/loft-a2%20(3).jpg",
        "casas/loft-a2/loft-a2%20(4).jpg",
      ],
      link: "/casas/loft-a2",
      nombre: "loft-a2",
      items: {
        item1: ["fas fa-bed", "1 Habitación", "Amplios espacios"],
        item2: ["fas fa-expand-arrows-alt", "99m2", "Amplio terreno"],
        item3: [
          "fas fa-door-closed",
          "Calidad",
          "Calidad de construcción asegurada",
        ],
        item4: [
          "fas fa-hourglass",
          "Entrega en 180 días",
          "No esperes más y tené tu casa ya!",
        ],
      },
      caracteristicas: [
        "Estar",
        "Comedor",
        "Cocina",
        "Entrepiso",
        "Dormitorio",
        "Baño",
        "Galería",
        "Expansión",
        "Terraza",
        ],
      puntos: {
        punto1: [
          "Funcionalidad",
          "Cada espacio de esta casa ha sido diseñado para ofrecer comodidad y practicidad, con materiales y acabados adaptados a la vida moderna.",
        ],
        punto2: [
          "Durabilidad",
          "Esta casa está construida con materiales de alta calidad y atención detallada, asegurando su resistencia y excelente estado durante años.",
        ],
        punto3: [
          "Diseño moderno",
          " La combinación de estilo y funcionalidad es impresionante en esta casa, con líneas limpias y detalles minimalistas que generan un ambiente sofisticado.",
        ],
      },
    },
    {
      titulo: "Tipología A",
      resumen:
        "Casa con dos dormitorios, sala de estar, comedor, cocina, baño, galería - expansión y cochera.",
      habitaciones: "2",
      descripcion:
        "La casa cuenta con dos dormitorios, una amplia sala de estar y comedor junto con una cocina con desayunador para dos personas. Además, tiene una amplia ventana con vistas a las sierras que lleva a la cochera y galería. También cuenta con un baño completo, galería - expansión y cochera.",
  
      detalles:
        "Los trabajos a ejecutar comprenden la construcción de una vivienda de dos dormitorios. Esto incluirá los trabajos necesarios, tales como: instalaciones sanitarias (agua y cloaca), instalaciones eléctricas (bocas y artefactos), equipamiento, revestimiento, pintura, revoques, carpintería, etc.",
      duracion: "180 días",
      financiacion: "Esta casa cuenta con financiación propia",
      loteo: "Si",
      loteo_nombre: "En cualquiera de nuestros emprendimientos.",
      tamano_terreno: "99",
      servicios:
        "Agua Potable, Luz, Gas, Cloacas, Alumbrado de Calles, Calles terminadas",
      galeria: [
        "casas/tipologia-a/tipologia-a%20(1).jpg",
        "casas/tipologia-a/tipologia-a%20(2).jpg",
        "casas/tipologia-a/tipologia-a%20(3).jpg",
        "casas/tipologia-a/tipologia-a%20(4).jpg",
      ],
      link: "/casas/tipologia-a",
      nombre: "tipologia-a",
      items: {
        item1: ["fas fa-bed", "2 Habitaciones", "Ideal para familias"],
        item2: ["fas fa-expand-arrows-alt", "99m2", "Amplio terreno"],
        item3: [
          "fas fa-door-closed",
          "Calidad",
          "Calidad de construcción asegurada",
        ],
        item4: [
          "fas fa-hourglass",
          "Entrega en 180 días",
          "No esperes más y tené tu casa ya!",
        ],
      },
      caracteristicas: [
        "Sala de estar",
        "Comedor",
        "Cocina con desayunador para dos personas",
        "Ventana con vistas a las sierras",
        "Dos dormitorios",
        "Baño completo",
        "Galería",
        "Expansión",
        "Cochera",
        ],
      puntos: {
        punto1: [
          "Funcionalidad",
          "Cada espacio de esta casa ha sido diseñado para ofrecer comodidad y practicidad, con materiales y acabados adaptados a la vida moderna.",
        ],
        punto2: [
          "Durabilidad",
          "Esta casa está construida con materiales de alta calidad y atención detallada, asegurando su resistencia y excelente estado durante años.",
        ],
        punto3: [
          "Diseño moderno",
          " La combinación de estilo y funcionalidad es impresionante en esta casa, con líneas limpias y detalles minimalistas que generan un ambiente sofisticado.",
        ],
      },
    },
    {
      titulo: "Tipología B",
      resumen:
        "Casa con dos dormitorios, sala de estar, comedor, cocina, baño, galería - expansión y cochera.",
      habitaciones: "2",
      descripcion:
        "La casa 'Tipología B' cuenta con dos dormitorios, una amplia sala de estar y comedor junto con una cocina con desayunador para dos personas. En el lado este, tiene una amplia ventana con vistas a las sierras y salida a la cochera - galería. Además, cuenta con un baño completo y galería - expansión.",
  
      detalles:
        "Los trabajos a ejecutar comprenden la construcción de una vivienda de dos dormitorios. Esto incluirá los trabajos necesarios, tales como: instalaciones sanitarias (agua y cloaca), instalaciones eléctricas (bocas y artefactos), equipamiento, revestimiento, pintura, revoques, carpintería, etc.",
      duracion: "180 días",
      financiacion: "Esta casa cuenta con financiación propia",
      loteo: "Si",
      loteo_nombre: "En cualquiera de nuestros emprendimientos.",
      tamano_terreno: "99",
      servicios:
        "Agua Potable, Luz, Gas, Cloacas, Alumbrado de Calles, Calles terminadas",
      galeria: [
        "casas/tipologia-b/tipologia-b%20(1).jpg",
        "casas/tipologia-b/tipologia-b%20(2).jpg",
        "casas/tipologia-b/tipologia-b%20(3).jpg",
      ],
      link: "/casas/tipologia-b",
      nombre: "tipologia-b",
      items: {
        item1: ["fas fa-bed", "2 Habitaciones", "Ideal para familias"],
        item2: ["fas fa-expand-arrows-alt", "99m2", "Amplio terreno"],
        item3: [
          "fas fa-door-closed",
          "Calidad",
          "Calidad de construcción asegurada",
        ],
        item4: [
          "fas fa-hourglass",
          "Entrega en 180 días",
          "No esperes más y tené tu casa ya!",
        ],
      },
      caracteristicas: [
        "Dos dormitorios",
        "Sala de estar",
        "Comedor",
        "Cocina con desayunador para dos personas",
        "Cochera - galería",
        "Baño completo",
        "Galería - expansión"
        ],
      puntos: {
        punto1: [
          "Funcionalidad",
          "Cada espacio de esta casa ha sido diseñado para ofrecer comodidad y practicidad, con materiales y acabados adaptados a la vida moderna.",
        ],
        punto2: [
          "Durabilidad",
          "Esta casa está construida con materiales de alta calidad y atención detallada, asegurando su resistencia y excelente estado durante años.",
        ],
        punto3: [
          "Diseño moderno",
          " La combinación de estilo y funcionalidad es impresionante en esta casa, con líneas limpias y detalles minimalistas que generan un ambiente sofisticado.",
        ],
      },
    },
    {
      titulo: "Tipología B3",
      resumen:
        "Casa con tres dormitorios, sala de estar, comedor, cocina, baño, galería - expansión y cochera.",
      habitaciones: "3",
      descripcion:
        "Casa con tres dormitorios, una amplia sala de estar de dos ambientes junto con una cocina con desayunador para dos personas. En el lado este, tiene una amplia ventana con vistas a las sierras que lleva a la galería y cochera. También cuenta con un baño completo y galería - expansión.",
  
      detalles:
        "Los trabajos a ejecutar comprenden la construcción de una vivienda de dos dormitorios. Esto incluirá los trabajos necesarios, tales como: instalaciones sanitarias (agua y cloaca), instalaciones eléctricas (bocas y artefactos), equipamiento, revestimiento, pintura, revoques, carpintería, etc.",
      duracion: "180 días",
      financiacion: "Esta casa cuenta con financiación propia.",
      loteo: "Si",
      loteo_nombre: "En cualquiera de nuestros emprendimientos.",
      tamano_terreno: "119",
      servicios:
        "Agua Potable, Luz, Gas, Cloacas, Alumbrado de Calles, Calles terminadas",
      galeria: [
        "casas/tipologia-b3/tipologia-b3%20(1).jpg",
        "casas/tipologia-b3/tipologia-b3%20(2).jpg",
        "casas/tipologia-b3/tipologia-b3%20(3).jpg",
        "casas/tipologia-b3/tipologia-b3%20(4).jpg",
      ],
      link: "/casas/tipologia-b3",
      nombre: "tipologia-b3",
      items: {
        item1: ["fas fa-bed", "3 Habitaciones", "Ideal para familias"],
        item2: ["fas fa-expand-arrows-alt", "119m2", "Amplio terreno"],
        item3: [
          "fas fa-door-closed",
          "Calidad",
          "Calidad de construcción asegurada",
        ],
        item4: [
          "fas fa-hourglass",
          "Entrega en 180 días",
          "No esperes más y tené tu casa ya!",
        ],
      },
      caracteristicas: [
        "Tres dormitorios",
        "Sala de estar de dos ambientes",
        "Comedor",
        "Cocina con desayunador para dos personas",
        "Cochera",
        "Baño completo",
        "Galería - expansión"
        ],
      puntos: {
        punto1: [
          "Funcionalidad",
          "Cada espacio de esta casa ha sido diseñado para ofrecer comodidad y practicidad, con materiales y acabados adaptados a la vida moderna.",
        ],
        punto2: [
          "Durabilidad",
          "Esta casa está construida con materiales de alta calidad y atención detallada, asegurando su resistencia y excelente estado durante años.",
        ],
        punto3: [
          "Diseño moderno",
          " La combinación de estilo y funcionalidad es impresionante en esta casa, con líneas limpias y detalles minimalistas que generan un ambiente sofisticado.",
        ],
      },
    },
    {
      titulo: "Tipología C",
      resumen:
        "Casa minimalista con dos dormitorios, sala de estar, comedor, cocina, baño, galería - expansión",
      habitaciones: "2",
      descripcion:
        "La casa 'Tipología C' cuenta con dos dormitorios, una amplia sala de estar y comedor junto con una cocina con desayunador para dos personas. Es minimalista con molduras rodeando las aberturas y revestimiento con piedra. Además, tiene un baño completo y galería - expansión.",
  
      detalles:
        "Los trabajos a ejecutar comprenden la construcción de una vivienda de dos dormitorios. Esto incluirá los trabajos necesarios, tales como: instalaciones sanitarias (agua y cloaca), instalaciones eléctricas (bocas y artefactos), equipamiento, revestimiento, pintura, revoques, carpintería, etc.",
      duracion: "180 días",
      financiacion: "Esta casa cuenta con financiación propia.",
      loteo: "Si",
      loteo_nombre: "En cualquiera de nuestros emprendimientos.",
      tamano_terreno: "151",
      servicios:
        "Agua Potable, Luz, Gas, Cloacas, Alumbrado de Calles, Calles terminadas",
      galeria: [
        "casas/tipologia-c/tipologia-c%20(1).jpg",
        "casas/tipologia-c/tipologia-c%20(2).jpg",
        "casas/tipologia-c/tipologia-c%20(3).jpg",
        "casas/tipologia-c/tipologia-c%20(4).jpg",
      ],
      link: "/casas/tipologia-c",
      nombre: "tipologia-c",
      items: {
        item1: ["fas fa-bed", "2 Habitaciones", "Ideal para familias"],
        item2: ["fas fa-expand-arrows-alt", "151m2", "Amplio terreno"],
        item3: [
          "fas fa-door-closed",
          "Calidad",
          "Calidad de construcción asegurada",
        ],
        item4: [
          "fas fa-hourglass",
          "Entrega en 180 días",
          "No esperes más y tené tu casa ya!",
        ],
      },
      caracteristicas: [
        "Dos dormitorios",
        "Sala de estar",
        "Comedor",
        "Cocina con desayunador para dos personas",
        "Baño completo",
        "Galería - expansión"
        ],        
      puntos: {
        punto1: [
          "Funcionalidad",
          "Cada espacio de esta casa ha sido diseñado para ofrecer comodidad y practicidad, con materiales y acabados adaptados a la vida moderna.",
        ],
        punto2: [
          "Durabilidad",
          "Esta casa está construida con materiales de alta calidad y atención detallada, asegurando su resistencia y excelente estado durante años.",
        ],
        punto3: [
          "Diseño moderno",
          " La combinación de estilo y funcionalidad es impresionante en esta casa, con líneas limpias y detalles minimalistas que generan un ambiente sofisticado.",
        ],
      },
    },
    {
      titulo: "Tipología D",
      resumen:
        "Casa minimalista con dos dormitorios, sala de estar, comedor, cocina, baño, galería - expansión.",
      habitaciones: "2",
      descripcion:
        "Esta vivienda es minimalista con molduras rodeando las aberturas y revestimiento con piedra. Cuenta con dos dormitorios, una amplia sala de estar y comedor junto con una cocina con desayunador para dos personas. También cuenta con un baño completo y galería - expansión.",
      detalles:
        "Los trabajos a ejecutar comprenden la construcción de una vivienda de dos dormitorios. Esto incluirá los trabajos necesarios, tales como: instalaciones sanitarias (agua y cloaca), instalaciones eléctricas (bocas y artefactos), equipamiento, revestimiento, pintura, revoques, carpintería, etc.",
      duracion: "180 días.",
      financiacion: "Esta casa cuenta con financiación propia.",
      loteo: "Si",
      loteo_nombre: "Se puede construir en todos nuestros emprendimientos.",
      tamano_terreno: "122",
      servicios:
        "Agua Potable, Luz, Gas, Cloacas, Alumbrado de Calles, Calles terminadas",
      galeria: [
        "casas/tipologia-d/tipologia-d%20(1).jpg",
        "casas/tipologia-d/tipologia-d%20(2).jpg",
        "casas/tipologia-d/tipologia-d%20(3).jpg",
        "casas/tipologia-d/tipologia-d%20(4).jpg",
      ],
      link: "/casas/tipologia-d",
      nombre: "tipologia-d",
      items: {
        item1: ["fas fa-bed", "2 Habitaciones", "Ideal para familias"],
        item2: ["fas fa-expand-arrows-alt", "122m2", "Amplio terreno"],
        item3: [
          "fas fa-door-closed",
          "Calidad",
          "Calidad de construcción asegurada",
        ],
        item4: [
          "fas fa-hourglass",
          "Entrega en 180 días",
          "No esperes más y tené tu casa ya!",
        ],
      },
      caracteristicas: [
        "Dos dormitorios",
        "Sala de estar",
        "Comedor",
        "Cocina con desayunador para dos personas",
        "Baño completo",
        "Galería - expansión",
        ],
      puntos: {
        punto1: [
          "Funcionalidad",
          "Cada espacio de esta casa ha sido diseñado para ofrecer comodidad y practicidad, con materiales y acabados adaptados a la vida moderna.",
        ],
        punto2: [
          "Durabilidad",
          "Esta casa está construida con materiales de alta calidad y atención detallada, asegurando su resistencia y excelente estado durante años.",
        ],
        punto3: [
          "Diseño moderno",
          " La combinación de estilo y funcionalidad es impresionante en esta casa, con líneas limpias y detalles minimalistas que generan un ambiente sofisticado.",
        ],
      },
    },
    {
      titulo: "Tipología D1",
      resumen:
        "Casa con dos dormitorios, vestidor, baño en suite, sala de estar, cocina, baño, cochera, galería - expansión y terraza.",
      habitaciones: "2",
      descripcion:
        "La casa D1 cuenta con dos dormitorios, uno de ellos es el dormitorio principal con vestidor y baño en suite. Además, tiene una amplia sala de estar de dos ambientes junto con una cocina con desayunador para dos personas. También cuenta con un baño completo, cochera, galería - expansión y terraza.",
  
      detalles:
        "Los trabajos a ejecutar comprenden la construcción de una vivienda de dos dormitorios y dos baños. Esto incluirá los trabajos necesarios, tales como: instalaciones sanitarias (agua y cloaca), instalaciones eléctricas (bocas y artefactos), equipamiento, revestimiento, pintura, revoques, carpintería, etc.",
      duracion: "180 días",
      financiacion: "Esta casa cuenta con financiación propia",
      loteo: "Si",
      loteo_nombre:
        "Vivienda ideal para emprendimientos como Terrazas del Talar y Miralba.",
      tamano_terreno: "167",
      servicios:
        "Agua Potable, Luz, Gas, Cloacas, Alumbrado de Calles, Calles terminadas",
      galeria: [
        "casas/tipologia-d1/tipologia-d1%20(1).jpg",
        "casas/tipologia-d1/tipologia-d1%20(2).jpg",
        "casas/tipologia-d1/tipologia-d1%20(3).jpg",
        "casas/tipologia-d1/tipologia-d1%20(4).jpg",
      ],
      link: "/casas/tipologia-d1",
      nombre: "tipologia-d1",
      items: {
        item1: ["fas fa-bed", "2 Habitaciones", "Ideal para familias"],
        item2: ["fas fa-expand-arrows-alt", "167m2", "Amplio terreno"],
        item3: [
          "fas fa-door-closed",
          "Calidad",
          "Calidad de construcción asegurada",
        ],
        item4: [
          "fas fa-hourglass",
          "Entrega en 180 días",
          "No esperes más y tené tu casa ya!",
        ],
      },
      caracteristicas: [
        "Dos dormitorios",
        "Dormitorio principal con vestidor y baño en suite",
        "Sala de estar de dos ambientes",
        "Comedor",
        "Cocina con desayunador para dos personas",
        "Baño completo",
        "Cochera",
        "Galería - expansión",
        "Terraza",
        ],
      puntos: {
        punto1: [
          "Funcionalidad",
          "Cada espacio de esta casa ha sido diseñado para ofrecer comodidad y practicidad, con materiales y acabados adaptados a la vida moderna.",
        ],
        punto2: [
          "Durabilidad",
          "Esta casa está construida con materiales de alta calidad y atención detallada, asegurando su resistencia y excelente estado durante años.",
        ],
        punto3: [
          "Diseño moderno",
          " La combinación de estilo y funcionalidad es impresionante en esta casa, con líneas limpias y detalles minimalistas que generan un ambiente sofisticado.",
        ],
      },
    },
    {
      titulo: "Tipología E",
      resumen:
        "Casa con tres dormitorios, cocina comedor, baño, lavadero, estar comedor, galería con asador, cochera y terraza.",
      habitaciones: "3",
      descripcion:
        "La casa E cuenta con tres dormitorios, dos de ellos ubicados en la planta alta junto con un baño. En la planta baja, cuenta con cocina comedor amplio, baño, lavadero, depósito, estar comedor, galería con asador y cochera. También tiene una amplia y accesible terraza.",
  
      detalles:
        "Los trabajos a ejecutar comprenden la construcción de una vivienda de 3 dormitorios, Dos plantas, y dos baños. Esto incluirá los trabajos necesarios, tales como: instalaciones sanitarias (agua y cloaca), instalaciones eléctricas (bocas y artefactos), equipamiento, revestimiento, pintura, revoques, carpintería, etc.",
      duracion: "180 días",
      financiacion: "Esta casa cuenta con financiación propia",
      loteo: "Si",
      loteo_nombre: "Casa ideal para loteos como Terrazas del Talar y Miralba",
      tamano_terreno: "240",
      servicios:
        "Agua Potable, Luz, Gas, Cloacas, Alumbrado de Calles, Calles terminadas",
      galeria: [
        "casas/tipologia-e/tipologia-e%20(1).jpg",
        "casas/tipologia-e/tipologia-e%20(2).jpg",
        "casas/tipologia-e/tipologia-e%20(3).jpg",
        "casas/tipologia-e/tipologia-e%20(4).jpg",
      ],
      link: "/casas/tipologia-e",
      nombre: "tipologia-e",
      items: {
        item1: ["fas fa-bed", "3 Habitaciones", "Ideal para familias"],
        item2: ["fas fa-expand-arrows-alt", "240m2", "Amplio terreno"],
        item3: [
          "fas fa-door-closed",
          "Calidad",
          "Calidad de construcción asegurada",
        ],
        item4: [
          "fas fa-hourglass",
          "Entrega en 180 días",
          "No esperes más y tené tu casa ya!",
        ],
      },
      caracteristicas: [
        "Tres dormitorios",
        "Cocina comedor amplio",
        "Baño",
        "Lavadero",
        "Depósito",
        "Estar comedor",
        "Galería con asador",
        "Cochera",
        "Terraza amplia y accesible",
        ],
      puntos: {
        punto1: [
          "Funcionalidad",
          "Cada espacio de esta casa ha sido diseñado para ofrecer comodidad y practicidad, con materiales y acabados adaptados a la vida moderna.",
        ],
        punto2: [
          "Durabilidad",
          "Esta casa está construida con materiales de alta calidad y atención detallada, asegurando su resistencia y excelente estado durante años.",
        ],
        punto3: [
          "Diseño moderno",
          " La combinación de estilo y funcionalidad es impresionante en esta casa, con líneas limpias y detalles minimalistas que generan un ambiente sofisticado.",
        ],
      },
    },
    {
      titulo: "Tipología F",
      resumen:
        "Casa con tres dormitorios, baño en suite, sala de estar, cocina, patio de servicio, lavadero, toilet y terraza.",
      habitaciones: "3",
      descripcion:
        "La casa F cuenta con tres dormitorios, dos de ellos ubicados en la planta alta junto con un baño en suite. Además, cuenta con hall de ingreso, amplia sala de estar, despensa, cocina, patio de servicio, lavadero y toilet. Este modelo cuenta con terraza.",
      detalles:
        "Los trabajos a ejecutar comprenden la construcción de una vivienda de dos plantas con tres dormitorios, tres baños, lavadero y despensa. Esto incluirá los trabajos necesarios, tales como: instalaciones sanitarias (agua y cloaca), instalaciones eléctricas (bocas y artefactos), equipamiento, revestimiento, pintura, revoques, carpintería, etc.",
      duracion: "180 días",
      financiacion: "Esta casa cuenta con financiación propia",
      loteo: "Si",
      loteo_nombre: "Vivienda ideal para Terraza del Talar o Miralba.",
      tamano_terreno: "208",
      servicios:
        "Agua Potable, Luz, Gas, Cloacas, Alumbrado de Calles, Calles terminadas",
      galeria: [
        "casas/tipologia-f/tipologia-f%20(1).jpg",
        "casas/tipologia-f/tipologia-f%20(2).jpg",
        "casas/tipologia-f/tipologia-f%20(3).jpg",
        "casas/tipologia-f/tipologia-f%20(4).jpg",
      ],
      link: "/casas/tipologia-f",
      nombre: "tipologia-f",
      items: {
        item1: ["fas fa-bed", "3 Habitaciones", "Ideal para familias"],
        item2: ["fas fa-expand-arrows-alt", "208m2", "Amplio terreno"],
        item3: [
          "fas fa-door-closed",
          "Calidad",
          "Calidad de construcción asegurada",
        ],
        item4: [
          "fas fa-hourglass",
          "Entrega en 180 días",
          "No esperes más y tené tu casa ya!",
        ],
      },
      caracteristicas: [
        "Hall de ingreso",
        "Sala de estar",
        "Despensa",
        "Cocina",
        "Patio de servicio",
        "Lavadero",
        "Toilet",
        "Terraza",
        "Tres dormitorios",
        "Tres baños"
        ],
      puntos: {
        punto1: [
          "Funcionalidad",
          "Cada espacio de esta casa ha sido diseñado para ofrecer comodidad y practicidad, con materiales y acabados adaptados a la vida moderna.",
        ],
        punto2: [
          "Durabilidad",
          "Esta casa está construida con materiales de alta calidad y atención detallada, asegurando su resistencia y excelente estado durante años.",
        ],
        punto3: [
          "Diseño moderno",
          " La combinación de estilo y funcionalidad es impresionante en esta casa, con líneas limpias y detalles minimalistas que generan un ambiente sofisticado.",
        ],
      },
    },
    {
      titulo: "Tipología H",
      resumen:
        "Casa con tres dormitorios, sala de estar con comedor, cocina, baño, cochera, galería con asador y terraza.",
      habitaciones: "3",
      descripcion:
        "La casa H cuenta con tres dormitorios, hall de ingreso, cocina y amplia sala de estar con comedor, toilet y lavado, cochera, galería con asador y dos plantas. En la planta alta, cuenta con dormitorio, baño y terraza.",
  
      detalles:
        "Los trabajos a ejecutar comprenden la construcción de una vivienda de tres dormitorios, baño y toilet + lavado. Esto incluirá los trabajos necesarios, tales como: instalaciones sanitarias (agua y cloaca), instalaciones eléctricas (bocas y artefactos), equipamiento, revestimiento, pintura, revoques, carpintería, etc.",
      duracion: "180 días",
      financiacion: "Esta casa cuenta con financiación propia",
      loteo: true,
      loteo_nombre: "Vivienda ideal para Miralba",
      tamano_terreno: "218",
      servicios:
        "Agua Potable, Luz, Gas, Cloacas, Alumbrado de Calles, Calles terminadas",
      galeria: [
        "casas/tipologia-h/tipologia-h%20(1).jpg",
        "casas/tipologia-h/tipologia-h%20(2).jpg",
        "casas/tipologia-h/tipologia-h%20(3).jpg",
        "casas/tipologia-h/tipologia-h%20(4).jpg",
      ],
      link: "/casas/tipologia-h",
      nombre: "tipologia-h",	
      items: {
        item1: ["fas fa-bed", "3 Habitaciones", "Ideal para familias"],
        item2: ["fas fa-expand-arrows-alt", "218m2", "Amplio terreno"],
        item3: [
          "fas fa-door-closed",
          "Calidad",
          "Calidad de construcción asegurada",
        ],
        item4: [
          "fas fa-hourglass",
          "Entrega en 180 días",
          "No esperes más y tené tu casa ya!",
        ],
      },
      caracteristicas: [
        "Hall de ingreso",
        "Cocina",
        "Sala de estar",
        "Comedor",
        "Toilet",
        "Lavado",
        "Cochera",
        "Galería con asador",
        "Terraza",
        "Tres dormitorios",
        "Baño"
        ],
      puntos: {
        punto1: [
          "Funcionalidad",
          "Cada espacio de esta casa ha sido diseñado para ofrecer comodidad y practicidad, con materiales y acabados adaptados a la vida moderna.",
        ],
        punto2: [
          "Durabilidad",
          "Esta casa está construida con materiales de alta calidad y atención detallada, asegurando su resistencia y excelente estado durante años.",
        ],
        punto3: [
          "Diseño moderno",
          " La combinación de estilo y funcionalidad es impresionante en esta casa, con líneas limpias y detalles minimalistas que generan un ambiente sofisticado.",
        ],
      },
    },
  ];

export const getCasa = (nombre) => {
    return casas.find((casa) => casa.nombre === nombre);
}