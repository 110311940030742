import React, {useState} from "react";

function BannerResponsive(props) {

    return(
        <div className="list-section pt-80 pb-80" id="oficinas">
          <div className="container">
              <div className="row">
                <div className="col-lg-3 col-md-6">
                  <div className="list-box d-flex align-items-center">
                    <div className="list-icon2 centrado-horizontal">
                      <i className={props.items.item1[0]} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-3 col-md-6 mb-4">
                  <div className="list-box d-flex align-items-center text-center centrado-horizontal">
                    <div className="content2 centrado-horizontal">
                      <a data-toggle="modal" data-target="#exampleModal">{props.items.item1[1]}</a>
                      <p>{props.items.item1[2]}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-3 col-md-6">
                  <div className="list-box d-flex align-items-center">
                    <div className="list-icon2 centrado-horizontal">
                      <i className={props.items.item2[0]} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-3 col-md-6 mb-4">
                  <div className="list-box d-flex align-items-center text-center centrado-horizontal">
                    <div className="content2 centrado-horizontal">
                      <a data-toggle="modal" data-target="#exampleModal">{props.items.item2[1]}</a>
                      <p>{props.items.item2[2]}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-3 col-md-6">
                  <div className="list-box d-flex align-items-center">
                    <div className="list-icon2 centrado-horizontal">
                      <i className={props.items.item3[0]} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-3 col-md-6 mb-4">
                  <div className="list-box d-flex align-items-center text-center centrado-horizontal">
                    <div className="content2 centrado-horizontal">
                      <a data-toggle="modal" data-target="#exampleModal">{props.items.item3[1]}</a>
                      <p>{props.items.item3[2]}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-3 col-md-6">
                  <div className="list-box d-flex align-items-center">
                    <div className="list-icon2 centrado-horizontal">
                      <i className={props.items.item4[0]} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-3 col-md-6 mb-4">
                  <div className="list-box d-flex align-items-center text-center centrado-horizontal">
                    <div className="content2 centrado-horizontal">
                      <a data-toggle="modal" data-target="#exampleModal">{props.items.item4[1]}</a>
                      <p>{props.items.item4[2]}</p>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
    )

}

export default BannerResponsive;