import { useState } from "react";
import { ListGroup } from "reactstrap";
import ChatItem from "../ChatItem";

function ChatContainer({ chat, setChat }) {
  const handleHover = (id) => {
    if (!chat[id].nuevo) return;
    const newChat = [...chat];
    newChat[id].nuevo = false;
    setChat(newChat);
  };
  return (
    <div contentClassName="card">
      <ListGroup>
        {chat.map((item, index) => (
          <ChatItem
            key={index}
            mensaje={item.mensaje}
            fecha={item.fecha}
            hora={item.hora}
            emisor={item.emisor}
            nuevo={item.nuevo}
            externo={item.externo}
            handleHover={() => handleHover(index)}
          />
        ))}
      </ListGroup>
    </div>
  );
}

export default ChatContainer;
