import React from "react";
import Casa from "../../layouts/Casa";
import Navbar from "../../components/Navbar";
import NavbarResponsive from "../../components/NavbarResponsive";
import { getCasa } from "../../data/casas.js";

function Loft() {

  const setNavbar = () => {
    if (window.screen.width < 800) {
      return <NavbarResponsive />;
    } else {
      return <Navbar active={4} />;
    }
  };

  return (
    <>
      {setNavbar()}
      {getCasa("tipologia-b3") ? <Casa data={getCasa("tipologia-b3")}/> : window.location.href = "/casas"}
    </>
  );
}

export default Loft;
