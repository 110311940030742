import React, { useState } from "react";
import ModalNavegacion from "../components/Modals/ModalNavegacion";

function NavbarResponsive(props) {
  const [modalNavegacion, setModalNavegacion] = useState(false);

  // Cambia el estado del modal, si es Falso pasa a Verdadero y visceversa
  const toggleModalNavegacion = () => {
    setModalNavegacion(!modalNavegacion);
  };

  return (
    <div className="top-header-area" style={{ padding: "0" }}>
      <div
        className="container"
        style={{
          position: "fixed",
          backgroundColor: "#133222",
          padding: "10px 5px",
          height: "70px",
        }}
      >
        <div className="row">
          <div className="col-lg-12 col-sm-12 text-right">
            <div className="main-menu-wrap">
              <div className="site-logo">
                <a href="/" className="ml-3">
                  <img src="https://terraloteos.com/img/logo.png" alt="" />
                </a>
              </div>
              <a
                className="mobile-show search-bar-icon btn-menu-movil btn-lg"
                href="/#"
              >
                <i className="fas fa-bars mr-0" />
              </a>
              {modalNavegacion && (
                <ModalNavegacion
                  modal={modalNavegacion}
                  toggle={toggleModalNavegacion}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NavbarResponsive;
