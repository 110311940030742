import { useEffect } from "react";
import Navbar from "../components/Navbar";
import NavbarResponsive from "../components/NavbarResponsive";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { Input } from "reactstrap";
// import Contact from "../components/Contact";

function NewBlog() {
  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (!user) {
      window.location.href = "/login";
    }
  });

  // const { blogUrl } = useParams();

  const setNavbar = () => {
    if (window.screen.width < 800) {
      return <NavbarResponsive />;
    } else {
      return <Navbar active={9} />;
    }
  };

  //click a button when the page load
  useEffect(() => {
    document.getElementById("filter-facturas").click();
  }, []);

  return (
    <>
      {setNavbar()}
      <div className="breadcrumb-section breadcrumb-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2 text-center">
              <div className="breadcrumb-text">
                <p>Acceso para clientes</p>
                <h1>Mi cuenta</h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="product-section mt-150 mb-150">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="product-filters">
                <ul>
                  <li
                    className="active"
                    data-filter=".facturas"
                    id="filter-facturas"
                  >
                    Facturas
                  </li>
                  <li data-filter=".cupones">Cupones</li>
                  <li data-filter=".ajustes">Mi usuario</li>
                  <li data-filter=".ayuda">Ayuda</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="row product-lists">
            <div className="col-lg-12 col-md-6 text-center facturas">
              <div className="single-product-item">
                <h3 className="pt-4 pb-3">Facturas</h3>
                <div className="col-lg-12 text-center">
                  <div className="checkout-accordion-wrap">
                    <div className="accordion" id="accordionExample">
                      <div className="card single-accordion">
                        <div className="card-header" id="headingOne">
                          <h5 className="mb-0">
                            <button
                              className="btn btn-link"
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseOne"
                              aria-expanded="true"
                              aria-controls="collapseOne"
                            >
                              Agosto 2023
                            </button>
                          </h5>
                        </div>
                        <div
                          id="collapseOne"
                          className="collapse show"
                          aria-labelledby="headingOne"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            <div className="row shipping-address-form">
                              <div className="col-lg-4 text-left">
                                <p>
                                  <b>Nombre:</b> Tomás Alfonso
                                </p>
                                <p>
                                  <b>DNI:</b> 12345678
                                </p>
                                <p>
                                  <b>Abonado:</b> 321
                                </p>
                              </div>
                              <div className="col-lg-4 text-left">
                                <p>
                                  <b>Loteo:</b> Estación Conlara
                                </p>
                                <p>
                                  <b>Manzana:</b> 12
                                </p>
                                <p>
                                  <b>Lote:</b> 32
                                </p>
                              </div>
                              <div className="col-lg-4 text-left">
                                <p>
                                  <b>Fecha de emisión:</b> 01/08/2023
                                </p>
                                <p>
                                  <b>Fecha de vencimiento:</b> 12/08/2023
                                </p>
                              </div>
                            </div>
                            <a href="#" className="boxed-btn mt-3">
                              Descargar PDF
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="card single-accordion">
                        <div className="card-header" id="headingTwo">
                          <h5 className="mb-0">
                            <button
                              className="btn btn-link collapsed"
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseTwo"
                              aria-expanded="false"
                              aria-controls="collapseTwo"
                            >
                              Julio 2023
                            </button>
                          </h5>
                        </div>
                        <div
                          id="collapseTwo"
                          className="collapse"
                          aria-labelledby="headingTwo"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            <div className="row shipping-address-form">
                              <div className="col-lg-4 text-left">
                                <p>
                                  <b>Nombre:</b> Tomás Alfonso
                                </p>
                                <p>
                                  <b>DNI:</b> 12345678
                                </p>
                                <p>
                                  <b>Abonado:</b> 321
                                </p>
                              </div>
                              <div className="col-lg-4 text-left">
                                <p>
                                  <b>Loteo:</b> Estación Conlara
                                </p>
                                <p>
                                  <b>Manzana:</b> 12
                                </p>
                                <p>
                                  <b>Lote:</b> 32
                                </p>
                              </div>
                              <div className="col-lg-4 text-left">
                                <p>
                                  <b>Fecha de emisión:</b> 01/07/2023
                                </p>
                                <p>
                                  <b>Fecha de vencimiento:</b> 12/07/2023
                                </p>
                              </div>
                            </div>
                            <a href="#" className="boxed-btn mt-3">
                              Descargar PDF
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="card single-accordion">
                        <div className="card-header" id="headingThree">
                          <h5 className="mb-0">
                            <button
                              className="btn btn-link collapsed"
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseThree"
                              aria-expanded="false"
                              aria-controls="collapseThree"
                            >
                              Junio 2023
                            </button>
                          </h5>
                        </div>
                        <div
                          id="collapseThree"
                          className="collapse"
                          aria-labelledby="headingThree"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            <div className="row shipping-address-form">
                              <div className="col-lg-4 text-left">
                                <p>
                                  <b>Nombre:</b> Tomás Alfonso
                                </p>
                                <p>
                                  <b>DNI:</b> 12345678
                                </p>
                                <p>
                                  <b>Abonado:</b> 321
                                </p>
                              </div>
                              <div className="col-lg-4 text-left">
                                <p>
                                  <b>Loteo:</b> Estación Conlara
                                </p>
                                <p>
                                  <b>Manzana:</b> 12
                                </p>
                                <p>
                                  <b>Lote:</b> 32
                                </p>
                              </div>
                              <div className="col-lg-4 text-left">
                                <p>
                                  <b>Fecha de emisión:</b> 01/06/2023
                                </p>
                                <p>
                                  <b>Fecha de vencimiento:</b> 12/06/2023
                                </p>
                              </div>
                            </div>
                            <a href="#" className="boxed-btn mt-3">
                              Descargar PDF
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pt-4">
                  <a
                    className="btn-circle btn-lg btn-round btn-selected"
                    href="#casas-title"
                  >
                    1
                  </a>
                  <a
                    className="btn-circle btn-lg btn-round"
                    href="#casas-title"
                  >
                    2
                  </a>
                  <a
                    className="btn-circle btn-lg btn-round"
                    href="#casas-title"
                  >
                    <i className="fas fa-arrow-right" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-6 text-center cupones">
              <div className="single-product-item">
                <h3 className="pt-4 pb-3">Cupones</h3>
                <div className="col-lg-12 text-center">
                  <div className="checkout-accordion-wrap">
                    <div className="accordion" id="accordionExample">
                      <div className="card single-accordion">
                        <div className="card-header" id="headingOne">
                          <h5 className="mb-0">
                            <button
                              className="btn btn-link"
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseOne"
                              aria-expanded="true"
                              aria-controls="collapseOne"
                            >
                              Agosto 2023
                            </button>
                          </h5>
                        </div>
                        <div
                          id="collapseOne"
                          className="collapse show"
                          aria-labelledby="headingOne"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            <div className="shipping-address-form">
                              <p>Aca van los datos del cupón</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card single-accordion">
                        <div className="card-header" id="headingTwo">
                          <h5 className="mb-0">
                            <button
                              className="btn btn-link collapsed"
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseTwo"
                              aria-expanded="false"
                              aria-controls="collapseTwo"
                            >
                              Julio 2023
                            </button>
                          </h5>
                        </div>
                        <div
                          id="collapseTwo"
                          className="collapse"
                          aria-labelledby="headingTwo"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            <div className="shipping-address-form">
                              <p>Aca van los datos del cupón</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card single-accordion">
                        <div className="card-header" id="headingThree">
                          <h5 className="mb-0">
                            <button
                              className="btn btn-link collapsed"
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseThree"
                              aria-expanded="false"
                              aria-controls="collapseThree"
                            >
                              Junio 2023
                            </button>
                          </h5>
                        </div>
                        <div
                          id="collapseThree"
                          className="collapse"
                          aria-labelledby="headingThree"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            <div className="card-details">
                              <p>Aca van los datos del cupón.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-6 text-center ajustes">
              <div className="single-product-item">
                <h3 className="pt-4 pb-3">Ajustes de cuenta</h3>
                <div className="col-lg-12 text-center">
                  <div className="row">
                    <div className="col-lg-4 text-left">
                      <label>Nombre de usuario</label>
                      <Input
                        defaultValue=""
                        placeholder="Nombre de usuario"
                        type="text"
                        className="blog-input"
                        value={"tomasalfonsook"}
                      />
                    </div>
                    <div className="col-lg-4 text-left">
                      <label>Correo electrónico</label>
                      <Input
                        defaultValue=""
                        placeholder="Correo electrónico"
                        type="text"
                        className="blog-input"
                        value={"tomasalfonso@kaizen.com.ar"}
                      />
                    </div>
                    <div className="col-lg-4 text-left">
                      <label>Teléfono</label>
                      <Input
                        defaultValue=""
                        placeholder="Teléfono"
                        type="text"
                        className="blog-input"
                        value={"+54 9 11 4444-4444"}
                      />
                    </div>
                  </div>
                  <a href="#" className="boxed-btn mt-3">
                    Guardar
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-6 text-center ajustes">
              <div className="single-product-item">
                <h3 className="pt-4 pb-3">Cambio de contraseña</h3>
                <div className="col-lg-12 text-center">
                  <div className="row">
                    <div className="col-lg-4 text-left">
                      <label>Contraseña anterior</label>
                      <Input
                        defaultValue=""
                        placeholder="Ingrese la contraseña"
                        type="password"
                        className="blog-input"
                      />
                    </div>
                    <div className="col-lg-4 text-left">
                      <label>Contraseña nueva</label>
                      <Input
                        defaultValue=""
                        placeholder="Ingrese la contraseña"
                        type="password"
                        className="blog-input"
                      />
                    </div>
                    <div className="col-lg-4 text-left">
                      <label>Confirmar contraseña</label>
                      <Input
                        defaultValue=""
                        placeholder="Ingrese la contraseña"
                        type="password"
                        className="blog-input"
                      />
                    </div>
                  </div>
                  <a href="#" className="boxed-btn mt-3">
                    Guardar
                  </a>
                </div>
              </div>
            </div>

            <div className="col-lg-12 col-md-6 text-center ayuda">
              <div className="single-product-item">
                <h3 className="pt-4 pb-3">Ayuda</h3>
                <div className="col-lg-12 text-center">
                  <div className="row justify-content-md-center">
                    <div className="col-lg-8 text-center">
                      <p>
                        Envianos un mensaje por el medio que prefieras y nos
                        comunicaremos con vos lo antes posible
                      </p>
                      <form id="contact">
                        <textarea
                          name="mensaje"
                          className="textarea-100"
                          id="mensaje"
                          rows={3}
                          placeholder="Mensaje"
                          defaultValue={""}
                          required
                        />
                        <p className="text-center">
                          <a href="#" className="boxed-btn m-2">
                            <i class="fa fa-envelope" aria-hidden="true" />{" "}
                            Enviar
                          </a>
                          <a href="#" className="wp-btn m-2">
                            <i className="fab fa-whatsapp" />
                            Enviar
                          </a>
                        </p>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewBlog;
