import { useEffect, useState } from 'react';
import { ModalHeader, ModalBody, Fade } from 'reactstrap';
import ChatContainer from './components/ChatContainer';
import axios from 'axios';
import { url } from '../../config/config';
import { gql, useMutation, useSubscription } from '@apollo/client';

const CHAT_SUBSCRIPTION = gql`
  subscription chat($ids: [String!]!) {
    chats(ids: $ids) {
      createdAt
      name
      text
    }
  }
`;

const SEND_MESSAGE_MUTATION = gql`
  mutation sendMessage(
    $name: String!
    $text: String!
    $idChat: String!
    $notify: ID
  ) {
    sendMessage(
      input: { name: $name, text: $text, idChat: $idChat, notify: $notify }
    ) {
      idChat
      notify
      name
      text
      createdAt
    }
  }
`;

function Chat() {
  const idChatFromLocalStorage = JSON.parse(localStorage.getItem('idChat'));
  const localChat = JSON.parse(localStorage.getItem('chat'));

  const [open, setOpen] = useState(true);
  const [message, setMessage] = useState('');
  const toggleOpen = () => {
    setOpen(!open);
  };
  const [chat, setChat] = useState([]);
  const [step, setStep] = useState(0);
  const [chatExistente, setChatExistente] = useState(true);
  const [cliente, setCliente] = useState(null);
  useSubscription(CHAT_SUBSCRIPTION, {
    variables: {
      ids: [idChatFromLocalStorage],
      //Falta hacer que actualice cuando cambia el idChat
    },
    onData: (data) => {
      const msg = data.data.data.chats;
      let newChat = [...chat];
      newChat.push({
        mensaje: msg.text,
        fecha: msg.createdAt.split(' ')[0],
        hora: msg.createdAt.split(' ')[1],
        emisor: msg.name,
        nuevo: true,
        externo: msg.name !== cliente?.nombre,
      });
      setChat(newChat);
    },
  });
  const [sendMessage] = useMutation(SEND_MESSAGE_MUTATION);

  const sendNuevaConsulta = async () => {
    const fecha = new Date();
    const fechaFormateada = fecha.toISOString();
    let newChat = [...chat];
    try {
      const res = await axios.post(url + '/rest/zapier', {
        nombreCliente: cliente.nombre,
        email: cliente.email,
        telefono: cliente.telefono,
        observaciones: cliente.consulta,
        fecha: fechaFormateada,
        plataforma: 'Chat',
        reasignable: 1,
      });
      let asesorOnline = res?.data?.asesorOnline;
      let idConsulta = res?.data?.id ? res?.data?.id : null;
      let idChat = res?.data?.idChat ? res?.data?.idChat : null;
      let idAsesor = res?.data?.idAsesor ? res?.data?.idAsesor : null;
      if (idChat) {
        await sendMessage({
          variables: {
            name: cliente?.nombre,
            text: cliente.consulta,
            idChat: idChat,
            notify: idAsesor,
          },
        });
      } else {
        newChat.push({
          mensaje: cliente.consulta,
          fecha: new Date().toLocaleDateString(),
          hora: new Date().toLocaleTimeString(),
          emisor: cliente?.nombre || 'Yo',
          nuevo: false,
          externo: false,
        });
        setChat(newChat);
      }
      if (!asesorOnline) {
        newChat.push({
          mensaje: `En este momento no hay ningún asesor en linea, sus datos han sido guardados, en breve nos pondremos en contacto con usted. Gracias.`,
          fecha: new Date().toLocaleDateString(),
          hora: new Date().toLocaleTimeString(),
          emisor: '🤖 TerraBot',
          nuevo: true,
          externo: true,
        });
        setChat(newChat);
      } else {
        newChat.push({
          mensaje: `Estamos conectandote con uno de nuestros asesores en linea, por favor espera un momento...`,
          fecha: new Date().toLocaleDateString(),
          hora: new Date().toLocaleTimeString(),
          emisor: '🤖 TerraBot',
          nuevo: true,
          externo: true,
        });
        localStorage.setItem('chat', JSON.stringify(newChat));
        localStorage.setItem('consulta', JSON.stringify(idConsulta));
        localStorage.setItem('idChat', JSON.stringify(idChat));
      }
      setChat(newChat);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (localChat && step === 0) {
      setChat(localChat);
      setStep(6);
    } else if (step === 0) {
      setStep(1);
    }
  }, [chat]);

  useEffect(() => {
    if (open) {
      if (document.querySelector('.chat-item:last-child')) {
        document.querySelector('.chat-item:last-child').scrollIntoView();
      }
    }
  }, [open, chat]);

  useEffect(() => {
    let newChat = [...chat];
    switch (step) {
      case 1:
        newChat.push({
          mensaje:
            'Hola! Soy *TerraBot* , el asistente virtual de Terraloteos. Para brindarte una atención personalizada podrías indicarnos tu nombre completo?',
          fecha: new Date().toLocaleDateString(),
          hora: new Date().toLocaleTimeString(),
          emisor: '🤖 TerraBot',
          nuevo: true,
          externo: true,
        });
        setChat(newChat);
        break;

      case 2:
        newChat.push({
          mensaje: 'Gracias! Podrías indicarnos ahora tu email?',
          fecha: new Date().toLocaleDateString(),
          hora: new Date().toLocaleTimeString(),
          emisor: '🤖 TerraBot',
          nuevo: true,
          externo: true,
        });
        setChat(newChat);
        break;
      case 3:
        newChat.push({
          mensaje: `Gracias ${cliente.nombre}, por último podrías indicarnos tu número de teléfono?`,
          fecha: new Date().toLocaleDateString(),
          hora: new Date().toLocaleTimeString(),
          emisor: '🤖 TerraBot',
          nuevo: true,
          externo: true,
        });
        setChat(newChat);
        break;
      case 4:
        newChat.push({
          mensaje: `Todo listo, en que podemos ayudarte?`,
          fecha: new Date().toLocaleDateString(),
          hora: new Date().toLocaleTimeString(),
          emisor: '🤖 TerraBot',
          nuevo: true,
          externo: true,
        });
        setChat(newChat);
        break;
      case 5:
        let consulta = JSON.parse(localStorage.getItem('consulta'));
        if (!consulta) {
          sendNuevaConsulta();
        }
        break;
    }
  }, [step]);

  useEffect(() => {
    if (cliente) {
      localStorage.setItem('cliente', JSON.stringify(cliente));
    }
  }, [cliente]);

  const enviarMensaje = async () => {
    let newChat = [...chat];
    if (chat.filter((item) => item.nuevo).length > 0) {
      newChat = newChat.map((item) => {
        if (item.nuevo) {
          return { ...item, nuevo: false };
        } else {
          return item;
        }
      });
    }
    if (step < 5) {
      newChat.push({
        mensaje: message,
        fecha: new Date().toLocaleDateString(),
        hora: new Date().toLocaleTimeString(),
        emisor: cliente?.nombre || 'Yo',
        nuevo: false,
        externo: false,
      });
      setChat(newChat);
    }
    if (step === 1) {
      setChatExistente(false);
      setCliente({ ...cliente, nombre: message });
      setMessage('');
      setStep(2);
    } else if (step === 2) {
      setCliente({ ...cliente, email: message });

      setMessage('');
      setStep(3);
    } else if (step === 3) {
      setCliente({ ...cliente, telefono: message });
      setMessage('');
      setStep(4);
    } else if (step === 4) {
      setCliente({ ...cliente, consulta: message });
      setMessage('');
      setStep(5);
    } else if (step >= 5) {
      try {
        await sendMessage({
          variables: {
            name: cliente?.nombre,
            text: message,
            idChat: JSON.parse(localStorage.getItem('idChat')),
          },
        });
      } catch (error) {
        console.log(error);
      }
      setMessage('');
    }
  };
  const handleNuevoChat = () => {
    localStorage.removeItem('chat');
    localStorage.removeItem('consulta');
    localStorage.removeItem('idChat');
    localStorage.removeItem('cliente');
    setChatExistente(false);
    setChat([]);
    setStep(1);
  };
  const handleContinuarChat = () => {
    const localChat = JSON.parse(localStorage.getItem('chat'));
    if (localChat) {
      setChat(localChat);
      setCliente(JSON.parse(localStorage.getItem('cliente')));
      setChatExistente(false);
      setStep(6);
    }
  };

  const renderMessage = (mensaje) => {
    const words = mensaje.split(' ');
    const newWords = words.map((word) => {
      if (word[0] === '*' && word[word.length - 1] === '*') {
        return <span>{word.slice(1, word.length - 1)}</span>;
      } else {
        return word + ' ';
      }
    });
    return <p>{newWords}</p>;
  };

  return (
    <div
      style={{
        position: 'fixed',
        bottom: '0',
        right: window.innerWidth < 768 ? '0' : '3rem',
        width: window.innerWidth < 768 ? '100%' : '25vw',
        maxWidth: window.innerWidth < 768 ? '100%' : '25vw',
        zIndex: '9',
        backgroundColor: '#fff',
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px',
      }}
    >
      <ModalHeader
        className='modal-header'
        onClick={toggleOpen}
        style={{
          cursor: 'pointer',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          Chat
          <Fade in={chat?.filter((item) => item.nuevo).length > 0}>
            <a className='chat-counter'>
              {chat.length > 0 && chat.filter((item) => item.nuevo).length}{' '}
              nuevo
            </a>
          </Fade>
          <a className='btn-close'>
            <i
              className={open ? 'fas fa-chevron-down' : 'fas fa-chevron-up'}
              onClick={toggleOpen}
            ></i>
          </a>
        </div>
      </ModalHeader>
      <ModalBody
        className='container'
        style={{
          maxHeight: open ? '50vh' : '0',
          padding: open ? null : '0',
          overflow: 'auto',
          transition: 'max-height 0.5s ease-in-out',
        }}
      >
        <div className='pt-2 pb-4'>
          {localStorage.getItem('chat') && chatExistente ? (
            <div className='d-flex justify-content-center align-items-center flex-column'>
              <h5 className='text-center mb-3'>
                ¡Hola! Soy 🤖<span className='orange'>TerraBot</span> , el
                asistente virtual de Terraloteos. Ya existe un chat guardado.
                ¿Que desea hacer?
              </h5>
              <a className='primary-btn mb-3' onClick={handleNuevoChat}>
                Iniciar nuevo chat
              </a>
              <a className='primary-btn mb-3' onClick={handleContinuarChat}>
                Continuar chat
              </a>
            </div>
          ) : (
            <ChatContainer chat={chat} setChat={setChat} />
          )}
        </div>
      </ModalBody>
      <div
        className='chat-input'
        style={{
          maxHeight: open ? '20vh' : '0',
          padding: open ? null : '0',
        }}
      >
        <input
          type='text'
          placeholder='Escribenos un mensaje...'
          className='mr-3'
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDownCapture={(e) =>
            e.key === 'Enter' && message.length > 0 && enviarMensaje()
          }
        />
        <a className='primary-btn' onClick={enviarMensaje}>
          Enviar
        </a>
      </div>
    </div>
  );
}

export default Chat;
