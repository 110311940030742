import React from "react";
import Proyecto from "../../layouts/Proyecto";
import Navbar from "../../components/Navbar";
import NavbarResponsive from "../../components/NavbarResponsive";

function PuebloMio() {
  var data = {
    image: "pueblo-mio-hero",
    nombre: "Pueblo Mío",
    descripcion:
      "Situado frente al Chorro de San Ignacio, este loteo está pensado para aquellos que buscan espacios amplios con naturaleza autoctona, con vista privilegiada de las Sierras de los Comechingones.",
    items: {
      item1: ["fas fa-map-pin", "Villa Larca, San Luis", "Ruta N°1 Km 33"],
      item2: [
        "fas fa-expand-arrows-alt",
        "55 Hectareas",
        "Divididas en 250 lotes",
      ],
      item3: ["fas fa-water", "Chorro San Ignacio", "Ubicado justo en frente"],
      item4: ["fas fa-tint", "Servicios", "Luz y Agua Potable"],
    },
    servicios: [
      "Agua Potable",
      "Luz",
      "Alumbrado de Calles",
      "Calles terminadas",
    ],
    galeria: [
      "https://terraloteos.com/img/proyectos/Pueblo%20Mio/pueblo-mio-g1.jpg",
      "https://terraloteos.com/img/proyectos/Pueblo%20Mio/pueblo-mio-g2.jpg",
      "https://terraloteos.com/img/proyectos/Pueblo%20Mio/pueblo-mio-g3.jpg",
      "https://terraloteos.com/img/proyectos/Pueblo%20Mio/pueblo-mio-g4.jpg",
      "https://terraloteos.com/img/proyectos/Pueblo%20Mio/pueblo-mio-g5.jpg",
    ],
    plano:
      "https://terraloteos.com/img/proyectos/Pueblo%20Mio/pueblo-mio-plano.jpg",
    mapa: "!1m14!1m8!1m3!1d13440.264078015085!2d-64.9795178!3d-32.6310653!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x914160c1747799ee!2sPueblo%20Mio!5e0!3m2!1ses!2sar!4v1646683152349!5m2!1ses!2sar",
    puntos: {
      punto1: [
        "fas fa-pin",
        "Ubicación Privilegiada",
        "Situado en Villa Larca, provincia de San Luis, brinda amplios espacios rodeados de naturaleza con vistas increíbles, sin dejar de lado la comodidad.",
        "https://terraloteos.com/img/proyectos/Pueblo%20Mio/pueblo-mio-item1.jpg",
      ],
      punto2: [
        "fas fa-arrows-expand",
        "Conexión natural",
        "Un ambiente rodeado de naturaleza, situado justo en frente del atractivo turístico 'Chorro de San Ignacio', entre grandes terrenos de fauna natural con vistas privilegiadas.",
        "https://terraloteos.com/img/proyectos/Pueblo%20Mio/pueblo-mio-item2.jpg",
      ],
      punto3: [
        "fas fa-arrow-left",
        "Todos los servicios y comodidades",
        "El loteo cuenta con todos los servicios necesarios para llevar una vida tranquila y con todas las comodidades rodeado de la naturaleza. Cuenta con agua potable, luz, alumbrado de calles y calles terminadas.",
        "https://terraloteos.com/img/proyectos/Pueblo%20Mio/pueblo-mio-item3.jpg",
      ],
    },
  };

  const setNavbar = () => {
    if (window.screen.width < 800) {
      return <NavbarResponsive />;
    } else {
      return <Navbar active={3} />;
    }
  };

  return (
    <>
      {setNavbar()}
      <Proyecto data={data}></Proyecto>
    </>
  );
}

export default PuebloMio;
