import React, { useState } from "react";
// import { useParams } from "react-router-dom";
import Navbar from "../components/Navbar";
import NavbarResponsive from "../components/NavbarResponsive";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToHTML } from "draft-convert";
import fire from "../config/Fire";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, addDoc, collection } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { Input, Row, Col } from "reactstrap";

function NewBlog() {
  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (!user) {
      window.location.href = "/login";
    }
  });

  const db = getFirestore(fire);
  const storage = getStorage();

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  //Estado donde se almacenan las categorias
  // const [categorias, setCategorias] = useState([]);

  // const { blogUrl } = useParams();

  const setNavbar = () => {
    if (window.screen.width < 800) {
      return <NavbarResponsive />;
    } else {
      return <Navbar active={9} />;
    }
  };

  const saveData = async () => {
    const loader = document.getElementsByClassName("loader");
    loader[0].style.display = "block";
    const title = document.getElementById("titleBlog").value;
    var urlBlog = document.getElementById("urlBlog").value;
    urlBlog = urlBlog.replace(/ /g, "-");
    const categoria = document.getElementById("categoriaSelect").value;
    const cuerpo = convertToHTML(editorState.getCurrentContent());
    const descripcion = cuerpo.replace(/<[^>]*>/g, "");
    const img = document.getElementById("imgPortada").files[0];
    const fecha = new Date();
    const fechaActual = fecha.toLocaleDateString();

    const storageRef = ref(storage, "blog/" + img.name);
    await uploadBytes(storageRef, img).then((snapshot) => {
      getDownloadURL(ref(storage, storageRef))
        .then((url) => {
          try {
            const docRef = addDoc(collection(db, "blogs"), {
              contenido: cuerpo,
              descripcion: descripcion,
              img_portada: url,
              categoria: categoria,
              title: title,
              url: urlBlog,
              fecha: fechaActual,
            });

            console.log("Document written with ID: ", docRef.id);
          } catch (e) {
            console.error("Error adding document: ", e);
          }
        })
        .catch((error) => {
          alert(error);
        });
    });
    loader[0].style.display = "none";
    alert("Blog guardado con éxito");
  };

  const updateTextDescription = async (state) => {
    await setEditorState(state);
  };

  const updateUrl = () => {
    var title = document.getElementById("titleBlog").value;
    var urlBlog = title.replace(/ /g, "-");

    document.getElementById("urlBlog").value = urlBlog;
  };

  return (
    <body>
      {setNavbar()}
      <div className="search-area">
        <div className="container">
          <div className="row">
            <input type="hidden" value={0} id="loader-status" />
            <div className="col-lg-12">
              <span className="close-btn">
                <i className="fas fa-times" />
              </span>
              <div className="search-bar">
                <div className="search-bar-tablecell">
                  <a href="/">
                    <h3>Home</h3>
                  </a>
                  <a href="/#nosotros" className="close-btn">
                    <h3>Nosotros</h3>
                  </a>
                  <a href="/oficinas">
                    <h3>Oficinas</h3>
                  </a>
                  <a href="/proyectos">
                    <h3>Proyectos</h3>
                  </a>
                  <a href="/casas">
                    <h3>Casas</h3>
                  </a>
                  <a href="/blog">
                    <h3>Blog</h3>
                  </a>
                  <a href="/clientes">
                      <h3>Clientes</h3>
                    </a>
                  <button type="submit" className="close-btn" id="close-btn">
                    Volver <i className="fas fa-arrow-up" />
                  </button>
                  <a href="/login">
                    <h5>Ingresar</h5>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="list-section pt-100"></div>

      <div className="latest-news pb-150 fondo-textura4" id="blog">
        <div className="container">
          <Row className="pb-3 mb-3">
            <a href="#" className="boxed-btn">
              Entradas anteriores
            </a>
          </Row>
          <div className="col-lg-8 offset-lg-2 text-center">
            <div className="section-title">
              <h3>
                Nueva<span className="orange-text"> Entrada</span>
              </h3>
            </div>
          </div>
          <Row className="justify-content-center">
            <Col md="5 pr-1">
              <label>Título</label>
              <Input
                defaultValue=""
                placeholder="Escriba aquí el título"
                type="text"
                id="titleBlog"
                className="blog-input"
                onChange={updateUrl}
              />
            </Col>
            <Col md="5 pl-1">
              <label>Categoría</label>
              <Input
                defaultValue=""
                placeholder="Escriba aquí la categoría"
                type="text"
                id="categoriaSelect"
                className="blog-input"
              />
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Col md="10">
              <Editor
                editorState={editorState}
                toolbarClassName="editorToolbar"
                wrapperClassName="wrapperClassName"
                editorClassName="editorTextBox"
                onEditorStateChange={updateTextDescription}
              />
            </Col>
          </Row>

          <Row className="justify-content-center mt-3">
            <Col md="5 pr-1">
              <label htmlFor="imgPortada">
                Portada - Recomendado 1920x1280
              </label>
              <Input
                defaultValue=""
                placeholder="Título"
                type="file"
                name="imgPortada"
                id="imgPortada"
                className="blog-file"
                accept="image/png, image/jpeg"
              />
            </Col>
            <Col md="5 pl-1">
              <label>URL</label>
              <Input
                defaultValue=""
                placeholder="Escribe aqui una URL (opcional)"
                type="text"
                id="urlBlog"
                className="blog-input"
              />
              {/* <label htmlFor="imgGaleria">Galería (max 5 imágenes) - Recomendado 1280x720</label>
              <Input
                defaultValue=""
                placeholder="Título"
                type="file"
                name="imgGaleria"
                id="imgGaleria"
                className="blog-file"
                multiple="true"
                accept="image/png, image/jpeg"
              /> */}
            </Col>
          </Row>
          <div className="text-center">
            <a className="boxed-btn" onClick={saveData}>
              Guardar
            </a>
          </div>
        </div>
      </div>
    </body>
  );
}

export default NewBlog;
