import React from "react";
import Proyecto from "../../layouts/Proyecto";
import Navbar from "../../components/Navbar";
import NavbarResponsive from "../../components/NavbarResponsive";

function MerloVillage() {
  var data = {
    image: "merlo-village-hero",
    nombre: "Merlo Village",
    descripcion:
      "Con una ubicación privilegiada, a 5 min del ingreso a Villa de Merlo, este emprendimiento pensado en la vida natural y urbana cuenta con amplios espacios verdes y un extenso centro comercial.",
    items: {
      item1: ["fas fa-map-pin", "Merlo, San Luis", "Ruta N°5 Km 2"],
      item2: [
        "fas fa-expand-arrows-alt",
        "98 Hectareas",
        "Divididas en 744 lotes",
      ],
      item3: ["fas fa-city", "A 5 min del centro", "Villa de Merlo"],
      item4: ["fas fa-tint", "Servicios", "Luz y Agua Potable"],
    },
    servicios: [
      "Agua Potable",
      "Luz",
      "Alumbrado de Calles",
      "Calles terminadas",
    ],
    galeria: [
      "https://terraloteos.com/img/proyectos/Merlo%20Village/merlo-village-g1.jpg",
      "https://terraloteos.com/img/proyectos/Merlo%20Village/merlo-village-g2.jpg",
      "https://terraloteos.com/img/proyectos/Merlo%20Village/merlo-village-g3.jpg",
      "https://terraloteos.com/img/proyectos/Merlo%20Village/merlo-village-g4.jpg",
      "https://terraloteos.com/img/proyectos/Merlo%20Village/merlo-village-g5.jpg",
    ],
    plano:
      "https://terraloteos.com/img/proyectos/Merlo%20Village/merlo-village-plano.jpg",
    mapa: "!1m18!1m12!1m3!1d3370.656014905537!2d-65.0309200844712!3d-32.34794298110367!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95d2e1910361d771%3A0x9e8d1fb1c3d73cb1!2sMerlo%20Village!5e0!3m2!1ses!2sar!4v1646684747531!5m2!1ses!2sar",
    puntos: {
      punto1: [
        "fas fa-pin",
        "Ubicación Privilegiada",
        "Situado a solo 5 minutos de Merlo, este emprendimiento le permite llevar una vida tranquila y relajada en un espacio natural sin dejar de lado las ventajas de la vida urbana.",
        "../img/proyectos/Merlo%20Village/merlo-village-item1.jpg",
      ],
      punto2: [
        "fas fa-arrows-expand",
        "Conexión natural",
        "Rodeado de naturaleza y con unas vistas increíbles de las Sierras de los Comechingones, este emprendimiento cuenta con amplios espacios verdes y vegetación autóctona.",
        "https://terraloteos.com/img/proyectos/Merlo%20Village/merlo-village-item2.jpg",
      ],
      punto3: [
        "fas fa-arrow-left",
        "Todos los servicios y comodidades",
        "El loteo cuenta con todos los servicios necesarios para llevar una vida tranquila y con todas las comodidades rodeado de la naturaleza. Cuenta con alumbrado de calles y calles terminadas.",
        "https://terraloteos.com/img/proyectos/Merlo%20Village/merlo-village-item3.jpg",
      ],
    },
  };

  const setNavbar = () => {
    if (window.screen.width < 800) {
      return <NavbarResponsive />;
    } else {
      return <Navbar active={3} />;
    }
  };

  return (
    <>
      {setNavbar()}
      <Proyecto data={data}></Proyecto>
    </>
  );
}

export default MerloVillage;
