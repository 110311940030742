import React, { useState } from "react";
import Navbar from "../components/Navbar";
import NavbarResponsive from "../components/NavbarResponsive";
import Contact from "../components/Contact";

function Casas() {
  const setNavbar = () => {
    if (window.screen.width < 800) {
      return <NavbarResponsive />;
    } else {
      return <Navbar active={2} />;
    }
  };

  return (
    <div>
      {setNavbar()}
      <body>
        <div className="search-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <span className="close-btn"><i className="fas fa-times" /></span>
                <div className="search-bar">
                  <div className="search-bar-tablecell">
                    <a href="/"><h3>Home</h3></a>
                    <a href="/asesores" className="close-btn"><h3>Asesores</h3></a>
                    <a href="/fundacion"><h3>Fundación</h3></a>
                    <a href="/oficinas"><h3>Oficinas</h3></a>
                    <a href="/proyectos"><h3>Proyectos</h3></a>
                    <a href="/casas"><h3>Casas</h3></a>
                    <a href="/blog"><h3>Blog</h3></a>
                    <a href="/clientes">
                      <h3>Clientes</h3>
                    </a>
                    <button type="submit" className="close-btn" id="close-btn">Volver <i className="fas fa-arrow-up" /></button>
                    <a href="/login"><h5>Ingresar</h5></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section
          className="casas-hero"
          style={{
            backgroundImage: `linear-gradient(rgba(19, 50, 34, 0.7),rgba(19, 50, 34, 0.7)) , url(https://terraloteos.com/img/oficinas/oficina-hero.jpg)`,
          }}
        >
          <div className="container text-center">
            <h3>
              Conocé nuestras
              <br />
              <span className="orange-text">Oficinas</span>
            </h3>
            <a href="#oficinas" className="btn-bajar btn-lg">
              <i className="fas fa-arrow-down mr-0" />
            </a>
          </div>
        </section>

        <div className="latest-news pt-150 pb-150 fondo-textura" id="oficinas">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2 text-center">
                <div className="section-title">
                  <h3>
                    Nuestras<span className="orange-text"> Oficinas</span>
                  </h3>
                  <p>
                    Te esperamos en nuestras oficinas, para brindarte la mejor
                    atención y asesorarte para encontrar el hogar de tus sueños!
                  </p>
                </div>
              </div>
            </div>

            <div className="row">
            <div className="col-lg-4 col-md-6" id="sl">
                <div className="single-latest-news">
                  <a href="https://goo.gl/maps/NinbZZRy86AVUg1y9">
                    <div>
                      <div className="latest-news-bg oficina-vm2">
                      <a
                      href="https://goo.gl/maps/NinbZZRy86AVUg1y9"
                      target={"_blank"}
                      rel="noopener noreferrer"
                      className="new-badge"
                    > Nueva
                    </a>
                      </div>
                    </div>
                  </a>
                  <div className="news-text-box text-center">
                    <h3 className="mb-0">Villa Mercedes</h3>
                    <h4>San Luis</h4>
                    <hr />
                    <p className="product-price">
                      <span>Oficina comercial</span>
                      Ubicada en Av. Mitre N° 734
                    </p>
                    <a
                      href="https://goo.gl/maps/NinbZZRy86AVUg1y9"
                      target={"_blank"}
                      rel="noopener noreferrer"
                      className="cart-btn"
                    >
                      <i className="fas fa-map" /> Ver mapa
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6" id="merlo">
                <div className="single-latest-news">
                  <a href="https://goo.gl/maps/1djGzQzZccu6JxSS7">
                    <div className="latest-news-bg oficina-merlo1" />
                  </a>
                  <div className="news-text-box text-center">
                    <h3 className="mb-0">Villa de Merlo</h3>
                    <h4>San Luis</h4>
                    <hr />
                    <p className="product-price">
                      <span>Oficina comercial</span>
                      Ubicada en Av. del Sol N° 821
                    </p>
                    <a
                      href="https://goo.gl/maps/1djGzQzZccu6JxSS7"
                      target={"_blank"}
                      rel="noopener noreferrer"
                      className="cart-btn"
                    >
                      <i className="fas fa-map" /> Ver mapa
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6" id="bsas">
                <div className="single-latest-news">
                  <a href="https://goo.gl/maps/WGobb3Zr1jXGStwu7">
                    <div className="latest-news-bg oficina-bsas" />
                  </a>
                  <div className="news-text-box text-center">
                    <h3 className="mb-0">CABA</h3>
                    <h4>Buenos Aires</h4>
                    <hr />
                    <p className="product-price">
                      <span>Oficina comercial</span>
                      Av. Córdoba N° 679, Piso:2 Oficina:B
                    </p>
                    <a
                      href="https://goo.gl/maps/WGobb3Zr1jXGStwu7"
                      target={"_blank"}
                      rel="noopener noreferrer"
                      className="cart-btn"
                    >
                      <i className="fas fa-map" /> Ver mapa
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
            <div className="col-lg-4 col-md-6" id="vm">
                <div className="single-latest-news">
                  <a href="https://goo.gl/maps/NqGkKSrj2d5QcpH18">
                    <div className="latest-news-bg oficina-vm" />
                  </a>
                  <div className="news-text-box text-center">
                    <h3 className="mb-0">Villa Mercedes</h3>
                    <h4>San Luis</h4>
                    <hr />
                    <p className="product-price">
                      <span>Oficina comercial</span>
                      Ubicada en Av. Mitre N° 1260
                    </p>
                    <a
                      href="https://goo.gl/maps/NqGkKSrj2d5QcpH18"
                      target={"_blank"}
                      rel="noopener noreferrer"
                      className="cart-btn"
                    >
                      <i className="fas fa-map" /> Ver mapa
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6" id="sl">
                <div className="single-latest-news">
                  <a href="https://goo.gl/maps/xGDqyxpgMH2e4v4k7">
                    <div>
                      <div className="latest-news-bg oficina-sl" />
                    </div>
                  </a>
                  <div className="news-text-box text-center">
                    <h3 className="mb-0">Ciudad de San Luis</h3>
                    <h4>San Luis</h4>
                    <hr />
                    <p className="product-price">
                      <span>Oficina comercial</span>
                      Ubicada en Gral. Paz N° 956
                    </p>
                    <a
                      href="https://goo.gl/maps/xGDqyxpgMH2e4v4k7"
                      target={"_blank"}
                      rel="noopener noreferrer"
                      className="cart-btn"
                    >
                      <i className="fas fa-map" /> Ver mapa
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6" id="rc">
                <div className="single-latest-news">
                  <a href="https://goo.gl/maps/6mxXrCjCdZBdE1v97">
                    <div className="latest-news-bg oficina-rc" />
                  </a>
                  <div className="news-text-box text-center">
                    <h3 className="mb-0">Rio Cuarto</h3>
                    <h4>Córdoba</h4>
                    <hr />
                    <p className="product-price">
                      <span>Oficina comercial</span>
                      Ubicada en Alberdi N° 717
                    </p>
                    <a
                      href="https://goo.gl/maps/6mxXrCjCdZBdE1v97"
                      target={"_blank"}
                      rel="noopener noreferrer"
                      className="cart-btn"
                    >
                      <i className="fas fa-map" /> Ver mapa
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="single-latest-news">
                  <a href="https://goo.gl/maps/dfVf2ZQ8d5Cas3Tq7">
                    <div className="latest-news-bg oficina-merlo2" />
                  </a>
                  <div className="news-text-box text-center">
                    <h3 className="mb-0">Villa de Merlo</h3>
                    <h4>San Luis</h4>
                    <hr />
                    <p className="product-price">
                      <span>Oficina comercial</span>
                      Ubicada en Av. del Sol N° 351
                    </p>
                    <a
                      href="https://goo.gl/maps/dfVf2ZQ8d5Cas3Tq7"
                      target={"_blank"}
                      rel="noopener noreferrer"
                      className="cart-btn"
                    >
                      <i className="fas fa-map" /> Ver mapa
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="list-section pt-80 pb-80" id="contacto">
          <div className="container">
            <div className="row text-center">
              <div className="col-lg-12">
                <div className="section-title mb-0">
                  <h3 className="text-white">
                    Comunicate con <span className="orange-text">Nosotros</span>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Contact></Contact>
      </body>
    </div>
  );
}

export default Casas;
