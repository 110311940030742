import React from "react";

function SliderHome() {

    return(

        <div className="homepage-slider">
          <div className="single-homepage-slider homepage-bg-1">
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-lg-7 offset-lg-1 offset-xl-0">
                  <div className="hero-text">
                    <div className="hero-text-tablecell">
                      <p className="subtitle">Alegría que llena el alma</p>
                      <h1>Volvé a enamorarte de la vida</h1>
                      <div className="hero-btns">
                        <a href="#desarrollos" className="boxed-btn">Ver proyectos</a>
                        <a href="#contacto" className="bordered-btn">Contactanos</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="single-homepage-slider homepage-bg-2">
            <div className="container">
              <div className="row">
                <div className="col-lg-10 offset-lg-1 text-center">
                  <div className="hero-text">
                    <div className="hero-text-tablecell">
                      <p className="subtitle">Mejorá tu calidad de vida</p>
                      <h1>Conectados con la naturaleza</h1>
                      <div className="hero-btns">
                        <a href="#desarrollos" className="boxed-btn">Ver desarrollos</a>
                        <a href="#contacto" className="bordered-btn">Contactanos</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="single-homepage-slider homepage-bg-3">
            <div className="container">
              <div className="row">
                <div className="col-lg-10 offset-lg-1 text-right">
                  <div className="hero-text">
                    <div className="hero-text-tablecell">
                      <p className="subtitle">Viví rodeado de paisajes</p>
                      <h1>La emoción de haber cumplido un sueño</h1>
                      <div className="hero-btns">
                        <a href="#casas" className="boxed-btn">Ver casas</a>
                        <a href="#contacto" className="bordered-btn">Contactanos</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

    )

}

export default SliderHome;