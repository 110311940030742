import React from "react";
import {
  getAuth,
  setPersistence,
  onAuthStateChanged,
  browserSessionPersistence,
  signOut,
} from "firebase/auth";

function Navbar(props) {
  const active = props.active;
  const navItems = [];
  const auth = getAuth();

  const cerrarSesion = () => {
    signOut(auth)
      .then(() => {
        console.log("Cerro sesion");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const setUserIcon = () => {
    if (active === 9) {
      return (
        <>
          <a onClick={cerrarSesion} id="login-btn">
            <i className="fas fa-door-open"></i>
          </a>
        </>
      );
    } else {
      return (
        <a href="/login" id="login-btn">
          <i className="fas fa-user"></i>
        </a>
      );
    }
  };

  const setActive = () => {
    switch (active) {
      case 0:
        navItems[0] = "current-list-item";
        break;
      case 1:
        navItems[1] = "current-list-item";
        break;
      case 2:
        navItems[2] = "current-list-item";
        break;
      case 3:
        navItems[3] = "current-list-item";
        break;
      case 4:
        navItems[4] = "current-list-item";
        break;
      case 5:
        navItems[5] = "current-list-item";
        break;
      case 6:
        navItems[6] = "current-list-item";
        break;
      case 7:
        navItems[7] = "current-list-item";
        break;
    }
  };

  return (
    <div className="top-header-area" id="sticker">
      {setActive()}
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-sm-12 text-center">
            <div className="main-menu-wrap">
              <div className="site-logo">
                <a href="/">
                  <img src="https://terraloteos.com/img/logo.png" alt="" />
                </a>
              </div>
              <nav className="main-menu">
                <ul>
                  <li id="nav-home" className={navItems[0]}>
                    <a href="/">Home</a>
                  </li>
                  <li id="nav-nosotros" className={navItems[1]}>
                    <a href="/asesores">Asesores</a>
                  </li>
                  <li id="nav-nosotros" className={navItems[7]}>
                    <a href="/fundacion">Fundación</a>
                  </li>
                  <li id="nav-oficinas" className={navItems[2]}>
                    <a href="/oficinas">Oficinas</a>
                    <ul className="sub-menu">
                      <li>
                        <a href="/oficinas/#vm">Villa Mercedes</a>
                      </li>
                      <li>
                        <a href="/oficinas/#bsas">Buenos Aires</a>
                      </li>
                      <li>
                        <a href="/oficinas/#sl">San Luis Capital</a>
                      </li>
                      <li>
                        <a href="/oficinas/#merlo">Merlo</a>
                      </li>
                      <li>
                        <a href="/oficinas/#rc">Rio Cuarto</a>
                      </li>
                    </ul>
                  </li>
                  <li id="nav-proyectos" className={navItems[3]}>
                    <a href="/proyectos">Proyectos</a>
                    <ul className="sub-menu">
                      <li>
                        <a href="/proyectos/ayres-de-larca">Ayres de Larca</a>
                      </li>
                      <li>
                        <a href="/proyectos/estacion-conlara">
                          Estación Conlara
                        </a>
                      </li>
                      <li>
                        <a href="/proyectos/merlo-village">Merlo Village</a>
                      </li>
                      <li>
                        <a href="/proyectos/valle-de-los-comechingones">
                          Valle de los Comechingones
                        </a>
                      </li>
                      <li>
                        <a href="/proyectos/miralba">Miralba</a>
                      </li>
                      <li>
                        <a href="/proyectos/balcones-de-san-miguel">
                          Balcones de San Miguel II
                        </a>
                      </li>
                      <li>
                        <a href="/proyectos/pueblo-mio">Pueblo Mío</a>
                      </li>
                      <li>
                        <a href="/proyectos/terrazas-del-talar">
                          Terrazas del Talar
                        </a>
                      </li>
                      <li>
                        <a href="/proyectos/estacion-del-valle">
                          Estación del Valle
                        </a>
                      </li>
                      <li>
                        <a href="/proyectos/los-alamos">
                          Los Alamos
                        </a>
                      </li>
                      <li>
                        <a href="/proyectos/las-chacras">
                          Las Chacras
                        </a>
                      </li>
                      <li>
                        <a href="/proyectos/palmares-de-balcarce">
                          Palmares de Balcarce
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li id="nav-casas" className={navItems[4]}>
                    <a href="/casas">Casas</a>
                    <ul className="sub-menu">
                      <li>
                        <a href="/casas/loft">Loft</a>
                      </li>
                      <li>
                        <a href="/casas/loft-A1">Loft A1</a>
                      </li>
                      <li>
                        <a href="/casas/loft-A2">Loft A2</a>
                      </li>
                      <li>
                        <a href="/casas/tipologia-A">Tipología A</a>
                      </li>
                      <li>
                        <a href="/casas/tipologia-B">Tipología B</a>
                      </li>
                      <li>
                        <a href="/casas/tipologia-B3">Tipología B3</a>
                      </li>
                      <li>
                        <a href="/casas/tipologia-C">Tipología C</a>
                      </li>
                      <li>
                        <a href="/casas/tipologia-D">Tipología D</a>
                      </li>
                      <li>
                        <a href="/casas/tipologia-D1">Tipología D1</a>
                      </li>
                      <li>
                        <a href="/casas/tipologia-E">Tipología E</a>
                      </li>
                      <li>
                        <a href="/casas/tipologia-F">Tipología F</a>
                      </li>
                      <li>
                        <a href="/casas/tipologia-H">Tipología H</a>
                      </li>
                    </ul>
                  </li>
                  <li id="nav-contacto" className={navItems[5]}>
                    <a href="/clientes">Clientes</a>
                  </li>
                  <li id="nav-blog" className={navItems[6]}>
                    <a href="/blog">Blog</a>
                  </li>
                  <li>
                    <div className="header-icons">{setUserIcon()}</div>
                  </li>
                </ul>
              </nav>
              <div className="mobile-menu" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
