import React from "react";

function BlogItemSingle(props) {
    const blog = props.blog;
    const shortDesc = blog.descripcion.substr(0, 147)+'...';

    return (
      <div className="col-lg-4 col-md-6">
          <div className="single-latest-news">
            <a href={"/blog/"+blog.url}>
              <div className="latest-news-bg" style={{backgroundImage: "url("+blog.img_portada+")" }} />
            </a>
            <div className="news-text-box">
              <h3><a href={"/blog/"+blog.url}>{blog.title}</a></h3>
              <p className="blog-meta">
                <span className="author"><i className="fas fa-map-marker-alt" />{blog.fecha}</span>
                <span className="date"><i className="fas fa-tag" />{blog.categoria}</span>
              </p>
              <p className="excerpt">{shortDesc}</p>
              <a href={"/blog/"+blog.url} className="read-more-btn">Seguir leyendo <i className="fas fa-angle-right" /></a>
            </div>
          </div>
      </div>
    );
}

export default BlogItemSingle;
