import React from "react";
import Proyecto from "../../layouts/Proyecto";
import Navbar from "../../components/Navbar";
import NavbarResponsive from "../../components/NavbarResponsive";

function TerrazasDelTalar() {
  var data = {
    image: "terrazas-del-talar-hero",
    nombre: "Terrazas del Talar",
    descripcion:
      "Terrazas del Talar es un loteo exclusivo que nos propone una experiencia única en conjunto con la naturaleza, garantizando comodidad, bienestar y calidad de vida para quienes deciden invertir.",
    items: {
      item1: ["fas fa-map-pin", "Los Molles, San Luis", "Ruta N°1 Km 13"],
      item2: [
        "fas fa-expand-arrows-alt",
        "112 Hectareas",
        "Divididas en 86 lotes",
      ],
      item3: ["fas fa-tree", "Reserva Natural", "La primera de la provincia"],
      item4: ["fas fa-tint", "Servicios", "Luz y Agua Potable"],
    },
    servicios: [
      "Agua Potable",
      "Luz",
      "Alumbrado de Calles",
      "Calles terminadas",
    ],
    galeria: [
      "https://terraloteos.com/img/proyectos/Terrazas%20del%20Talar/terrazas-del-talar-g1.jpg",
      "https://terraloteos.com/img/proyectos/Terrazas%20del%20Talar/terrazas-del-talar-g2.jpg",
      "https://terraloteos.com/img/proyectos/Terrazas%20del%20Talar/terrazas-del-talar-g3.jpg",
      "https://terraloteos.com/img/proyectos/Terrazas%20del%20Talar/terrazas-del-talar-g4.jpg",
      "https://terraloteos.com/img/proyectos/Terrazas%20del%20Talar/terrazas-del-talar-g5.jpg",
    ],
    plano:
      "https://terraloteos.com/img/proyectos/Terrazas%20del%20Talar/terrazas-del-talar-plano.jpg",
    mapa: "!1m18!1m12!1m3!1d3366.8826811952003!2d-64.99504008482623!3d-32.44907488107225!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95d2fbf630babee9%3A0xd048d6f3b4840e4f!2sTerrazas%20del%20Talar!5e0!3m2!1ses!2sar!4v1646413664862!5m2!1ses!2sar",
    puntos: {
      punto1: [
        "fas fa-pin",
        "Ubicación Privilegiada",
        "Situado en la avenida de acceso a el Talar, en la localidad de Los Molles, ofrece una ubicación ideal para disfrutar del paisaje de la Sierra de los Comechingones.",
        "../img/proyectos/Terrazas%20del%20Talar/terrazas-del-talar-item1.jpg",
      ],
      punto2: [
        "fas fa-arrows-expand",
        "Conexión natural",
        "Cuenta con 112 hectareas que conforman la primera reserva natural privada protegidad de la provincia, brindando un paisaje y un ambiente ideal para vivir.",
        "https://terraloteos.com/img/proyectos/Terrazas%20del%20Talar/terrazas-del-talar-item2.jpg",
      ],
      punto3: [
        "fas fa-arrow-left",
        "Todos los servicios y comodidades",
        "El loteo cuenta con todos los servicios necesarios para llevar una vida tranquila y con todas las comodidades rodeado de la naturaleza. Cuenta con agua potable, luz, alumbrado de calles y calles terminadas.",
        "https://terraloteos.com/img/proyectos/Terrazas%20del%20Talar/terrazas-del-talar-item3.jpg",
      ],
    },
  };

  const setNavbar = () => {
    if (window.screen.width < 800) {
      return <NavbarResponsive />;
    } else {
      return <Navbar active={3} />;
    }
  };

  return (
    <>
      {setNavbar()}
      <Proyecto data={data}></Proyecto>
    </>
  );
}

export default TerrazasDelTalar;
