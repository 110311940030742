import { useEffect, useState } from "react";
import { Fade } from "reactstrap";

function ChatItem({ mensaje, fecha, hora, emisor, nuevo, handleHover, externo = true }) {
  const [newMessage, setNewMessage] = useState("");

  useEffect(() => {
    if (mensaje && !externo) {
      setNewMessage(mensaje);
    } else if (mensaje && externo) {
      let i = 0;
      const interval = setInterval(() => {
        if (i < mensaje.length) {
          setNewMessage((prev) => prev + mensaje[i]);
          i++;
        } else {
          clearInterval(interval);
        }
      }, 30);
      return () => clearInterval(interval);
    }
  }, [mensaje]);

  const renderMessage = (mensaje) => {
    const words = mensaje.split(" ");
    const newWords = words.map((word) => {
      if (word[0] === "*" && word[word.length - 1] === "*") {
        return <span>{word.slice(1, word.length - 1)}</span>;
      } else {
        return word + " ";
      }
    });
    return <p>{newWords}</p>;
  };
  return (
    <div
      className={
        externo
          ? "chat-item"
          : "chat-item reverse"
      }
      onMouseEnter={handleHover}
    >
      {externo ? (
        <div className="chat-head">
          <h5>{emisor}</h5>
          <Fade in={nuevo ? nuevo : false}>
            <h5>
              <span>nuevo</span>
            </h5>
          </Fade>
        </div>
      ) : (
        <div className="chat-head-reverse">
          <h5>{emisor}</h5>
        </div>
      )}
      {mensaje && renderMessage(newMessage)}
      <p className="time">{fecha + " a las  " + hora}</p>
    </div>
  );
}

export default ChatItem;
