import React, { useState, useEffect } from "react";
import { Media, Input, Form } from "reactstrap";
import Navbar from "../components/Navbar";
import NavbarResponsive from "../components/NavbarResponsive";
import Contact from "../components/Contact";
import {casas} from "../data/casas.js";

function Casas() {
  const setNavbar = () => {
    if (window.screen.width < 800) {
      return <NavbarResponsive />;
    } else {
      return <Navbar active={4} />;
    }
  };  

  return (
    <div>
      {setNavbar()}
      <body>
        <div className="search-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <span className="close-btn">
                  <i className="fas fa-times" />
                </span>
                <div className="search-bar">
                  <div className="search-bar-tablecell">
                    <a href="/">
                      <h3>Home</h3>
                    </a>
                    <a href="/asesores" className="close-btn">
                      <h3>Asesores</h3>
                    </a>
                    <a href="/fundacion">
                      <h3>Fundación</h3>
                    </a>
                    <a href="/oficinas">
                      <h3>Oficinas</h3>
                    </a>
                    <a href="/proyectos">
                      <h3>Proyectos</h3>
                    </a>
                    <a href="/casas">
                      <h3>Casas</h3>
                    </a>
                    <a href="/blog">
                      <h3>Blog</h3>
                    </a>
                    <a href="/clientes">
                      <h3>Clientes</h3>
                    </a>
                    <button type="submit" className="close-btn" id="close-btn">
                      Volver <i className="fas fa-arrow-up" />
                    </button>
                    <a href="/login">
                      <h5>Ingresar</h5>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="casas-hero">
          <div className="container text-center">
            <h3>
              Tu casa ideal
              <br />
              <span className="orange-text">te está esperando</span>
            </h3>
            <a href="#oficinas" className="btn-bajar btn-lg">
              <i className="fas fa-arrow-down mr-0" />
            </a>
          </div>
        </section>

        <div
          className="latest-news pt-150 pb-150 fondo-textura"
          id="casas-title"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2 text-center">
                <div className="section-title">
                  <h3>
                    Nuestros<span className="orange-text"> Modelos</span>
                  </h3>
                  <p>
                    Cada casa está diseñada con el propósito de satisfacer todas
                    tus necesidades y brindarte la comodidad y el ambiente que
                    tanto buscas. Hay una casa para cada persona, encontrá el
                    modelo que más se adecue a vos
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              {casas.map((casa) => {
                return (
                  <div
                    className="col-lg-4 col-md-6"
                    style={{ marginBottom: "30px" }}
                  >
                    <div
                      className="single-latest-news"
                      style={{ height: "100%" }}
                    >
                      <a href={casa.link}>
                        <div
                          className="latest-news-bg"
                          style={{
                            backgroundImage: `url('/img/${casa.galeria[0]}')`,
                          }}
                        />
                      </a>
                      <div className="news-text-box text-center">
                        <h3>{casa.titulo}</h3>
                        <p className="blog-meta">
                          <span className="author">
                            <i className="fas fa-calendar" /> Disponible en{" "}
                            {casa.duracion}
                          </span>
                          <span className="date">
                            <i className="fas fa-bed" /> {casa.habitaciones}
                          </span>
                          <span className="date">
                            <i className="fas fa-arrows-alt" />{" "}
                            {casa.tamano_terreno}m2
                          </span>
                        </p>
                        <p className="product-price">
                          <span>{casa.resumen}</span>
                        </p>
                        <a href={casa.link} className="cart-btn">
                          <i className="fas fa-home" /> Ver más
                        </a>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="list-section pt-80 pb-80" id="contacto">
          <div className="container">
            <div className="row text-center">
              <div className="col-lg-12">
                <div className="section-title mb-0">
                  <h3 className="text-white">
                    Comunicate con <span className="orange-text">Nosotros</span>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Contact></Contact>
      </body>
    </div>
  );
}

export default Casas;
