import React from "react";

function Footer(props) {

    return(
        <><div className="footer-area pt-80 pb-30">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-6">
                        <div className="footer-box about-widget">
                            <h2 className="widget-title">Sobre nosotros</h2>
                            <p className="mb-0">Brindamos soluciones inmobiliarias con el objetivo de visualizar y capitalizar
                                oportunidades de negocios con crecimiento ordenado y sostenido hacia cada cliente.</p>
                            <div className="social-icons">
                                <ul>
                                    <li><a href="https://www.facebook.com/loteosterra" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i></a></li>
                                    <li><a href="https://www.instagram.com/terraloteos" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="footer-box pages">
                            <h2 className="widget-title">Contactanos</h2>
                            <ul>
                                <li><a href="/#">Oficina San Luis</a></li>
                                <li><a href="/#">Oficina Villa Mercedes</a></li>
                                <li><a href="/#">Oficina Buenos Aires</a></li>
                                <li><a href="/#">Oficina Merlo</a></li>
                                <li><a href="/#">Oficina Rio Cuarto</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="footer-box pages">
                            <h2 className="widget-title">Útiles</h2>
                            <ul>
                                <li><a href="/#">Débito Automático</a></li>
                                <li><a href="/#">Data Fiscal</a></li>
                                <li><a href="/#">Política de Cookies</a></li>
                                <li><a href="/#">Términos y Condiciones</a></li>
                                <li><a href="/#">Política de Privacidad</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="footer-box subscribe">
                            <h2 className="widget-title">Subscribite</h2>
                            <p>Suscribite a nuestro newsletter para recibir novedades y promociones.</p>
                            <form action="#">
                                <input type="email" placeholder="Email" />
                                <button type="submit"><i className="fas fa-paper-plane" /></button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="copyright">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-12">
                        <p>Creado por <a href="http://kaizensoftware.com.ar/" target={"_blank"} rel="noopener noreferrer">Kaizen Software Factory</a> &copy; 2024</p>
                    </div>
                    <div className="col-lg-6 text-right col-md-12">
                        <div className="social-icons">
                            <ul>
                                <li><a href="https://www.facebook.com/loteosterra" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i></a></li>
                                <li><a href="https://www.instagram.com/terraloteos" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )

}

export default Footer;