import React, {useState} from "react";

function Banner(props) {

    if(Object.keys(props.items).length == 4){
      return(

        <div className="list-section pt-80 pb-80" id="oficinas">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6 mb-4 mb-lg-0">
                <div className="list-box d-flex align-items-center">
                  <div className="list-icon2 centrado-horizontal">
                    <i className={props.items.item1[0]} />
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 mb-4 mb-lg-0">
                <div className="list-box d-flex align-items-center">
                  <div className="list-icon2 centrado-horizontal">
                    <i className={props.items.item2[0]} />
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="list-box d-flex justify-content-start align-items-center">
                  <div className="list-icon2 centrado-horizontal">
                    <i className={props.items.item3[0]} />
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="list-box d-flex justify-content-start align-items-center">
                  <div className="list-icon2 centrado-horizontal">
                    <i className={props.items.item4[0]} />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 col-md-6 mb-4 mb-lg-0">
                <div className="list-box d-flex align-items-center text-center centrado-horizontal">
                  <div className="content2 centrado-horizontal">
                    <a data-toggle="modal" data-target="#exampleModal">{props.items.item1[1]}</a>
                    <p>{props.items.item1[2]}</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 mb-4 mb-lg-0">
                <div className="list-box d-flex align-items-center text-center centrado-horizontal">
                  <div className="content2 centrado-horizontal">
                    <a data-toggle="modal" data-target="#exampleModal">{props.items.item2[1]}</a>
                    <p>{props.items.item2[2]}</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="list-box d-flex justify-content-start align-items-center text-center centrado-horizontal">
                  <div className="content2 centrado-horizontal">
                    <a data-toggle="modal" data-target="#exampleModal">{props.items.item3[1]}</a>
                    <p>{props.items.item3[2]}</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="list-box d-flex justify-content-start align-items-center text-center centrado-horizontal">
                  <div className="content2 centrado-horizontal">
                    <a data-toggle="modal" data-target="#exampleModal">{props.items.item4[1]}</a>
                    <p>{props.items.item4[2]}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    )
    }else if(Object.keys(props.items).length == 5){
      return(

        <div className="list-section pt-80 pb-80" id="oficinas">
          <div className="container">
            <div className="row">
              <div className="col-lg-1 col-md-6 mb-4 mb-lg-0" />
              <div className="col-lg-2 col-md-6 mb-4 mb-lg-0">
                <div className="list-box d-flex align-items-center">
                  <div className="list-icon2 centrado-horizontal">
                    <i className={props.items.item1[0]} />
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-6 mb-4 mb-lg-0">
                <div className="list-box d-flex align-items-center">
                  <div className="list-icon2 centrado-horizontal">
                    <i className={props.items.item2[0]} />
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-6">
                <div className="list-box d-flex justify-content-start align-items-center">
                  <div className="list-icon2 centrado-horizontal">
                    <i className={props.items.item3[0]} />
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-6">
                <div className="list-box d-flex justify-content-start align-items-center">
                  <div className="list-icon2 centrado-horizontal">
                    <i className={props.items.item4[0]} />
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-6">
                <div className="list-box d-flex justify-content-start align-items-center">
                  <div className="list-icon2 centrado-horizontal">
                    <i className={props.items.item5[0]} />
                  </div>
                </div>
              </div>
              <div className="col-lg-1 col-md-6 mb-4 mb-lg-0" />
            </div>
            <div className="row">
              <div className="col-lg-1 col-md-6 mb-4 mb-lg-0" />
              <div className="col-lg-2 col-md-6 mb-4 mb-lg-0">
                <div className="list-box d-flex align-items-center text-center centrado-horizontal">
                  <div className="content2 centrado-horizontal">
                    <a data-toggle="modal" data-target="#exampleModal">{props.items.item1[1]}</a>
                    <p>{props.items.item1[2]}</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-6 mb-4 mb-lg-0">
                <div className="list-box d-flex align-items-center text-center centrado-horizontal">
                  <div className="content2 centrado-horizontal">
                    <a data-toggle="modal" data-target="#exampleModal">{props.items.item2[1]}</a>
                    <p>{props.items.item2[2]}</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-6">
                <div className="list-box d-flex justify-content-start align-items-center text-center centrado-horizontal">
                  <div className="content2 centrado-horizontal">
                    <a data-toggle="modal" data-target="#exampleModal">{props.items.item3[1]}</a>
                    <p>{props.items.item3[2]}</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-6">
                <div className="list-box d-flex justify-content-start align-items-center text-center centrado-horizontal">
                  <div className="content2 centrado-horizontal">
                    <a data-toggle="modal" data-target="#exampleModal">{props.items.item4[1]}</a>
                    <p>{props.items.item4[2]}</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-6">
                <div className="list-box d-flex justify-content-start align-items-center text-center centrado-horizontal">
                  <div className="content2 centrado-horizontal">
                    <a data-toggle="modal" data-target="#exampleModal">{props.items.item5[1]}</a>
                    <p>{props.items.item5[2]}</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-1 col-md-6 mb-4 mb-lg-0" />
            </div>
          </div>
        </div>
    )
    }

    

}

export default Banner;