import React, { useState } from "react";
import Navbar from "../components/Navbar";
import NavbarResponsive from "../components/NavbarResponsive";
import Contact from "../components/Contact";
import {asesores} from "../data/asesores.js";

function Asesores() {
  const setNavbar = () => {
    if (window.screen.width < 800) {
      return <NavbarResponsive />;
    } else {
      return <Navbar active={1} />;
    }
  };

  const shuffle = (array) => {
    let currentIndex = array.length,
      temporaryValue,
      randomIndex;

    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  };

  return (
    <div>
      {setNavbar()}
      <body>
        <div className="search-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <span className="close-btn">
                  <i className="fas fa-times" />
                </span>
                <div className="search-bar">
                  <div className="search-bar-tablecell">
                    <a href="/">
                      <h3>Home</h3>
                    </a>
                    <a href="/asesores" className="close-btn">
                      <h3>Asesores</h3>
                    </a>
                    <a href="/fundacion">
                      <h3>Fundación</h3>
                    </a>
                    <a href="/oficinas">
                      <h3>Oficinas</h3>
                    </a>
                    <a href="/proyectos">
                      <h3>Proyectos</h3>
                    </a>
                    <a href="/casas">
                      <h3>Asesores</h3>
                    </a>
                    <a href="/blog">
                      <h3>Blog</h3>
                    </a>
                    <a href="/clientes">
                      <h3>Clientes</h3>
                    </a>
                    <button type="submit" className="close-btn" id="close-btn">
                      Volver <i className="fas fa-arrow-up" />
                    </button>
                    <a href="/login">
                      <h5>Ingresar</h5>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section
          className="casas-hero"
          style={{
            backgroundImage: `linear-gradient(rgba(19, 50, 34, 0.7),rgba(19, 50, 34, 0.7)) , url(https://terraloteos.com/img/asesores/asesores-hero.jpg)`,
          }}
        >
          <div className="container text-center">
            <h3>
              Conocé nuestros
              <br />
              <span className="orange-text">Asesores oficiales</span>
            </h3>
            <a href="#oficinas" className="btn-bajar btn-lg">
              <i className="fas fa-arrow-down mr-0" />
            </a>
          </div>
        </section>

        <div className="latest-news pt-150 pb-150" id="oficinas">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2 text-center">
                <div className="section-title">
                  <h3>
                    Nuestros<span className="orange-text"> Asesores</span>
                  </h3>
                  <p>
                    Asesores oficiales de Terraloteos que te ayudarán y
                    brindarán la mejor atención en la busqueda de tu inmueble
                    ideal!
                  </p>
                </div>
              </div>
            </div>

            <div className="row">
              {shuffle(asesores).map((asesor) => {
                return (
                  <div className="col-lg-4 col-md-6" id="merlo">
                    <div className="single-latest-news">
                      <a
                        href={
                          "https://api.whatsapp.com/send/?phone=" +
                          asesor.whatsapp +
                          "&type=phone_number&app_absent=0"
                        }
                        target={"_blank"}
                      >
                        <div className="asesor-bg">
                          <img src={asesor.imagen} />
                        </div>
                      </a>
                      <div className="news-text-box text-center">
                        <h3 className="mb-0">{asesor.nombre}</h3>
                        <h4>{asesor.puesto}</h4>
                        <hr />
                        <p className="product-price">
                          <span>Sucursales</span>
                          {asesor.sucursal}
                        </p>
                        <a
                          href={
                            "https://api.whatsapp.com/send/?phone=" +
                            asesor.whatsapp +
                            "&type=phone_number&app_absent=0"
                          }
                          className="wp-btn mr-2"
                          target={"_blank"}
                          rel="noopener noreferrer"
                        >
                          <i className="fab fa-whatsapp" /> Whatsapp
                        </a>
                        <a
                          href={"mailto:" + asesor.correo}
                          target={"_blank"}
                          rel="noopener noreferrer"
                          className="cart-btn"
                        >
                          <i className="fas fa-envelope" /> Correo
                        </a>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="list-section pt-80 pb-80" id="contacto">
          <div className="container">
            <div className="row text-center">
              <div className="col-lg-12">
                <div className="section-title mb-0">
                  <h3 className="text-white">
                    Comunicate con <span className="orange-text">Nosotros</span>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Contact></Contact>
      </body>
    </div>
  );
}

export default Asesores;
